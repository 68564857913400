import { ExternalPathRoots } from "../utils/constants"

const ExternalPaths = Object.freeze({
  CONTEXT_SETTING: `/${ExternalPathRoots.EXTERNAL_PATH}/CardActivation`,
  CARD_ACTIVE: `/${ExternalPathRoots.EXTERNAL_PATH}/CardActivation/CardActive`,
  ONBOARDING_IFRAME: `/${ExternalPathRoots.EXTERNAL_PATH}/onboarding/iframe`,
  CVP: `/${ExternalPathRoots.EXTERNAL_PATH}/onboarding`,
  LOGIN: `/${ExternalPathRoots.EXTERNAL_PATH}/login`,
  DEMO_LOGIN: `/${ExternalPathRoots.CBCC_DEMO_PATH}/:issuerName?`,
  STANDALONE_LOGIN: `/${ExternalPathRoots.STANDALONE_LOGIN_PATH}`,
})

export default ExternalPaths
