import React from "react"
import { useTranslation } from "react-i18next"
import { WhiteButton, ColorButton } from "../core/buttons"
import Close_v3 from "../svg/v3/close"
import shield from "../../assets/images/v3/shield.svg"
import downloadReady from "../../assets/images/v3/downloadStatementReady.svg"
import downloadPending from "../../assets/images/v3/downloadPending.svg"
import downloadSuccess from "../../assets/images/v3/downloadSuccess.svg"
import downloadFailure from "../../assets/images/v3/downloadFailure.svg"
import { downloadStatementStates } from "../../utils/enums"

const DownloadStatement = ({
  downloadStatementStatus,
  isDownloadSelected,
  emailSentStatus,
  handleDownloadStatementBottomSheetClose,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className='v3-statement-summary-download-button-close-section'>
        <div
          className='v3-statement-summary-download-bottom-sheet-close-button'
          onClick={handleDownloadStatementBottomSheetClose}
        >
          <Close_v3 color={"#0E0E0E"} height={"14"} width={"14"} />
        </div>
      </div>
      <div className='v3-statement-summary-download-bottom-sheet-section'>
        <div className='v3-statement-summary-download-bottom-sheet-top-section'>
          <div className='v3-statement-summary-download-bottom-sheet-icon'>
            {isDownloadSelected ? (
              downloadStatementStatus === downloadStatementStates.INIT ? (
                <img src={downloadReady} alt='download statement ready icon' />
              ) : downloadStatementStatus ===
                downloadStatementStates.PENDING ? (
                <img
                  src={downloadPending}
                  alt='download statement pending icon'
                />
              ) : downloadStatementStatus ===
                downloadStatementStates.SUCCESS ? (
                <img
                  src={downloadSuccess}
                  alt='download/send email statement success icon'
                />
              ) : downloadStatementStatus ===
                downloadStatementStates.FAILURE ? (
                <img
                  src={downloadFailure}
                  alt='download/email statement failure icon'
                />
              ) : null
            ) : emailSentStatus === downloadStatementStates.INIT ? (
              <img src={downloadReady} alt='download statement ready icon' />
            ) : emailSentStatus === downloadStatementStates.SUCCESS ? (
              <img
                src={downloadSuccess}
                alt='download/send email statement success icon'
              />
            ) : emailSentStatus === downloadStatementStates.FAILURE ? (
              <img
                src={downloadFailure}
                alt='download/email statement failure icon'
              />
            ) : null}
          </div>
          <div className='v3-statement-summary-download-bottom-sheet-text'>
            {isDownloadSelected
              ? downloadStatementStatus === downloadStatementStates.INIT
                ? t("statements.YourStatementIsReady")
                : downloadStatementStatus === downloadStatementStates.PENDING
                  ? t("statements.DownloadingText")
                  : downloadStatementStatus === downloadStatementStates.SUCCESS
                    ? t("statements.statementDowloadedSuccess")
                    : downloadStatementStatus ===
                        downloadStatementStates.FAILURE
                      ? t("statements.somethingWentWrong")
                      : null
              : emailSentStatus === downloadStatementStates.INIT
                ? t("statements.YourStatementIsReady")
                : emailSentStatus === downloadStatementStates.SUCCESS
                  ? t("statements.statementRequestSent")
                  : emailSentStatus === downloadStatementStates.FAILURE
                    ? t("statements.somethingWentWrong")
                    : null}
            {((isDownloadSelected &&
              downloadStatementStatus === downloadStatementStates.SUCCESS) ||
              (!isDownloadSelected &&
                emailSentStatus === downloadStatementStates.SUCCESS)) && (
              <div className='v3-statement-summary-download-statement-success-text'>
                {isDownloadSelected ? (
                  "14 Feb - 15 Mar"
                ) : (
                  <div>
                    {t("statements.statementEmailedText")}{" "}
                    <span className='v3-statement-summary-download-bottom-sheet-description-bold'>
                      {t("statements.emailAddressExampledText")}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='v3-statement-summary-download-bottom-sheet-mid-section'>
            {((isDownloadSelected &&
              downloadStatementStatus !== downloadStatementStates.FAILURE) ||
              (!isDownloadSelected &&
                emailSentStatus !== downloadStatementStates.FAILURE)) && (
              <div className='v3-statement-summary-download-bottom-sheet-password-protected-section'>
                <span>
                  <img
                    width={"18px"}
                    height={"18px"}
                    src={shield}
                    alt='shield image'
                  />
                </span>{" "}
                {t("statements.passwordProtectedText")}
              </div>
            )}
            {(isDownloadSelected &&
              downloadStatementStatus !== downloadStatementStates.FAILURE) ||
            (!isDownloadSelected &&
              emailSentStatus !== downloadStatementStates.FAILURE) ? (
              <div className='v3-statement-summary-download-bottom-sheet-description-section'>
                {t("statements.accessFileText")}{" "}
                <span className='v3-statement-summary-download-bottom-sheet-description-bold'>
                  {t("statements.enterCharacters", { noCharacters: 4 })}
                </span>{" "}
                {t("statements.firstName")}{" "}
                <span className='v3-statement-summary-download-bottom-sheet-description-bold'>
                  {t("statements.dobFormat")}
                </span>
              </div>
            ) : (
              <div className='v3-statement-summary-download-bottom-sheet-description-section'>
                {isDownloadSelected
                  ? t("statements.creditStatementDownloadFailed")
                  : t("statements.creditStatementRequestFailed")}
              </div>
            )}
          </div>
        </div>
      </div>
      {((isDownloadSelected &&
        downloadStatementStatus !== downloadStatementStates.SUCCESS) ||
        (!isDownloadSelected &&
          emailSentStatus !== downloadStatementStates.SUCCESS)) && (
        <div className='v3-statement-summary-download-bottom-sheet-bottom-section'>
          {(isDownloadSelected &&
            downloadStatementStatus === downloadStatementStates.FAILURE) ||
          (!isDownloadSelected &&
            emailSentStatus === downloadStatementStates.FAILURE) ? (
            <>
              <div className='v3-statement-summary-download-bottom-sheet-download-button'>
                <ColorButton
                  text={t("statement.tryAgainText")}
                  width={"100%"}
                />
              </div>
            </>
          ) : (
            <>
              <div className='v3-statement-summary-download-bottom-sheet-send-email-button'>
                <WhiteButton
                  text={t("statement.sendEmail")}
                  width={"100%"}
                  isDisable={
                    downloadStatementStatus === downloadStatementStates.PENDING
                      ? true
                      : false
                  }
                />
              </div>
              <div className='v3-statement-summary-download-bottom-sheet-download-button'>
                <ColorButton
                  text={t("statement.downloadText")}
                  width={"100%"}
                  isDisable={
                    downloadStatementStatus === downloadStatementStates.PENDING
                      ? true
                      : false
                  }
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default DownloadStatement
