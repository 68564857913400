import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import TokenisationMenuDots from "../../../svg/v3/tokenisationMenuDots"
import TokenisationPower from "../../../svg/v3/tokenisationPower"
import { MERCHANT_STATUS } from "../../../../utils/enums"
import TokenisationPause from "../../../svg/v3/tokenisationPause"
import TokenisationRequested from "../../../svg/v3/tokenisationRequested"
import TokenisationCaretRight from "../../../svg/v3/tokenisationCaretRight"
import TokenisationLock from "../../../svg/v3/tokenisationLock"
import TokenisationProcessing from "../../../svg/v3/tokenisationProcessing"
import { useTranslation } from "react-i18next"
import TokenisationDefaultMerchant from "../../../svg/v3/TokenisationDefaultMerchant"

const MERCHANT_TYPE_TEXT = {
  [MERCHANT_STATUS.ACTIVE]: "On",
  [MERCHANT_STATUS.SUSPENDED]: "Paused",
  [MERCHANT_STATUS.FAILED]: "Request Failed",
  [MERCHANT_STATUS.PROCESSING]: "processing",
}

const MerchantCard = ({ merchant, merchantType, onMerchantCardClick }) => {
  const [theme, screen] = useSelector(state => [
    state.theme,
    state.screen,
    state.config,
  ])

  const { t } = useTranslation()

  const MERCHANT_TYPE_ICON = {
    [MERCHANT_STATUS.ACTIVE]: (
      <TokenisationPower color={theme.v3.cssVars.primary.color1} />
    ),
    [MERCHANT_STATUS.SUSPENDED]: (
      <TokenisationPause color={theme.v3.cssVars.primaryBase.color1} />
    ),
    [MERCHANT_STATUS.FAILED]: (
      <TokenisationRequested color={theme.v3.cssVars.tertiaryNegative.color1} />
    ),
    [MERCHANT_STATUS.PROCESSING]: (
      <TokenisationProcessing
        color1={theme.v3.cssVars.tertiaryCaution.color1}
        color2={theme.v3.cssVars.tertiaryCaution.color1}
      />
    ),
  }

  const MERCHANT_TYPE_BG_COLOR = {
    [MERCHANT_STATUS.ACTIVE]: theme.v3.cssVars.primary.color5,
    [MERCHANT_STATUS.SUSPENDED]: theme.v3.cssVars.primaryBase.color5,
    [MERCHANT_STATUS.FAILED]: theme.v3.cssVars.tertiaryNegative.color3,
    [MERCHANT_STATUS.PROCESSING]: theme.v3.rawColors.tertiaryCaution.color3,
  }

  const MERCHANT_TYPE_TEXT_COLOR = {
    [MERCHANT_STATUS.ACTIVE]: theme.v3.cssVars.primary.color1,
    [MERCHANT_STATUS.SUSPENDED]: theme.v3.cssVars.primaryBase.color1,
    [MERCHANT_STATUS.FAILED]: theme.v3.cssVars.tertiaryNegative.color1,
    [MERCHANT_STATUS.PROCESSING]: theme.v3.cssVars.tertiaryCaution.color1,
  }

  const getMerchantName = merchant => {
    if (merchant?.name && merchant?.name != "null") {
      return merchant.name?.length > 14
        ? merchant.name?.slice(0, 12) + "..."
        : merchant.name
    }
    return merchant?.merchantId
  }

  return (
    <div
      className='tokenisation-merchant-card-box'
      onClick={() => onMerchantCardClick()}
    >
      <div className='tokenisation-merchant-card-inside-div'>
        <div className='tokenisation-merchant-card-inside-first'>
          {merchant?.base64Image ? (
            <img
              src={`data:image/jpg;base64,${merchant?.base64Image}`}
              className='tokenisation-merchant-card-inside-icon'
            />
          ) : (
            <TokenisationDefaultMerchant
              color={theme.v3.cssVars.primary.color1}
            />
          )}
          <span className='tokenisation-merchant-card-inside-name'>
            {" "}
            {getMerchantName(merchant)}
          </span>

          <span className='tokenisation-merchant-card-inside-menu-icon'>
            <TokenisationMenuDots color={theme.v3.cssVars.primaryBase.color1} />
          </span>
        </div>

        <div
          className='tokenisation-merchant-card-inside-second'
          style={{
            backgroundColor: MERCHANT_TYPE_BG_COLOR[merchantType],
            color: MERCHANT_TYPE_TEXT_COLOR[merchantType],
          }}
        >
          <div className='tokenisation-merchant-card-inside-second-icon'>
            {MERCHANT_TYPE_ICON[merchantType]}

            <span className='tokenisation-merchant-card-inside-second-text'>
              {MERCHANT_TYPE_TEXT[merchantType]}
            </span>
          </div>
        </div>
        {merchant.status == MERCHANT_STATUS.FAILED && (
          <div className='tokenisation-merchant-card-inside-second-retry-text'>
            {t("merchant.retry")}
            <TokenisationCaretRight color={theme.v3.cssVars.color1} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MerchantCard
