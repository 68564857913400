import React, { useEffect, useState } from "react"
import swipeDown from "../../../../components/svg/v3/swipeDown.json"
import swipeUp from "../../../../components/svg/v3/swipeUp.json"
import { useLottie } from "lottie-react"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import { useTranslation } from "react-i18next"

const InactiveGuideOverlay = ({ cardVisibleV3 }) => {
  const [animationData, setAnimationData] = useState(null)
  const { t } = useTranslation()

  const { View } = useLottie(
    {
      animationData: animationData,
      loop: true,
      autoplay: true,
      speed: 1.0,
    },
    {
      height: 567,
      width: 567,
      maxWidth: useWindowDimensions().width,
    },
  )

  useEffect(() => {
    if (cardVisibleV3) {
      setAnimationData(swipeDown)
    } else {
      setAnimationData(swipeUp)
    }
  }, [cardVisibleV3])
  return (
    <div className='v3-home-overlay'>
      <div
        className='v3-swipe-load'
        style={{
          top: cardVisibleV3 ? "185px" : "456px",
          width: "140px",
          height: "137px",
        }}
      >
        {View}
      </div>
      <div className='v3-overlay-btn-container'>
        <button className='v3-overlay-btn-ok'>
          {t("rewards.okayGotItText")}
        </button>
      </div>
    </div>
  )
}

export default InactiveGuideOverlay
