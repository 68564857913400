import React from "react"

const CakeSvg = ({ color = "#25314C", ...props }) => (
  <svg
    width='18'
    height='19'
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.75 13.6924H15.5625V8.25488C15.5625 6.43988 14.565 5.44238 12.75 5.44238H12.5625V3.75488C12.5625 3.44738 12.3075 3.19238 12 3.19238C11.6925 3.19238 11.4375 3.44738 11.4375 3.75488V5.44238H9.5625V3.75488C9.5625 3.44738 9.3075 3.19238 9 3.19238C8.6925 3.19238 8.4375 3.44738 8.4375 3.75488V5.44238H6.5625V3.75488C6.5625 3.44738 6.3075 3.19238 6 3.19238C5.6925 3.19238 5.4375 3.44738 5.4375 3.75488V5.44238H5.25C3.435 5.44238 2.4375 6.43988 2.4375 8.25488V13.6924H2.25C1.9425 13.6924 1.6875 13.9474 1.6875 14.2549V15.3799C1.6875 16.3099 2.445 17.0674 3.375 17.0674H14.625C15.555 17.0674 16.3125 16.3099 16.3125 15.3799V14.2549C16.3125 13.9474 16.0575 13.6924 15.75 13.6924ZM5.25 6.56738H12.75C13.935 6.56738 14.4375 7.06988 14.4375 8.25488V9.11738C13.8825 9.34238 13.4025 9.85238 13.0575 10.3174C12.6525 9.70238 11.9775 9.00488 11.0025 9.00488C10.0275 9.00488 9.41251 9.64238 9.00751 10.2349C8.59501 9.64238 7.9425 9.00488 7.0125 9.00488C6.0825 9.00488 5.36249 9.70238 4.95749 10.3174C4.61249 9.85238 4.12499 9.34238 3.57749 9.11738V8.25488C3.57749 7.06988 4.07999 6.56738 5.26499 6.56738H5.25ZM3.5625 10.4449C3.9075 10.7674 4.275 11.2849 4.5 11.7049C4.605 11.8999 4.815 12.0199 5.04 11.9974C5.265 11.9824 5.4525 11.8324 5.5275 11.6299C5.5275 11.6149 6.0975 10.1299 7.005 10.1299C7.9125 10.1299 8.46749 11.6149 8.47499 11.6299C8.55749 11.8549 8.76751 12.0049 9.00751 12.0049C9.24751 12.0049 9.4575 11.8549 9.54 11.6299C9.675 11.2399 10.2375 10.1299 11.01 10.1299C11.9175 10.1299 12.48 11.6149 12.4875 11.6299C12.5625 11.8399 12.7575 11.9824 12.975 11.9974C13.2075 12.0199 13.41 11.8999 13.515 11.7049C13.74 11.2849 14.1075 10.7749 14.4525 10.4449V13.6924H3.57749V10.4449H3.5625ZM15.1875 15.3799C15.1875 15.6874 14.9325 15.9424 14.625 15.9424H3.375C3.0675 15.9424 2.8125 15.6874 2.8125 15.3799V14.8174H15.1875V15.3799Z'
      fill={color}
    />
  </svg>
)

export default CakeSvg
