import { ProgramTypes } from "../../utils/constants"
import {
  ADD_FAILED_EMI_TRANSACTION,
  SET_ACCOUNT,
  SET_ACTIVATION_CODE,
  SET_CARDS,
  SET_CARD_REPLACEMENT_FEE,
  SET_CASHBACK,
  SET_CUSTOMER,
  SET_EMI_DETAILS,
  SET_MULTICARD_ACCOUNTS,
  SET_REWARD,
  SET_REWARD_SUMMARY,
  SET_SPEND_CATEGORY,
  SET_SPEND_GROUP,
  SET_SPEND_GROUPS,
  SET_SUMMARY,
  SET_USER,
} from "../ActionTypes"

const initialState = {
  customer: {},
  cards: [],
  account: {},
  summary: null,
  programType: ProgramTypes.PAY_LATER,
  enablePciWidget: false,
  enableManageEMI: false,
  enableBalanceEMI: false,
  enableTransactionEMI: false,
  rewardsSummary: {
    currentValue: null,
  },
  activationCode: null,
  failedEmiTransactions: [],
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        customer: action.payload.customer,
        cards: action.payload.cards,
        account: action.payload.account,
        programType: action.payload.programType,
        enablePciWidget: action.payload.enablePciWidget,
      }
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customer,
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload.account,
      }
    case SET_CARDS:
      return {
        ...state,
        cards: action.payload.cards,
      }
    case SET_EMI_DETAILS:
      return {
        ...state,
        enableManageEMI: action.payload.enableManageEMI,
        enableBalanceEMI: action.payload.enableBalanceEMI,
        enableTransactionEMI: action.payload.enableTransactionEMI,
      }
    case SET_SUMMARY:
      return {
        ...state,
        summary: action.payload.summary,
      }
    case SET_REWARD:
      return {
        ...state,
        aggregate: action.payload.aggregate,
      }
    case SET_CASHBACK:
      return {
        ...state,
        hfCashback: action.payload,
      }
    case SET_SPEND_GROUP:
      return {
        ...state,
        spendGroup: action.payload.spendGroup,
      }
    case SET_SPEND_GROUPS:
      return {
        ...state,
        spendGroups: action.payload.spendGroups,
      }
    case SET_SPEND_CATEGORY:
      return {
        ...state,
        spendCategory: action.payload.spendCategory,
      }
    case SET_CARD_REPLACEMENT_FEE:
      return {
        ...state,
        cardReplacementFee: action.payload.cardReplacementFee,
      }
    case SET_REWARD_SUMMARY:
      return {
        ...state,
        rewardSummary: action.payload.rewardSummary,
      }
    case SET_ACTIVATION_CODE:
      return {
        ...state,
        activationCode: action.payload.activationCode,
      }
    case ADD_FAILED_EMI_TRANSACTION:
      return {
        ...state,
        failedEmiTransactions: [...state.failedEmiTransactions, action.payload],
      }
    case SET_MULTICARD_ACCOUNTS:
      return {
        ...state,
        multicardAccounts: action.payload.multicardAccounts,
      }
    default:
      return state
  }
}

export default UserReducer
