import React, { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import {
  DisplayConfig,
  ErrorType,
  ProgramTypes,
} from "../../../utils/constants"
import {
  AU_BlockCodes,
  BottomSheetType,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../utils/enums"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import {
  captureEvents,
  consoleError,
  hideGenericError,
  isWebKitBrowser,
  handleWebKitTextInputFocus,
  resetErrorTryAgainCount,
} from "../../../utils/functions"
import Paths from "../../../routes/Paths"
import { handleApiAuth } from "../../../utils/auth"
import ApiIds from "../../../auth/ApiIds"
import CardService from "../../../services/CardService"
import crossIcon from "../../../assets/images/v3/closeBtn.svg"
import OtpInput from "../../auth/otp/OtpInput_v3"
import { useBottomSheet } from "../../auth/BottomSheetContext"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import DividerV3 from "../../core/Divider/v3/DividerV3"
import { ErrorContext } from "../../auth/ErrorScreenContext"

const ResetPin_v3 = ({}) => {
  const { t } = useTranslation()
  const { setErrorState } = useContext(ErrorContext)
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pin, setPin] = useState(["", "", "", ""])
  const [confirmPin, setConfirmPin] = useState(["", "", "", ""])
  const bottomSheetContext = useBottomSheet()

  const resetPinContainerRef = useRef(null)

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const [mainContainerHeight, setMainContainerHeight] = useState(0)
  const mainContainerRef = useRef(null)
  const [isFilled, setIsFilled] = useState(false)
  const location = useLocation()
  const fromActivation = location?.state?.from === "Activation"

  const resetCardPinInput1 = useRef()
  const resetCardPinInput2 = useRef()

  const focusFirst = () => {
    resetCardPinInput2.current?.focusFirst()
  }
  const focusLast = () => {
    resetCardPinInput1.current?.focusLast()
  }

  useEffect(() => {
    if (pin.length === 4) {
      focusFirst()
    }
  }, [pin])

  useEffect(() => {
    if (mainContainerRef.current) {
      setMainContainerHeight(mainContainerRef.current.clientHeight)
    }
  }, [mainContainerRef.current])

  useEffect(() => {
    captureEvents({ eventName: EventName.RESET_PIN, metadata: {} })
  }, [])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.CC_PIN_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.RESET_PIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      )
    ) {
      navigate("/")
    }
    const element = resetPinContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    })
    return () => {
      element.classList.remove("active")
    }
  }, [])

  useEffect(() => {
    document.addEventListener("focusin", handleWebKitTextInputFocus)
    return () => {
      document.removeEventListener("focusin", handleWebKitTextInputFocus)
    }
  }, [])

  useEffect(() => {
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/")
    }
  }, [user.account.status, user.cards, navigate])

  const isActivationFlow = location.state?.from === "Activation"

  const Cancel = () => {
    navigate(-1)
  }

  const handleSubmit = async () => {
    captureEvents({ eventName: EventName.RESET_PIN_CTA, metadata: {} })
    if (pin === confirmPin) {
      setSubmitLoading(true)

      await handleApiAuth({
        apiId: ApiIds.SET_CARD_PIN,
        flowId: isActivationFlow ? "4" : "3",
        onAuthSuccess: onAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: t("mpin.resetPinReason"),
        mpinReason: t("mpin.resetPinReason"),
        version: config?.version,
        bottomSheetContext: bottomSheetContext,
      })

      setSubmitLoading(false)
    } else {
      setError(true)
      captureEvents({
        eventName: EventName.RESET_PIN_ERROR,
        metadata: { msg: t("messages.pinMismatchError") },
      })
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.resetPin(apiToken, user.cards[0]?.id, {
        cardPin: pin,
      })
      const result = response?.data
      if (result?.success) {
        resetErrorTryAgainCount()
        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("messages.resetPinSuccessMessage"),
          "",
          "/",
        )
        captureEvents({ eventName: EventName.RESET_PIN_SUCCESS, metadata: {} })
        if (isActivationFlow) {
          if (
            (config.version === PwaVersions.V1 &&
              [ProgramTypes.PAY_LATER, ProgramTypes.PREPAID].includes(
                user.programType,
              )) ||
            ((config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1) &&
              screen?.home?.isCardControlsInActivationFlow)
          ) {
            navigate(Paths.ACTIVATION_CARD_CONTROLS, { replace: true })
          } else {
            navigate(Paths.HOME_WITH_ACTIVATE, { replace: true })
          }
        } else {
          //   navigate("/ManageCard", { replace: true })
        }

        // TODO: in activation flow, calling toast before navigate prevents toast from disappearing. Find why.
      } else {
        consoleError(result.errors)
        if (hideGenericError(response?.status)) {
          setErrorState(response?.status)
        } else {
          handleFailureState()
        }

        captureEvents({
          eventName: EventName.RESET_PIN_ERROR,
          metadata: { msg: t("messages.resetPinErrorMessage") },
        })
        // navigate("/ManageCard", { replace: true })
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR)
      } else {
        consoleError(error)
        handleFailureState()
        captureEvents({
          eventName: EventName.RESET_PIN_ERROR,
          metadata: { msg: t("messages.resetPinErrorMessage") },
        })
      }
      //   navigate("/ManageCard", { replace: true })
    }
  }

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("statements.somethingWentWrong"),
      "",
      null,
      null,
      null,
      false,
    )
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    captureEvents({
      eventName: EventName.RESET_PIN_ERROR,
      metadata: { msg: message },
    })
    handleFailureState()
    message && toast(message)
  }

  const onAuthCancel = async message => {
    message && toast(message)
    captureEvents({
      eventName: EventName.RESET_PIN_ERROR,
      metadata: { msg: message },
    })
    // navigate("/ManageCard", { replace: true })
  }

  const getPinMsg = () => {
    if (user.programType === ProgramTypes.CREDIT_CARD)
      return config?.version === PwaVersions.V2_1
        ? t("mpin.updateCardPin")
        : fromActivation
          ? t("mpin.cardPinSetup")
          : t("mpin.setupCardPin")
    else if (user.programType === ProgramTypes.PREPAID)
      return fromActivation
        ? t("mpin.cardPinSetup")
        : t("mpin.setupPrepaidCardPin")
    else
      return fromActivation ? t("mpin.cardPinSetup") : t("mpin.setupNewCardPin")
  }

  return (
    <div ref={resetPinContainerRef} className='v3ResetPin-container'>
      <div>
        <div>
          <div className='bottom-sheet'>
            <div className='bottom-sheet-header'>
              <div className='v3-verify-otp-title'>
                {t("mpin.resetCardPin")}
                <span
                  className='v3-verify-otp-close-btn'
                  style={{
                    float: "right",
                    marginRight: "20px",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    bottomSheetContext.closeBottomSheet()
                    window.scrollTo(0, 0) // for ios scroll issue
                  }}
                >
                  <img src={crossIcon} />
                </span>
              </div>

              {/* <div className='v3-verify-otp-subtitle'>
              Enter the 6 digit code sent to +91 ********87
            </div> */}
            </div>

            <hr className='v3-card-block-success-hr-line' />

            <div className='v3-reset-mpin-text-title'>
              {t("mpin.enterNewCardPin")}
            </div>
            <div className='v3-otp-input-bottom-sheet-content'>
              <OtpInput
                isValid={!error}
                length={4}
                onChange={e => setPin(e)}
                isHashed
                isSetFlow={true}
                ref={resetCardPinInput1}
              />
            </div>

            <div className='v3-reset-mpin-text-title'>
              {t("mpin.reEnterCardPin")}
            </div>
            <div className='v3-otp-input-bottom-sheet-content'>
              <OtpInput
                isValid={!error}
                length={4}
                onChange={e => setConfirmPin(e)}
                isHashed
                isSetFlow={true}
                ref={resetCardPinInput2}
                goToPreviousInput={focusLast}
              />
            </div>
            <DividerV3 marginBottom={"18px"} />
            <ColorButton
              margin='auto'
              marginBottom='20px'
              text={t("mpin.submitText")}
              isLoading={submitLoading}
              onPress={handleSubmit}
              isDisable={!(pin.length == 4 && pin == confirmPin)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPin_v3
