import OtpInput from "../../otp/OtpInput_v3"
import { useTranslation } from "react-i18next"
import { ColorButton } from "../../../core/buttons"
import mPinV3 from "../../../../assets/images/mPinV3.svg"
import CardImg from "../../../../assets/images/v3/card.png"
import mPinV3bg from "../../../../assets/images/v3/mPinV3bg.svg"
import AuthHeader from "../AuthHeader"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import CommonService from "../../../../services/CommonService"
import {
  restoreStateBeforeSetMpin,
  saveStateBeforeSetMpin,
  setDeviceId,
  setIsMpinSet,
  setVerifiedChallenges,
} from "../../../../store/actions/Auth"
import { setSetMpinState } from "../../../../store/actions/SetMpin"
import * as analytics from "../../../../utils/analytics"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../../utils/auth"
import {
  AuthChallenges,
  DEFAULT_MPIN_SIZE,
  MpinErrors,
} from "../../../../utils/constants"
import {
  AU_BlockCodes,
  AuthenticationType,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../utils/enums"
import {
  captureEvents,
  getAnalyticsProgramType,
  goToRedirectUrl,
} from "../../../../utils/functions"
import { Header, mainHeader } from "../../../core"
import MpinBlocked from "./MpinBlocked"
import styles from "../styles"
import ArrowRight from "../../../svg/arrowRight"
import { AuthBlocked_v3 } from "../../../core/AuthBlocked_v3"
import InfoCircle from "../../../svg/v3/infoCircle"
import { useBottomSheet } from "../../BottomSheetContext"
import Error_v3 from "../../../core/Error_v3"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import DividerV3 from "../../../core/Divider/v3/DividerV3"

const Mpin_v3 = () => {
  const { t } = useTranslation()
  const [pin, setPin] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [attempts, setAttempts] = useState()
  const [showMpinBlocked, setShowMpinBlocked] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const [theme, authStore, user, config, screen, session] = useSelector(
    state => [
      state.theme,
      state.auth,
      state.user,
      state.config,
      state.screen,
      state.session,
    ],
  )
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const bottomSheetContext = useBottomSheet()
  const mpinSize =
    config?.auth?.[AuthChallenges.MPIN]?.length || DEFAULT_MPIN_SIZE

  const [slideRight, setSlideRight] = useState(false)
  const slideUpAnimation = localStorage.getItem("firstTimeAuth") || false
  const isAppHeaderEnabled = screen?.pwaHeader?.isAppHeaderEnabled

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleMpinInputChange = e => {
    setError()
    setPin(e)
  }
  const resetStateOnWrongMPIN = () => {
    setPin("")
  }
  const verifyMpin = async () => {
    localStorage.removeItem("firstTimeAuth")
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - Click on Verify ${mpinWord}`,
    )

    if (pin.length < mpinSize) {
      setError(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      // resetStateOnWrongMPIN()
      return
    }

    setSubmitLoading(true)

    try {
      const response = await CommonService.verifyChallengeMpin({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        customerId: user.customer.id,
        programId: user.account.programId,
        mpin: pin,
      })
      const result = response.data

      if (result?.success) {
        setSlideRight(true)
        setError(false)
        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          mpinRefId: result.data.mpinRefId,
        }

        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        if (!authStore.verifiedChallenges.deviceId) {
          // generate device token
          const deviceIdResponse = await CommonService.generateDeviceId({
            accountId: user.account.id,
          })
          const deviceIdResult = deviceIdResponse.data

          if (deviceIdResult?.success) {
            dispatch(
              setDeviceId({
                deviceId: deviceIdResult.data?.deviceToken,
              }),
            )
            // this will also set device id in local storage
          }
        }

        setTimeout(() => {
          onFactorCompletion(
            navigate,
            config?.version,
            true,
            bottomSheetContext,
          )
        }, 150)
      } else {
        if (result?.errors?.status === MpinErrors.INCORRECT_MPIN) {
          setError(true)
          setPin("")
          setAttempts(result?.errors?.attemptsLeft)
        } else if (result?.errors?.status === MpinErrors.BLOCKED) {
          // update isMpinSet flag
          dispatch(setIsMpinSet({ isMpinSet: { result: false } }))
          // show mpin blocked screen
          setShowMpinBlocked(true)
          bottomSheetContext.closeBottomSheet()
          bottomSheetContext.closeBottomSheet2()
          navigate("/AttemptExhausted", {
            state: {
              type: AuthenticationType.MPIN,
            },
          })
        } else {
          await authStore.onAuthFailure(
            result?.errors?.reason,
            `${t("mpin.verifyV3MpinErrMsg")}`,
          )
        }
      }
    } catch (error) {
      await authStore.onAuthFailure(error, t("mpin.genericError"))
    }

    setSubmitLoading(false)
  }

  const handleForgotMpin = () => {
    dispatch(saveStateBeforeSetMpin())

    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {
          dispatch(restoreStateBeforeSetMpin())
          navigate("/Auth/Mpin", { replace: true })
        },

        onSetMpinFailure: async (error, message) => {
          dispatch(restoreStateBeforeSetMpin())
          await authStore.onAuthFailure(
            t("mpin.failedMpinSetUpMsg"),
            t("mpin.genericError"),
          )
        },

        onSetMpinCancel: async message => {
          dispatch(restoreStateBeforeSetMpin())
          // on cancel navigate back here
          navigate("/Auth/Mpin", { replace: true })
        },
      }),
    )

    navigate("/Auth/SetMpin", { replace: true })
  }
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  const getAppWindowHeight = isAppHeaderEnabled => {
    return isAppHeaderEnabled ? window?.innerHeight - 52 : window?.innerHeight
  }

  return (
    <div
      style={{
        minHeight: windowHeight,
        backgroundColor: theme.v3.cssVars.leadingWhite,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Header
        onBack={async () => {
          navigate("/")
        }}
      />
      <div
        className={
          authStore.factors[0].challenge === EventName.MPIN && slideUpAnimation
            ? "v3_mpin_slide_up"
            : slideRight && !slideUpAnimation && "v3_mpin_slide_right"
        }
        style={{ minHeight: getAppWindowHeight(isAppHeaderEnabled) }}
      >
        <AuthHeader
          centerFloatingImage={mPinV3}
          backgroundImage={mPinV3bg}
          type={"mpin"}
        />
        <div className='v3_mpin_second_half_container'>
          <div className='v3_mpin_content_container'>
            <div className='v3_mpin_image v3_mpin_page_image'>
              <img src={mPinV3} alt='lock' />
            </div>
            <div className='v3_mpin_header_text'>
              {t("mpin.enterMpinWordLogin")}
            </div>
            <div className='v3_mpin_input_widget'>
              <OtpInput
                type={"mpin"}
                length={mpinSize}
                onChange={e => handleMpinInputChange(e)}
                error={false}
                isValid={!error}
                isHashed
                maxInputSize={mpinSize}
              />
            </div>
            {error && (
              <Error_v3 type={AuthenticationType.MPIN} attempts={attempts} />
            )}
            <div className='v3_mpin_forgot_pin_container'>
              {t("mpin.forgotCTA")}{" "}
              <span className='v3_mpin_reset_link' onClick={handleForgotMpin}>
                {t("mpin.resetCTA")}
              </span>
            </div>
          </div>
          <div className='v3_mpin_verify_button'>
            <DividerV3 />
            <ColorButton
              text={t("mpin.verifyBtnText")}
              isDisable={pin?.length !== mpinSize}
              onPress={verifyMpin}
              isLoading={submitLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mpin_v3
