import axios from "axios"

const InternalService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  validateStatus: function (status) {
    return true
  },
})

export default InternalService
