import { BottomSheet } from "react-spring-bottom-sheet"
import crossIcon from "../../assets/images/v3/closeBtn.svg"
import rupayImg from "../../assets/images/v3/rupayImg.svg"
import masterCardImg from "../../assets/images/v3/masterCardSvg.svg"
import visaImg from "../../assets/images/v3/VISA.svg"
import dinersImg from "../../assets/images/v3/DinersLogo.svg"
import amexImg from "../../assets/images/v3/AmexLogo.svg"
import horizontalCard from "../../assets/images/v3/horizontalCard.svg"
import verticalCard from "../../assets/images/v3/VerticalCard.svg"
import { ColorButton } from "../core/buttons"
import DividerV3 from "../core/Divider/v3/DividerV3"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { CardOrientation, ErrorType } from "../../utils/constants"
import { networkType } from "../../utils/enums"
import AccountService from "../../services/AccountService"
import { createSearchParams, useNavigate } from "react-router-dom"
import { setCommonState } from "../../store/actions/Common"
import { consoleError } from "../../utils/functions"
import { ErrorContext } from "../auth/ErrorScreenContext"

const CardSwapBottomSheet = ({ isOpen = false, onClose = () => {} }) => {
  const [selectedCardId, setSelectedCardId] = useState(null)
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const common = useSelector(state => state.common)
  const multicard = user?.multicardAccounts || []
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setErrorState } = useContext(ErrorContext)

  const getCardImage = orientation =>
    orientation === CardOrientation.HORIZONTAL ? horizontalCard : verticalCard

  const getNetworkImage = network => {
    switch (network) {
      case networkType.Visa:
        return visaImg
      case networkType.MasterCard:
        return masterCardImg
      case networkType.Rupay:
        return rupayImg
      case networkType.Diners:
        return dinersImg
      case networkType.Amex:
        return amexImg
      default:
        return rupayImg
    }
  }

  const handleCardSelect = id => {
    setSelectedCardId(id)
  }

  const handleSessionGeneration = async accID => {
    try {
      const sessionResponse = await AccountService.generateSession({
        accountId: accID,
      })

      if (sessionResponse?.status !== 200) {
        consoleError(sessionResponse?.errors)
        setErrorState(sessionResponse?.status, () =>
          handleSessionGeneration(accID),
        )
      }

      const payload = sessionResponse?.data?.data?.payload
      const sessionToken = payload?.token
      const tenantId = payload?.tenantId

      await handlePWACardSwap(sessionToken, tenantId)
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          handleSessionGeneration(accID),
        )
      } else {
        console.error(error)
        setErrorState(ErrorType.INTERNAL_ERROR, () =>
          handleSessionGeneration(accID),
        )
      }
    }
  }

  const handlePWACardSwap = (sessionToken, tenantId) => {
    localStorage.setItem("sessionToken", sessionToken)
    navigate({
      pathname: "/",
      search: createSearchParams({
        sessionToken,
        tenantId,
      }).toString(),
    })
    onClose()
    dispatch(setCommonState({ isStandaloneFlow: !common?.isStandaloneFlow }))
  }

  const handleConfirm = () => {
    handleSessionGeneration(selectedCardId)
  }

  return (
    <div style={{ maxWidth: "500px" }}>
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <div
          className='v3-bottomDrawer-nudges-container'
          style={{
            backgroundColor: theme.v3.cssVars.leadingWhite,
            borderRadius: "16px",
          }}
        >
          <div
            className='v3-summary-bottomSheet-title'
            style={{ width: "100%" }}
          >
            <div className='v3-summary-bottomSheet-title-container'>
              <div className='v3-summary-bottomSheet-title-text'>
                {t("HomePage.chooseCard")}
              </div>
              <div className='v3-summary-bottomSheet-title-subText'>
                {t("HomePage.cardMessage", { count: multicard.length })}
              </div>
            </div>
          </div>
        </div>

        {multicard.map(card => (
          <div
            key={card.id}
            className='v3-cards-container-body'
            onClick={() => handleCardSelect(card.id)}
            style={{
              border:
                selectedCardId === card.id
                  ? `2px solid ${theme.v3.cssVars.primary.color1}`
                  : "1px solid #d3d8df",
              borderRadius: "8px",
              padding: "10px",
              cursor: "pointer",
            }}
          >
            <div className='v3-cards-container'>
              <div className='v3-cards-image'>
                <img
                  src={getCardImage(card.virtualCardOrientation)}
                  style={{ width: "100%" }}
                  alt='card'
                />
              </div>
              <div className='v3-cards-name-number'>
                <div className='v3-cards-name'>{card.programName}</div>
                <div className='v3-cards-number'>
                  **** **** **** {card.primaryCard?.last4}
                </div>
              </div>
            </div>
            <div className='v3-cards-rupay-img-container'>
              <img
                src={getNetworkImage(card.primaryCard?.network)}
                alt={card.primaryCard?.network}
              />
            </div>
          </div>
        ))}

        <DividerV3 />

        <div className='v3-mpinIntro-cta-container' style={{ padding: "2% 0" }}>
          <div style={{ width: "90%" }}>
            <ColorButton
              mx='auto'
              text={"Confirm"}
              width='100%'
              onPress={handleConfirm}
              isDisable={!selectedCardId}
            />
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}

export default CardSwapBottomSheet
