import React from "react"
import ArrowRight from "../../../../svg/arrowRight"
import { useSelector } from "react-redux"

const MoreToKnowCard = ({ details }) => {
  const theme = useSelector(state => state.theme)
  const handleArrowClick = () => {
    if (details?.redirectionUrl) window.location.href = details?.redirectionUrl
  }

  return (
    <div className='v3-aboutCard-moreToKnow-card'>
      <div>
        <img src={details?.imageUrl} alt='icon' />
      </div>
      <div>
        <div className='v3-aboutCard-moreToKnow-card'>{details?.title}</div>
        <div onClick={() => handleArrowClick()}>
          <ArrowRight
            className='v3-aboutCard-moreToKnow-arrowRight'
            color={theme?.v3.rawColors.primary.color1}
          />
        </div>
      </div>
    </div>
  )
}

export default MoreToKnowCard
