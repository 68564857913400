import React from "react"
import PurchaseIcon from "../../assets/images/v3/Purchase.svg"
import RefundIcon from "../../assets/images/v3/refundIcon.svg"
import RepaymentIcon from "../../assets/images/v3/repaymentIcon.svg"
import { transactionNature, transactionTypes } from "../../utils/enums"
import moment from "moment"
import { useSelector } from "react-redux"
import { formatAmount } from "../../utils/functions"
import { useTranslation } from "react-i18next"

const FkwTransaction_v3 = ({ transaction }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const renderTransactionTypes = transaction => {
    if (transaction?.txnType === transactionTypes.PURCHASE) return "Purchase"
    else if (transaction?.txnType === transactionTypes.REFUND) return "Refund"
    return "Repayment"
  }
  const renderTransactionImage = transaction => {
    if (transaction?.txnType === transactionTypes.PURCHASE) return PurchaseIcon
    else if (transaction?.txnType === transactionTypes.REFUND) return RefundIcon
    return RepaymentIcon
  }
  const getTransactionStatus = transaction => {
    if (
      transaction?.txnStatus === "APPROVED" ||
      transaction?.txnStatus === "PARTIALLY_SETTLED"
    )
      return "DUE"
    else if (transaction?.txnStatus === "SETTLED") return "REPAID"
  }

  const isDebitTransaction = transaction?.txnNature === transactionNature.DEBIT
  const isTransactionStatusEnabled =
    getTransactionStatus(transaction) === "REPAID" ||
    getTransactionStatus(transaction) === "DUE"

  return (
    <div className='v3-fkw-transaction-container'>
      <div className='v3-fkw-transaction-left-conainer'>
        <div className='v3-fkw-transaction-left-icon'>
          <img src={renderTransactionImage(transaction)} alt='txn-image' />
        </div>
        <div className='v3-fkw-transaction-left-description-container'>
          <div className='v3-fkw-transaction-left-header'>
            <div className='v3-fkw-transaction-left-header-type'>
              {renderTransactionTypes(transaction)}
            </div>
            <div className='v3-fkw-transaction-left-header-date'>
              {moment(transaction?.transactionDate).format("DD MMM")}
            </div>
          </div>
          <div className='v3-fkw-transaction-left-description'>
            {transaction?.id}
          </div>
        </div>
      </div>
      <div className='v3-fkw-transaction-right-container'>
        <div
          className='v3-fkw-transaction-right-amount'
          style={{
            color:
              !isDebitTransaction && theme.v3.rawColors.tertiaryPositive.color1,
          }}
        >
          {" "}
          {!isDebitTransaction && "+ "}
          {formatAmount(transaction?.amount)}
        </div>
        {isTransactionStatusEnabled && (
          <div className='v3-fkw-transaction-right-status'>
            <div
              className='v3-fkw-transaction-right-status-container'
              style={{
                backgroundColor:
                  getTransactionStatus(transaction) === "REPAID" &&
                  theme.v3.rawColors.tertiaryPositive.color1,
              }}
            >
              {getTransactionStatus(transaction) === "REPAID"
                ? t("TransactionSection.repaidText")
                : getTransactionStatus(transaction) === "DUE"
                  ? t("TransactionSection.dueText")
                  : null}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FkwTransaction_v3
