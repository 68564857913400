import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import QrReader from "react-qr-reader"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../auth/ApiIds"
import Paths from "../../../routes/Paths"
import CardService from "../../../services/CardService"
import { handleApiAuth } from "../../../utils/auth"
import { DisplayConfig } from "../../../utils/constants"
import { EventName, PwaVersions } from "../../../utils/enums"
import {
  captureEvents,
  consoleError,
  isIosDevice,
  postMessageToIos,
} from "../../../utils/functions"
import { Header } from "../../core"
import ErrorScreen from "../../messages/errorScreen"
import { showToast1 } from "../toast"
import styles from "./styles"
import View from "../../../nativeBaseReplacements/View"
import Text from "../../../nativeBaseReplacements/Text"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import ChevronLeftIcon from "../../../nativeBaseReplacements/ChevronLeftIcon"
import { ScrollView } from "../../../nativeBaseReplacements"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const QRscanner = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const nativeToast = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const [activationCode, setActivationCode] = useState("")
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [iosLinkError, setIosLinkError] = useState(false)

  const qrCode = useRef(null)
  const activationCodeRef = useRef("")
  const cameraGranted = useRef(0)
  const { home } = screen || {}
  const { qrcodeBaseUrl } = home || {}

  const updateQrPageErrorScreen = () => {
    setError(!error)
    setActivationCode("")
  }
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    captureEvents({ eventName: EventName.QR_SCANNER, metadata: {} })
  }, [])

  useEffect(() => {
    if (location?.state?.activationCode) {
      setActivationCode(location?.state?.activationCode)
    }
    if (user.cards[0].isPhysicalCardActivated) {
      navigate("/")
      toast(t("qrscanner.physicalardActivateText"))
    }
    if (!user.cards[0]) {
      navigate("/")
    }
    if (isIosDevice()) {
      // if it's an iOS device

      const qrLink = window.hyperfaceIosProps?.cardActivationQrLink
      // process a link only once
      delete window.hyperfaceIosProps?.cardActivationQrLink

      const isQrLinkSet = Boolean(qrLink) && Boolean(qrLink.trim())
      const openQrAction = JSON.stringify({ cmd: "CARD_ACTIVATION_QR_SCAN" })

      if (!isQrLinkSet) {
        // if no qr link was set, send message to open qr scanner
        postMessageToIos(openQrAction)
      } else if (isQrLinkSet) {
        // if qr link was set, use the link
        if (isQrLinkValid(qrLink)) {
          setActivationCode(getActivationCodeFromQrLink(qrLink))
          activationCodeRef.current = getActivationCodeFromQrLink(qrLink)
        } else {
          // if invalid link, then show error and open qr scanner again
          setIosLinkError(true)
          setTimeout(() => postMessageToIos(openQrAction), 2000)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      if (activationCode) {
        await handleApiAuth({
          apiId: ApiIds.PWA_SESSION_INFO_FROM_ACTIVATION_CODE,
          onAuthSuccess: onVerifyCodeAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("qrscanner.verifyActivationCode"),
          mpinReason: t("qrscanner.verifyActivationCode"),
          toast,
          navigate,
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode])

  const onVerifyCodeAuthSuccess = async apiToken => {
    try {
      const response = await CardService.verifyActivationCode(
        apiToken,
        user.cards[0]?.id,
        activationCode,
      )
      const result = response?.data

      if (result?.success && result?.data?.accountId === user.account.id) {
        if (!user.enablePciWidget) {
          localStorage.setItem("activationCode", activationCode)
          navigate("/ResetPin", {
            state: { from: "Activation" },
            replace: true,
          })
        } else {
          await handleApiAuth({
            apiId: ApiIds.PCI_SET_PIN_URL,
            onAuthSuccess: onPciSetPinAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: t("qrscanner.resetCardPinReason"),
            mpinReason: t("qrscanner.resetCardPinReason"),
            toast,
            navigate,
          })
        }
      } else {
        consoleError(result.errors)
        toast(t("qrscanner.qrForActivationText"))
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast(t("qrscanner.activationError"))
      navigate("/", { replace: true })
    }
  }

  const onPciSetPinAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getResetPinWidget(
        apiToken,
        user.cards[0]?.id,
        {
          callbackUrl:
            (config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1) &&
            screen?.home?.isCardControlsInActivationFlow
              ? process.env.REACT_APP_URL + Paths.ACTIVATION_CARD_CONTROLS
              : process.env.REACT_APP_URL + Paths.HOME_WITH_ACTIVATE,
        },
      )
      const result = response.data

      if (result?.success) {
        localStorage.setItem("activationCode", activationCode)
        window.location.href = result.data
      } else {
        consoleError(result?.errors)
        toast(t("qrscanner.setPinError"))
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast(t("qrscanner.setPinError"))
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const isQrLinkValid = link =>
    !qrcodeBaseUrl || link?.startsWith(qrcodeBaseUrl)

  const getActivationCodeFromQrLink = link => {
    if (!qrcodeBaseUrl) return link
    else return link?.replace(qrcodeBaseUrl, "")
  }

  const handldeScan = result => {
    if (isQrLinkValid(result)) {
      setActivationCode(getActivationCodeFromQrLink(result))
      activationCodeRef.current = result
    } else if (result && activationCodeRef.current !== result) {
      toast(t("qrscanner.invalidQRError"))
      activationCodeRef.current = result
    }
  }

  const handleError = async error => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const queries = devices
      .filter(({ kind }) => kind === "videoinput")
      .map(({ deviceId }) => ({ name: "camera", deviceId }))

    if (queries.length === 0) {
      setError(true)
      setErrorMessage(t("qrscanner.cameraConnectionRequiredError"))
      return
    }

    const promises = queries.map(queryObj =>
      navigator.permissions.query(queryObj),
    )
    try {
      const results = await Promise.all(promises)
      // log the state of each camera
      results.forEach(({ state }, i) => {
        if (state === "granted") {
          cameraGranted.current = cameraGranted.current + 1
        }
      })
      if (cameraGranted.current === 0) {
        setError(true)
        setErrorMessage(t("qrscanner.cameraAccessRequiredError"))
        return
      }
    } catch (error) {
      setError(true)
      setErrorMessage(t("qrscanner.genericError"))
      consoleError(error)
      return
    }
  }

  return (
    <>
      {!location?.state?.activationCode ? (
        <View alignItems='center' justifyContent='center'>
          <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
            {error ? (
              <ErrorScreen
                retry={true}
                updateQrPageErrorScreen={updateQrPageErrorScreen}
                message={errorMessage}
              />
            ) : iosLinkError ? (
              <ScrollView>
                <View w='100%' h={windowDimensions.height} minHeight='750px'>
                  <View alignItems='center' justifyContent='center' mt='30%'>
                    <View w='80%'>
                      <View mt='6px'>
                        <Text
                          fontFamily={theme.fontFamily}
                          fontWeight='400'
                          fontSize='16px'
                          lineHeight='20px'
                          color='#7B7B7B'
                          textAlign='center'
                        >
                          {t("qrscanner.InvalidActivationCode")}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </ScrollView>
            ) : isIosDevice() ? (
              <View>
                <View mt='30%' ml='50%'>
                  Loading...
                </View>
              </View>
            ) : (
              <View>
                <View
                  {...styles.mainContainer}
                  height={windowDimensions.height}
                >
                  {config.version === PwaVersions.V2 ||
                  config.version === PwaVersions.V2_1 ? (
                    <Header
                      text='Activate Card'
                      onBack={async () => {
                        window.history.go(-1)
                      }}
                    />
                  ) : (
                    <View {...styles.header}>
                      <ChevronLeftIcon
                        {...styles.back}
                        onClick={() => navigate("/")}
                      />
                    </View>
                  )}

                  <View {...styles.QrReader} borderColor={theme.color1}>
                    <QrReader
                      ref={qrCode}
                      delay={300}
                      style={{
                        width: "100%",
                        borderRadius: 16,
                        borderWidth: 2,
                      }}
                      onError={handleError}
                      onScan={handldeScan}
                      showViewFinder={false}
                    />
                  </View>
                  <View alignItems='center'>
                    <Text fontFamily={theme.fontFamily} {...styles.infoText}>
                      {t("qrscanner.scanQRPromptText")}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      ) : (
        <></>
      )}
    </>
  )
}

export default QRscanner
