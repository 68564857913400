import moment from "moment"
import React, { lazy, Suspense } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { ProgramTypes } from "../../utils/constants"
import { PwaVersions } from "../../utils/enums"
import BilledTransactionLoader from "../core/SkeletonLoader/BilledTransaction"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"
import WebViewShowTransactions from "./v3/webView/AllTransactions"
import ShowTransactions_v3 from "./v3/showTransactions"
import usePaylaterDesktopViewEnabled from "../../hooks/usePaylaterDesktopViewEnabled"
// import ShowTransactions_v2 from "./v2/showTransactions"
const ShowTransactions_v2 = lazy(() => import("./v2/showTransactions"))

const Index = () => {
  const location = useLocation()
  const config = useSelector(state => state.config)
  const user = useSelector(state => state.user)
  const windowDimension = useWindowDimensions()

  const isWebViewEnabled = usePaylaterDesktopViewEnabled()

  const getVersioned = () => {
    switch (config.version) {
      case PwaVersions.V2:
        return (
          <Suspense fallback={<BilledTransactionLoader />}>
            <ShowTransactions_v2
              from={
                user.programType === ProgramTypes.PREPAID
                  ? `${moment().subtract(89, "days").format("YYYY-MM-DD")}`
                  : location.state.from
              }
              to={
                user.programType === ProgramTypes.PREPAID
                  ? `${moment().format("YYYY-MM-DD")}`
                  : location.state.to
              }
            />
          </Suspense>
        )
      case PwaVersions.V2_1:
        return (
          <Suspense fallback={<BilledTransactionLoader />}>
            <ShowTransactions_v2
              from={
                user.programType === ProgramTypes.PREPAID
                  ? `${moment().subtract(89, "days").format("YYYY-MM-DD")}`
                  : location.state.from
              }
              to={
                user.programType === ProgramTypes.PREPAID
                  ? `${moment().format("YYYY-MM-DD")}`
                  : location.state.to
              }
            />
          </Suspense>
        )
      case PwaVersions.V3:
        return (
          <Suspense fallback={<BilledTransactionLoader />}>
            {!isWebViewEnabled ? (
              <ShowTransactions_v3 />
            ) : (
              <WebViewShowTransactions />
            )}
          </Suspense>
        )
      default:
        return (
          <Suspense fallback={<BilledTransactionLoader />}>
            <ShowTransactions_v2
              from={
                user.programType === ProgramTypes.PREPAID
                  ? `${moment().subtract(89, "days").format("YYYY-MM-DD")}`
                  : location.state.from
              }
              to={
                user.programType === ProgramTypes.PREPAID
                  ? `${moment().format("YYYY-MM-DD")}`
                  : location.state.to
              }
            />
          </Suspense>
        )
    }
  }
  return getVersioned()
}

export default Index
