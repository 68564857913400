const PanCardSvg = props => (
  <svg
    width='18'
    height='19'
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.75449 9.79242C5.76224 9.79242 4.95525 8.98465 4.95525 7.9924C4.95525 7.00015 5.76224 6.19238 6.75449 6.19238C7.74674 6.19238 8.5545 7.00015 8.5545 7.9924C8.5545 8.98465 7.74674 9.79242 6.75449 9.79242ZM6.75449 7.31738C6.38249 7.31738 6.08025 7.6204 6.08025 7.9924C6.08025 8.3644 6.38249 8.66742 6.75449 8.66742C7.12649 8.66742 7.4295 8.3644 7.4295 7.9924C7.4295 7.6204 7.12649 7.31738 6.75449 7.31738ZM9 13.3174C8.6895 13.3174 8.4375 13.0654 8.4375 12.7549V12.5276C8.4375 11.9876 8.06477 11.4424 7.23152 11.4424H6.26926C5.43601 11.4424 5.06323 11.9876 5.06323 12.5276V12.7549C5.06323 13.0654 4.81123 13.3174 4.50073 13.3174C4.19023 13.3174 3.93823 13.0654 3.93823 12.7549V12.5276C3.93823 11.4296 4.73851 10.3174 6.26926 10.3174H7.23152C8.76227 10.3174 9.5625 11.4289 9.5625 12.5276V12.7549C9.5625 13.0654 9.3105 13.3174 9 13.3174ZM13.5 15.9424H4.5C2.6865 15.9424 1.6875 14.9434 1.6875 13.1299V6.37988C1.6875 4.56638 2.6865 3.56738 4.5 3.56738H13.5C15.3135 3.56738 16.3125 4.56638 16.3125 6.37988V13.1299C16.3125 14.9434 15.3135 15.9424 13.5 15.9424ZM4.5 4.69238C3.31725 4.69238 2.8125 5.19713 2.8125 6.37988V13.1299C2.8125 14.3126 3.31725 14.8174 4.5 14.8174H13.5C14.6828 14.8174 15.1875 14.3126 15.1875 13.1299V6.37988C15.1875 5.19713 14.6828 4.69238 13.5 4.69238H4.5ZM14.0625 8.20837C14.0625 7.89787 13.8105 7.64587 13.5 7.64587H10.5C10.1895 7.64587 9.9375 7.89787 9.9375 8.20837C9.9375 8.51887 10.1895 8.77087 10.5 8.77087H13.5C13.8105 8.77087 14.0625 8.51887 14.0625 8.20837ZM14.025 11.2549C14.025 10.9444 13.773 10.6924 13.4625 10.6924H11.2125C10.902 10.6924 10.65 10.9444 10.65 11.2549C10.65 11.5654 10.902 11.8174 11.2125 11.8174H13.4625C13.773 11.8174 14.025 11.5654 14.025 11.2549Z'
      fill='#25314C'
    />
  </svg>
)

export default PanCardSvg
