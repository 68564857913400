import React from "react"
import { useSelector } from "react-redux"
import CrossButton from "../../../../svg/v3/CrossButton"
import CustomerServiceCard from "./CustomerServiceCard"
import { CustomerCareContactTypes } from "../../../../../utils/enums"
import { BottomSheet } from "react-spring-bottom-sheet"
import { useTranslation } from "react-i18next"

const CustomerSupportBottomSheet = ({
  isOpen,
  onClose,
  handleContactsClick,
}) => {
  const [theme, screen] = useSelector(state => [state.theme, state.screen])
  const { t } = useTranslation()
  const {
    tertiaryNeutral: {
      color1: tertiaryNeutralColor1,
      color3: tertiaryNeutralColor3,
    },
  } = theme.v3.rawColors

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div className='v3-customer-support-bottomSheet'>
        <div
          className='v3-customer-support-email-query-header'
          style={{
            color: tertiaryNeutralColor1,
          }}
        >
          <div className='v3-customerSupport-bottomSheet-title'>
            {screen?.customerCare?.descText ||
              t("customerSupport.supportDescription")}
          </div>
          <div onClick={() => onClose()}>
            <CrossButton
              backgroundColor={tertiaryNeutralColor3}
              color={tertiaryNeutralColor1}
            />
          </div>
        </div>
        <CustomerServiceCard
          handleOnClick={handleContactsClick}
          contactType={CustomerCareContactTypes.CALL}
        />
        <CustomerServiceCard
          handleOnClick={handleContactsClick}
          contactType={CustomerCareContactTypes.EMAIL}
        />
      </div>
    </BottomSheet>
  )
}

export default CustomerSupportBottomSheet
