import { useLocation, useNavigate } from "react-router-dom"
import { Header } from "../../../../core"
import successTickAnimation from "../../../../../components/svg/v3/successTickAnimation.json"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"
import { useEffect, useState } from "react"
import { useLottie } from "lottie-react"
import DividerV3 from "../../../../core/Divider/v3/DividerV3"
import { ColorButton } from "../../../../core/buttons"
import loadingTransactions from "../../../../../components/svg/v3/loadingTransactions.json"
import { replaceColor } from "lottie-colorify"
import { useSelector } from "react-redux"
import { formatDateTime } from "../../../../../utils/functions"
import { useTranslation } from "react-i18next"

const AuthenticationTxnSuccess_v3 = ({ amountprice, clientName }) => {
  const navigate = useNavigate()
  const [animationData, setAnimationData] = useState(null)
  const [ispaymentCompleted, setispaymentCompleted] = useState(false)
  const { width } = useWindowDimensions()
  const location = useLocation()
  const paymentCompleted = location.state?.ispaymentCompleted
  const createdOn = location.state?.createdOn
  const amount = location.state?.amount || amountprice
  const client = location.state?.client || clientName
  const successRedirectUrl = location.state?.redirectUrlSuccess
  const { t } = useTranslation()

  useEffect(() => {
    if (successRedirectUrl) {
      const timer = setTimeout(() => {
        window.location.href = successRedirectUrl
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [successRedirectUrl])

  const theme = useSelector(state => state.theme)

  useEffect(() => {
    if (paymentCompleted) {
      setispaymentCompleted(true)
    } else {
      setispaymentCompleted(false)
    }
  }, [paymentCompleted])

  useEffect(() => {
    const primaryColor = theme.v3.rawColors.primary.color1

    const successAnimation = replaceColor(
      "#005aff",
      primaryColor,
      successTickAnimation,
    )
    const loadingAnimation = replaceColor(
      "#005aff",
      primaryColor,
      loadingTransactions,
    )

    setAnimationData(ispaymentCompleted ? successAnimation : loadingAnimation)
  }, [ispaymentCompleted, theme])

  const { View } = useLottie(
    {
      animationData: animationData,
      loop: true,
      autoplay: true,
      speed: 1.0,
    },
    {
      height: width <= 550 ? (ispaymentCompleted ? 118 : 142) : 222,
      width: width <= 550 ? (ispaymentCompleted ? 118 : 142) : 222,
      maxWidth: width,
    },
  )

  const handleSuccessRedirect = () => {
    window.location.href = successRedirectUrl
  }

  const renderMobileView = () => (
    <div className='v3-authenticationTxnSuccess-container'>
      <div className='v3-acs-loader-container'>
        <div className={!ispaymentCompleted ? "v3-acs-loader" : ""}>{View}</div>
        {!ispaymentCompleted && (
          <div className='v3-acs-loader-text-name'>
            {t("PaymentGateway.processingPayment")} {client}
          </div>
        )}
        {!ispaymentCompleted && (
          <div className='v3-acs-loader-text-sub'>
            {t("PaymentGateway.verifiedPayment")}
          </div>
        )}
        {ispaymentCompleted && (
          <div className='v3-acs-loader-text-amount'>₹{amount}</div>
        )}
        {ispaymentCompleted && (
          <div className='v3-acs-loader-text-name'>
            {t("PaymentGateway.payingTo")} {client}
          </div>
        )}
        {ispaymentCompleted && (
          <div className='v3-acs-loader-text-date'>
            {formatDateTime(createdOn)}
          </div>
        )}
      </div>
      {ispaymentCompleted && (
        <div className='v3-login-otp-cta'>
          <DividerV3 />
          <div className='v3-verify-otp-btn'>
            <ColorButton
              text={t("mpin.doneText")}
              onPress={handleSuccessRedirect}
              margin={"auto"}
            />
          </div>
        </div>
      )}
    </div>
  )

  const renderDesktopView = () => (
    <div className='v3-authenticationTxnSuccess-desktop-container'>
      <Header
        onBack={async () => {
          navigate("/")
        }}
        customClassName={"v3-acs-desktop-header"}
      />
      <div className='v3-acs-loader-desktop-price-container'>
        <div className='v3-acs-loader-desktop-header'>
          {t("PaymentGateway.payingTo")}{" "}
          <span className='v3-acs-loader-desktop-header-span'>{client}:</span>
        </div>
        <div className='v3-acs-loader-desktop-price'>₹{amount}</div>
      </div>
      <div
        className={
          ispaymentCompleted
            ? "v3-acs-loader-desktop-body-container-success"
            : "v3-acs-loader-desktop-body-container"
        }
      >
        <div
          className={
            ispaymentCompleted
              ? "v3-acs-loader-desktop-animation-success"
              : "v3-acs-loader-desktop-animation"
          }
        >
          {View}
        </div>
        {ispaymentCompleted && (
          <div className='v3-acs-loader-desktop-content'>
            <div className='v3-acs-loader-text-name-desktop'>
              {t("PaymentGateway.paymentComplete")}
            </div>
            <div className='v3-acs-loader-text-date-desktop'>
              {formatDateTime(createdOn)}
            </div>
          </div>
        )}
        {ispaymentCompleted && (
          <div className='v3-verify-otp-btn-desktop'>
            <ColorButton
              text={t("mpin.doneText")}
              onPress={handleSuccessRedirect}
              margin={"auto"}
            />
          </div>
        )}
      </div>
    </div>
  )

  return width <= 550 ? renderMobileView() : renderDesktopView()
}

export default AuthenticationTxnSuccess_v3
