import React, { useState, useEffect, useRef, useCallback } from "react"
import Cross from "../../svg/v3/Cross"
import { ColorButton } from "../../core/buttons"
import BottomSheetDateValues from "./bottomSheetDateValues"
import Cycles from "../../svg/v3/cycles"
import Calender from "../../svg/v3/Calender"
import { getFormatedBillingCycle } from "../../../utils/functions"
import CheckIcon from "../../svg/v3/checkIcon"
import { useSelector } from "react-redux"
import moment from "moment"
import { Input } from "../../../nativeBaseReplacements"
import crossIcon from "../../../assets/images/v3/closeBtn.svg"
import { FilterBottomSheetTabs } from "../../../utils/constants"
import Error_v3 from "../../core/Error_v3"
import { customDateRangeFilterFields } from "../../../utils/enums"
import { useTranslation } from "react-i18next"

const durations = [
  {
    label: "Today",
    from: moment().startOf("day").format("YYYY-MM-DD"),
    to: moment().endOf("day").format("YYYY-MM-DD"),
  },
  {
    label: "This Week",
    from: moment().startOf("week").format("YYYY-MM-DD"),
    to: moment().endOf("week").format("YYYY-MM-DD"),
  },
  {
    label: "This Month",
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: "Custom Range",
  },
]

const FilterByDate = ({
  billingCycles,
  selectedCycle,
  setCustomDateRange,
  customDateRange,
  handleDateSelect,
  handleApplyFilter,
  setIsBottomSheetOpen,
  invalidCustomDateRange,
  setInvalidCustomDateRange,
  setDateType,
  dateType,
}) => {
  const theme = useSelector(state => state.theme)
  // const [dateType, setDateType] = useState(FilterBottomSheetTabs.CYCLE)
  const [isDobInputFocused, setIsDobInputFocused] = useState(false)
  const [changeFocus, setChangeFocus] = useState(false)
  const fromDateInput = useRef(null)
  const toDateInput = useRef(null)
  const [error, setError] = useState(false)
  const dateBottomSheetBodyRef = useRef()
  const { t } = useTranslation()

  const handleDateTypeClick = type => {
    setDateType(type)
  }

  useEffect(() => {
    if (
      invalidCustomDateRange?.invalidRange ||
      invalidCustomDateRange?.durationExceeded
    ) {
      dateBottomSheetBodyRef.current.scrollTop =
        dateBottomSheetBodyRef.current.scrollHeight
    }
  }, [
    invalidCustomDateRange?.invalidRange,
    invalidCustomDateRange?.durationExceeded,
  ])

  const setFromDate = useCallback(
    date => {
      if (
        (date.length > customDateRange?.from?.length &&
          date[date.length - 1] === "/") ||
        date.length > 10
      ) {
        return
      }
      setInvalidCustomDateRange({
        invalidRange: false,
        durationExceeded: false,
        invalidField: null,
      })
      date = date.replace(/[^0-9/]/g, "")
      ;(date.length === 2 || date.length === 5) &&
      date.length > customDateRange?.from?.length
        ? setCustomDateRange(prev => ({
            ...prev,
            from: `${date}/`,
          }))
        : setCustomDateRange(prev => ({
            ...prev,
            from: date,
          }))
    },
    [customDateRange?.from],
  )

  const setToDate = useCallback(
    date => {
      if (
        (date.length > customDateRange?.to?.length &&
          date[date.length - 1] === "/") ||
        date.length > 10
      ) {
        return
      }
      setInvalidCustomDateRange({
        invalidRange: false,
        durationExceeded: false,
        invalidField: null,
      })
      date = date.replace(/[^0-9/]/g, "")
      ;(date.length === 2 || date.length === 5) &&
      date.length > customDateRange?.to?.length
        ? setCustomDateRange(prev => ({
            ...prev,
            to: `${date}/`,
          }))
        : setCustomDateRange(prev => ({
            ...prev,
            to: date,
          }))
    },
    [customDateRange?.to],
  )

  const handleFromDateInputChange = e => {
    const date = e.target.value
    setFromDate(date)
    if (date?.length >= 10) fromDateInput.current.blur()
  }

  const handleToDateInputChange = e => {
    const date = e.target.value
    setToDate(date)
    if (date?.length >= 10) toDateInput.current.blur()
  }

  const renderErrorContainer = () => {
    if (invalidCustomDateRange?.invalidRange) {
      if (
        invalidCustomDateRange?.invalidField ===
        customDateRangeFilterFields.BOTH
      ) {
        return (
          <Error_v3
            errorMessage={t(
              "payLaterWebComponents.transactionFilters.invalidDateRangeSelected",
            )}
            customClassName={"customerAuth"}
          />
        )
      } else {
        return (
          <Error_v3
            errorMessage={t(
              "payLaterWebComponents.transactionFilters.invalidDate",
            )}
            customClassName={"customerAuth"}
          />
        )
      }
    } else if (invalidCustomDateRange?.durationExceeded) {
      return (
        <Error_v3
          errorMessage={t(
            "payLaterWebComponents.transactionFilters.invalidDateRange",
          )}
          customClassName={"customerAuth"}
        />
      )
    }
  }

  const isInvalidDateField = field => {
    return (
      invalidCustomDateRange?.invalidRange &&
      (invalidCustomDateRange.invalidField === field ||
        invalidCustomDateRange.invalidField ===
          customDateRangeFilterFields.BOTH)
    )
  }

  return (
    <div className='v3-filter-by-date-bottomsheet-container'>
      <div className='v3-filter-by-date-bottomsheet-header'>
        <div className='v3-filter-by-date-bottomsheet-header-text'>
          {t("payLaterWebComponents.transactionFilters.filterByDate")}
        </div>
        <div
          className='v3-filter-by-date-bottomsheet-header-close-icon'
          onClick={() => setIsBottomSheetOpen(false)}
        >
          <img src={crossIcon} />
        </div>
      </div>
      <div className='v3-filter-by-date-bottomsheet-date-types'>
        <div
          className='v3-filter-by-date-bottomsheet-cycles'
          onClick={() => handleDateTypeClick(FilterBottomSheetTabs.CYCLE)}
          style={{
            borderBottom:
              dateType === FilterBottomSheetTabs.CYCLE &&
              `4px solid ${theme.v3.cssVars.secondary.color1}`,
            fontWeight: dateType === FilterBottomSheetTabs.CYCLE && "700",
            color:
              dateType === FilterBottomSheetTabs.CYCLE &&
              `${theme.v3.cssVars.secondary.color1}`,
          }}
        >
          <div className='v3-filter-by-date-bottomsheet-cycles-icon'>
            <Cycles
              width={"20"}
              height={"20"}
              color={
                dateType === FilterBottomSheetTabs.CYCLE
                  ? `${theme.v3.rawColors.secondary.color1}`
                  : `${theme.v3.rawColors.tertiaryNeutral.color2}`
              }
            />
          </div>
          <div
            className='v3-filter-by-date-bottomsheet-cycles-text'
            style={{
              color:
                dateType === FilterBottomSheetTabs.CYCLE
                  ? `${theme.v3.rawColors.secondary.color1}`
                  : `${theme.v3.rawColors.tertiaryNeutral.color2}`,
            }}
          >
            {t("payLaterWebComponents.transactionFilters.byCycle")}
          </div>
        </div>
        <div
          className='v3-filter-by-date-bottomsheet-duration'
          onClick={() => handleDateTypeClick(FilterBottomSheetTabs.DURATION)}
          style={{
            borderBottom:
              dateType === FilterBottomSheetTabs.DURATION &&
              `4px solid ${theme.v3.cssVars.secondary.color1}`,
            fontWeight: dateType === FilterBottomSheetTabs.DURATION && "700",
            color:
              dateType === FilterBottomSheetTabs.DURATION &&
              `${theme.v3.cssVars.secondary.color1}`,
          }}
        >
          <div className='v3-filter-by-date-bottomsheet-duration-icon'>
            <Calender
              color={
                dateType === FilterBottomSheetTabs.DURATION
                  ? `${theme.v3.rawColors.secondary.color1}`
                  : `${theme.v3.rawColors.tertiaryNeutral.color2}`
              }
            />
          </div>
          <div
            className='v3-filter-by-date-bottomsheet-duration-text'
            style={{
              color:
                dateType === FilterBottomSheetTabs.DURATION
                  ? `${theme.v3.rawColors.secondary.color1}`
                  : `${theme.v3.rawColors.tertiaryNeutral.color2}`,
            }}
          >
            {t("payLaterWebComponents.transactionFilters.byDuration")}
          </div>
        </div>
      </div>
      <div
        className='v3-summary-bottom-sheet-body'
        ref={dateBottomSheetBodyRef}
      >
        {dateType === FilterBottomSheetTabs.CYCLE ? (
          billingCycles?.map((cycle, index) => {
            const fromDate = cycle.fromDate || cycle.from
            const toDate = cycle.toDate || cycle.to
            const formattedBillingCycle = getFormatedBillingCycle(
              fromDate,
              toDate,
            )
            const isSelected = formattedBillingCycle === selectedCycle?.label
            return (
              <div
                className={`v3-summary-bottom-sheet-body-date-container ${
                  isSelected ? "selected-cycle" : "not-selected-cycle"
                }`}
                onClick={() =>
                  handleDateSelect(formattedBillingCycle, fromDate, toDate)
                }
              >
                <div
                  className={`v3-summary-bottom-sheet-body-date-value ${
                    isSelected ? "selected-date" : "not-selected-date"
                  }`}
                >
                  {formattedBillingCycle} {index === 0 && "(unbilled)"}
                </div>
                {formattedBillingCycle === selectedCycle?.label && (
                  <div className='v3-summary-bottom-sheet-body-date-selected-icon'>
                    <CheckIcon
                      color={theme.v3.cssVars.secondary.color1}
                      width={"25.5px"}
                      height={"21.5px"}
                    />
                  </div>
                )}
              </div>
            )
          })
        ) : (
          <>
            {durations?.map((duration, index) => {
              const durationLabel = duration.label
              const fromDate = duration.from
              const toDate = duration.to
              const isSelected = durationLabel === selectedCycle?.label
              return (
                <>
                  <div
                    className={`v3-summary-bottom-sheet-body-date-container ${
                      isSelected ? "selected-cycle" : "not-selected-cycle"
                    }`}
                    onClick={() =>
                      handleDateSelect(durationLabel, fromDate, toDate)
                    }
                  >
                    <div
                      className={`v3-summary-bottom-sheet-body-date-value ${
                        isSelected ? "selected-date" : "not-selected-date"
                      }`}
                    >
                      {durationLabel}
                    </div>
                    {durationLabel === selectedCycle?.label && (
                      <div className='v3-summary-bottom-sheet-body-date-selected-icon'>
                        <CheckIcon
                          color={theme.v3.cssVars.secondary.color1}
                          width={"25.5px"}
                          height={"21.5px"}
                        />
                      </div>
                    )}
                  </div>
                </>
              )
            })}
            {selectedCycle?.label === "Custom Range" && (
              <div className='v3-custom-range-container'>
                <div className='v3-custom-range-from-container'>
                  <div className='v3-custom-range-from-label'>
                    <div className='v3-custom-range-date-icon'>
                      <Calender color='#2680EA' width={"12"} height={"12"} />
                    </div>
                    <div className='v3-custom-range-date-text'>
                      {t("payLaterWebComponents.transactionFilters.fromDate")}
                    </div>
                  </div>
                  <div className='v3-custom-range-from-input'>
                    <Input
                      onFocus={() => {
                        setChangeFocus(false)
                        setIsDobInputFocused(true)
                      }}
                      onBlur={() => setIsDobInputFocused(false)}
                      value={customDateRange?.from}
                      ref={fromDateInput}
                      onChange={handleFromDateInputChange}
                      width='100%'
                      fontSize={14}
                      backgroundColor={theme.v3.rawColors.leadingWhite}
                      placeholderTextColor={
                        theme.v3.rawColors.tertiaryNeutral.color2
                      }
                      borderWidth='0px'
                      borderBottomWidth={"1px"}
                      py={3}
                      my={2}
                      focusOutlineColor={theme.v3.rawColors.primary.color1}
                      borderBottomColor={
                        isInvalidDateField(customDateRangeFilterFields.FROM)
                          ? theme.v3.rawColors.tertiaryNegative.color1
                          : isDobInputFocused
                            ? theme.v3.rawColors.primary.color1
                            : theme.v3.rawColors.tertiaryNeutral.color2
                      }
                      // borderColor={error ? "#C2181B" : "#ECECEC"}
                      placeholder='DD/MM/YYYY'
                    />
                  </div>
                </div>
                <div className='v3-custom-range-to-container'>
                  <div className='v3-custom-range-from-label'>
                    <div className='v3-custom-range-date-icon'>
                      <Calender color='#2680EA' width={"12"} height={"12"} />
                    </div>
                    <div className='v3-custom-range-date-text'>
                      {t("payLaterWebComponents.transactionFilters.toDate")}
                    </div>
                  </div>
                  <div className='v3-custom-range-from-input'>
                    <Input
                      onFocus={() => {
                        setChangeFocus(false)
                        setIsDobInputFocused(true)
                      }}
                      onBlur={() => setIsDobInputFocused(false)}
                      value={customDateRange?.to}
                      ref={toDateInput}
                      onChange={handleToDateInputChange}
                      width='100%'
                      fontSize={14}
                      backgroundColor={theme.v3.rawColors.leadingWhite}
                      placeholderTextColor={
                        theme.v3.rawColors.tertiaryNeutral.color2
                      }
                      borderWidth='0px'
                      borderBottomWidth={"1px"}
                      py={3}
                      my={2}
                      focusOutlineColor={theme.v3.rawColors.primary.color1}
                      borderBottomColor={
                        isInvalidDateField(customDateRangeFilterFields.TO)
                          ? theme.v3.rawColors.tertiaryNegative.color1
                          : isDobInputFocused
                            ? theme.v3.rawColors.primary.color1
                            : theme.v3.rawColors.tertiaryNeutral.color2
                      }
                      // borderColor={error ? "#C2181B" : "#ECECEC"}
                      placeholder='DD/MM/YYYY'
                    />
                  </div>
                </div>
              </div>
            )}
            <div className='v3-custom-range-error-container'>
              {renderErrorContainer()}
            </div>
          </>
        )}
      </div>
      <div className='v3-summary-bottom-sheet-cta-button'>
        <ColorButton
          color={"#FFFFFF"}
          width={"100%"}
          text={t("payLaterWebComponents.transactionFilters.applyFilters")}
          isDisable={
            invalidCustomDateRange?.invalidRange ||
            invalidCustomDateRange?.durationExceeded ||
            (customDateRange?.from && customDateRange?.from?.length !== 10) ||
            (customDateRange?.to && customDateRange?.to?.length !== 10)
          }
          onPress={handleApplyFilter}
        />
      </div>
    </div>
  )
}

export default FilterByDate
