import ApiIds from "../auth/ApiIds"
import Service from "../services/Service"
import store from "../store/Store"
import { setAuthState, setCurrentFactor } from "../store/actions/Auth"
import { AuthChallenges, AuthScopes } from "./constants"
import { AuthenticationType, PwaVersions } from "./enums"

// get api token
export const getApiToken = async (apiId, flowId) => {
  const authStore = store.getState().auth
  const challenges = authStore.afaDetails.config[apiId].challenges
  const conditional = authStore.afaDetails.config[apiId].conditional
  const dynamicChallenges = authStore.afaDetails.config[apiId].dynamicChallenges

  // get reference ids of challenges
  const deviceId = authStore.verifiedChallenges.deviceId
  const otpRefId = authStore.verifiedChallenges.otpRefId
  const emailOtpRefId = authStore.verifiedChallenges.emailOtpRefId
  const mpinRefId = authStore.verifiedChallenges.mpinRefId
  const customerRefId = authStore.verifiedChallenges.customerRefId

  let challengesToUse = challenges || []
  let requiredFlow = null

  if (conditional) {
    requiredFlow = dynamicChallenges.find(
      flow =>
        flow.deviceTokenPresent === Boolean(deviceId) &&
        flow.mpinKnown === Boolean(mpinRefId) &&
        (flowId ? flow.flowId === flowId : true),
    )

    if (requiredFlow) {
      challengesToUse = requiredFlow.challenges
    }
  }

  // get factors
  const firstFactor = challengesToUse.find(
    data => data.scope === AuthScopes.FIRST_FACTOR,
  )
  const secondFactor = challengesToUse.find(
    data => data.scope === AuthScopes.SECOND_FACTOR,
  )

  const verifiedChallengeReference = {}

  // construct the 'verifiedChallengeReference' object according to required and completed challenges
  if (firstFactor) {
    // if first factor is required
    if (firstFactor.challenge === AuthChallenges.OTP_SMS && otpRefId) {
      // if first factor's challenge is otp and an otp verification was done
      verifiedChallengeReference.firstFactorMethod = AuthChallenges.OTP_SMS
      verifiedChallengeReference.firstFactorRefId = otpRefId
    }
    if (firstFactor.challenge === AuthChallenges.OTP_EMAIL && emailOtpRefId) {
      // if first factor's challenge is otp and an otp verification was done
      verifiedChallengeReference.firstFactorMethod = AuthChallenges.OTP_EMAIL
      verifiedChallengeReference.firstFactorRefId = emailOtpRefId
    } else if (firstFactor.challenge === AuthChallenges.MPIN && mpinRefId) {
      verifiedChallengeReference.firstFactorMethod = AuthChallenges.MPIN
      verifiedChallengeReference.firstFactorRefId = mpinRefId
    } else if (
      firstFactor.challenge === AuthChallenges.VERIFY_CUSTOMER &&
      customerRefId
    ) {
      verifiedChallengeReference.firstFactorMethod =
        AuthChallenges.VERIFY_CUSTOMER
      verifiedChallengeReference.firstFactorRefId = customerRefId
    }
  }

  if (secondFactor) {
    if (secondFactor.challenge === AuthChallenges.OTP_SMS && otpRefId) {
      verifiedChallengeReference.secondFactorMethod = AuthChallenges.OTP_SMS
      verifiedChallengeReference.secondFactorRefId = otpRefId
    } else if (
      secondFactor.challenge === AuthChallenges.OTP_EMAIL &&
      emailOtpRefId
    ) {
      verifiedChallengeReference.secondFactorMethod = AuthChallenges.OTP_EMAIL
      verifiedChallengeReference.secondFactorRefId = emailOtpRefId
    } else if (secondFactor.challenge === AuthChallenges.MPIN && mpinRefId) {
      verifiedChallengeReference.secondFactorMethod = AuthChallenges.MPIN
      verifiedChallengeReference.secondFactorRefId = mpinRefId
    } else if (
      secondFactor.challenge === AuthChallenges.VERIFY_CUSTOMER &&
      customerRefId
    ) {
      verifiedChallengeReference.secondFactorMethod =
        AuthChallenges.VERIFY_CUSTOMER
      verifiedChallengeReference.secondFactorRefId = customerRefId
    }
  }

  // note: currently step up doesn't have cool off and hence is not a part of the 'verifiedChallengeReference' object

  const req = {
    apiId,
    verifiedChallengeReference,
  }

  if (requiredFlow) {
    req.conditional = true

    req.conditions = {
      flowId: requiredFlow.flowId,
    }

    if (requiredFlow.deviceTokenPresent && deviceId) {
      req.conditions.deviceToken = deviceId
    }

    if (requiredFlow.mpinKnown && mpinRefId) {
      req.conditions.mpinRefId = mpinRefId
    }
  }

  const response = await Service.post(`/auth/apiToken`, req)
  return response.data
}

// handle auth for api
const navigateToScreen = (
  Factor,
  navigate,
  version,
  pageNavigation,
  bottomSheetContext,
) => {
  if (Factor.challenge === AuthChallenges.OTP_SMS) {
    // there can be a case when one api auth ends with an otp challenge and its success callback calls
    // another api auth that starts with an otp challenge. Hence first navigate to a dummy page to ensure that
    // the otp screen remounts.
    if (version == PwaVersions.V3 && !pageNavigation) {
      bottomSheetContext?.openBottomSheet(AuthenticationType.OTP)
    } else {
      navigate("/SessionExpired", { replace: true })
      navigate("/Auth/Otp", { replace: true })
    }
  } else if (Factor.challenge === AuthChallenges.MPIN) {
    if (version == PwaVersions.V3 && !pageNavigation) {
      bottomSheetContext?.openBottomSheet(AuthenticationType.MPIN)
    } else {
      navigate("/SessionExpired", { replace: true })
      navigate("/Auth/Mpin", { replace: true })
    }
  } else if (Factor.challenge === AuthChallenges.OTP_EMAIL) {
    navigate("/SessionExpired", { replace: true })
    navigate("/Auth/EmailOtp", { replace: true })
  } else if (Factor.challenge === AuthChallenges.VERIFY_CUSTOMER) {
    if (version == PwaVersions.V3 && !pageNavigation) {
      bottomSheetContext?.openBottomSheet(AuthenticationType.CUSTOMER_VERIFY)
    } else {
      navigate("/SessionExpired", { replace: true })
      navigate("/Auth/Customer", { replace: true })
    }
  }
}
export const handleApiAuth = async ({
  apiId, // id of the api that is to be called
  flowId = null, // id of the flow to be used in dynamic auth
  onAuthSuccess, // success callback
  onAuthFailure, // failure callback
  onAuthCancel, // cancel callback
  otpReason, // text to be displayed in otp screen
  mpinReason, // text to be displayed in mpin screen
  toast, // function for displaying toast
  navigate, // function for navigation
  version, // PWA version,
  pageNavigation, // navigate to page and not bottom sheet
  bottomSheetContext,
}) => {
  try {
    // check from config if auth is required
    const isAuthRequired =
      store.getState()?.auth?.afaDetails?.config[apiId]?.result

    if (isAuthRequired) {
      // if auth is required then api token would be needed for the api call

      // get the api token
      const result = await getApiToken(apiId, flowId)

      if (result?.success) {
        const { apiToken, challengeMetadata } = result.data
        if (
          challengeMetadata &&
          Array.isArray(challengeMetadata) &&
          challengeMetadata.length > 0
        ) {
          // if any challenge needs to be completed
          const firstFactor = challengeMetadata.find(
            data => data.scope === AuthScopes.FIRST_FACTOR,
          )
          const secondFactor = challengeMetadata.find(
            data => data.scope === AuthScopes.SECOND_FACTOR,
          )
          const stepUp = challengeMetadata.find(
            data => data.scope === AuthScopes.STEP_UP,
          )

          // ideally preceding factors should never be null, but handle that case
          const currentFactor = firstFactor || secondFactor || stepUp

          // set auth variables in store
          store.dispatch(
            setAuthState({
              apiId,
              apiToken,
              onAuthSuccess,
              onAuthFailure,
              onAuthCancel,
              factors: challengeMetadata,
              currentFactor: currentFactor,
              otpReason,
              mpinReason,
            }),
          )

          // navigate to current challenge screen
          navigateToScreen(
            currentFactor,
            navigate,
            version,
            pageNavigation,
            bottomSheetContext,
          )
        } else {
          // no challenge required, directly call success callback with api token
          bottomSheetContext?.closeBottomSheet()
          await onAuthSuccess(apiToken)
        }
      } else {
        bottomSheetContext?.closeBottomSheet()
        await onAuthFailure(result.errors, "Oops an internal error occurred!!")
      }
    } else {
      // auth not required, call success callback without api token
      bottomSheetContext?.closeBottomSheet()
      await onAuthSuccess(null)
    }
  } catch (error) {
    bottomSheetContext?.closeBottomSheet()
    await onAuthFailure(error, "Oops an internal error occurred!!")
  }
}

// get next factor to be completed after the current factor
// if no factor needed next then return null
export const getNextFactor = () => {
  const authStore = store.getState().auth

  // get factors
  const secondFactor = authStore.factors.find(
    data => data.scope === AuthScopes.SECOND_FACTOR,
  )
  const stepUp = authStore.factors.find(
    data => data.scope === AuthScopes.STEP_UP,
  )

  let nextFactor

  if (authStore.currentFactor.scope === AuthScopes.FIRST_FACTOR) {
    // if current factor's scope is first factor
    nextFactor = secondFactor || stepUp || null
  }

  if (authStore.currentFactor.scope === AuthScopes.SECOND_FACTOR) {
    nextFactor = stepUp || null
  }

  if (authStore.currentFactor.scope === AuthScopes.STEP_UP) {
    nextFactor = null
  }

  return nextFactor
}

export const onFactorCompletion = async (
  navigate,
  version,
  pageNavigation,
  bottomSheetContext,
) => {
  const authStore = store.getState().auth

  // get next factor
  const nextFactor = getNextFactor()

  if (nextFactor) {
    // iauthStorepdate current factor pointer
    // if another factor is pending
    // update current factor pointer
    store.dispatch(setCurrentFactor({ currentFactor: nextFactor }))
    // navigate to next challenge screen
    navigateToScreen(
      nextFactor,
      navigate,
      version,
      pageNavigation,
      bottomSheetContext,
    )
  } else {
    // else no more screens to show, call the success callback
    bottomSheetContext?.closeBottomSheet()
    await authStore.onAuthSuccess(authStore.apiToken)
  }
}

// get text to be shown in auth screen headers
export const getHeaderText = challenge => {
  const authStore = store.getState().auth
  const mpinWord =
    store.getState()?.screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  const deviceId = authStore.verifiedChallenges.deviceId
  const apiId = authStore.apiId
  const isMpinSet = authStore.afaDetails.config.isMpinSet.result
  if (apiId === ApiIds.SET_MPIN && isMpinSet) return `Change ${mpinWord}`
  else if (apiId === ApiIds.SET_MPIN && deviceId) return `Reset ${mpinWord}`
  else if (apiId === ApiIds.SET_MPIN && !deviceId) return `Setup ${mpinWord}`
  else if (apiId === ApiIds.ACCOUNT_SUMMARY) return "Login"
  else if (challenge === AuthChallenges.OTP_SMS) return "Verify OTP"
  else if (challenge === AuthChallenges.OTP_EMAIL) return "Verify Email OTP"
  else if (challenge === AuthChallenges.MPIN) return `Verify ${mpinWord}`
  else if (challenge === AuthChallenges.VERIFY_CUSTOMER)
    return "Verify PAN & DOB"
  else return ""
}

// if cancel button should be shown in auth screens
export const showCancelButton = () => {
  const authStore = store.getState().auth

  // if auth is for summary (home screen) or auth is for setting up mpin with no mpin currently set then don't provide cancel option
  return !(
    authStore.apiId === ApiIds.ACCOUNT_SUMMARY ||
    (authStore.apiId === ApiIds.SET_MPIN &&
      !authStore.afaDetails.config.isMpinSet.result)
  )
}
