import React from "react"
import nullImage from "../../../../../assets/images/v3/zerostatetxn.svg"
import moment from "moment"
import { useTranslation } from "react-i18next"

const ZeroStateTransactions = ({ billingCycle }) => {
  const { t } = useTranslation()
  return (
    <div className='v3-home-transactions-zero-state'>
      <div className='v3-home-transaction-zero-state-container'>
        <div className='v3-home-transaction-zero-state-image'>
          <img src={nullImage} alt='zero state image' />
        </div>
        <div className='v3-home-transaction-text-section'>
          <div className='v3-home-transaction-header-text'>
            {t("payLaterWebComponents.zeroTransactions.noTransactionsFound")}
          </div>
          {billingCycle ? (
            <div className='v3-home-transaction-description-text'>
              {t("payLaterWebComponents.zeroTransactions.forThisCycle")}{" "}
              <span>
                ({moment(billingCycle.from).format("DD MMM ")}-
                {moment(billingCycle.to).format(" DD MMM")})
              </span>
            </div>
          ) : (
            <div className='v3-home-transaction-description-text'>
              {t("payLaterWebComponents.zeroTransactions.trnxnDescriptionText")}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ZeroStateTransactions
