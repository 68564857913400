import React from "react"

import emptyTransaction from "../../../assets/images/emptyTransaction.svg"
import { useTranslation } from "react-i18next"

const Empty = props => {
  const { t } = useTranslation()
  return (
    <div className='emptyTransaction'>
      <div className='emptyTransaction-content'>
        <div
          className='emptyTransaction-content-img'
          style={{ backgroundColor: props.color3 }}
        >
          <img src={emptyTransaction} />
        </div>
        <div className='emptyTransaction-content-text-container'>
          <div
            className='emptyTransaction-content-text-1'
            style={{ fontFamily: props.fontFamily }}
          >
            {t("TransactionSection.noTransactionsFound")}
          </div>
          <div
            className='emptyTransaction-content-text-2'
            style={{ fontFamily: props.fontFamily }}
          >
            {t("TransactionSection.trackTrxnText")}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Empty
