import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { ColorButton } from "../../core/buttons"
import { FilterBottomSheetTabs } from "../../../utils/constants"
import CloseButton from "../../svg/v3/closeButton"
import Rotate from "../../svg/v3/Rotate"
import Calender from "../../svg/v3/Calender"
import Check from "../../svg/v3/Check"
import { useTranslation } from "react-i18next"

const FiltersBottomSheet = ({
  handleCloseButtonClick,
  selectedTab,
  setSelectedTab,
  cycles,
  selectedCycle,
  setSelectedCycle,
}) => {
  const theme = useSelector(state => state.theme)
  const [selectedDuration, setSelectedDuration] = useState(selectedCycle)
  const [customFromDate, setCustomFromDate] = useState("")
  const [customToDate, setCustomToDate] = useState("")
  const [lastFocusedInput, setLastFocusedInput] = useState("")
  const { t } = useTranslation()

  const {
    primary: { color1: primaryColor1 },
    tertiaryNeutral: {
      color1: tertiaryNeutralColor1,
      color2: tertiaryNeutralColor2,
      color3: tertiaryNeutralColor3,
    },
    secondary: { color1: secondaryColor1, color2: secondaryColor2 },
  } = theme?.v3?.rawColors

  const inputRefs = {
    customFromDate: useRef(null),
    customToDate: useRef(null),
  }

  const duration = [
    {
      text: t("payLaterWebComponents.transactionFilters.today"),
      duration: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
    },
    {
      text: t("payLaterWebComponents.transactionFilters.thisWeek"),
      duration: {
        from: moment().startOf("week").format("YYYY-MM-DD"),
        to: moment().endOf("week").format("YYYY-MM-DD"),
      },
    },
    {
      text: t("payLaterWebComponents.transactionFilters.thisMonth"),
      duration: {
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      },
    },
    {
      text: t("payLaterWebComponents.transactionFilters.thisYear"),
      duration: {
        from: moment().startOf("year").format("YYYY-MM-DD"),
        to: moment().endOf("year").format("YYYY-MM-DD"),
      },
    },
    {
      text: t("payLaterWebComponents.transactionFilters.customRange"),
      duration: { from: customFromDate, to: customToDate },
    },
  ]

  const applyFilters = () => {
    if (
      selectedDuration?.from ===
        duration?.[duration.length - 1]?.duration?.from &&
      selectedDuration?.to === duration?.[duration.length - 1]?.duration?.to
    ) {
      setSelectedCycle({
        from: moment(selectedDuration.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
        to: moment(selectedDuration.to, "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    } else {
      setSelectedCycle(selectedDuration)
    }

    handleCloseButtonClick()
  }

  const getTabStyles = tab => {
    if (tab === selectedTab) {
      return {
        borderBottom: "solid 4px var(--secondary-color-1)",
        color: "var(--secondary-color-1)",
        fontWeight: "800",
      }
    } else {
      return {
        color: "var(--tertiary-neutral-color-2)",
        fontWeight: "500",
      }
    }
  }

  const handleCustomDateChange = useCallback(
    (value, type) => {
      if (type === "from") {
        if (
          (value.length > customFromDate.length &&
            value[value.length - 1] === "/") ||
          value.length > 10
        ) {
          return
        }
        value = value.replace(/[^0-9/]/g, "")
        ;(value.length === 2 || value.length === 5) &&
        value.length > customFromDate.length
          ? setCustomFromDate(`${value}/`)
          : setCustomFromDate(value)
        setLastFocusedInput("customFromDate")
      } else if (type === "to") {
        if (
          (value.length > customToDate.length &&
            value[value.length - 1] === "/") ||
          value.length > 10
        ) {
          return
        }
        value = value.replace(/[^0-9/]/g, "")
        ;(value.length === 2 || value.length === 5) &&
        value.length > customToDate.length
          ? setCustomToDate(`${value}/`)
          : setCustomToDate(value)
        setLastFocusedInput("customToDate")
      }
    },
    [customFromDate, customToDate],
  )

  useEffect(() => {
    if (customFromDate || customToDate) {
      setSelectedDuration({ from: customFromDate, to: customToDate })
    }
  }, [customFromDate, customToDate])

  useEffect(() => {
    if (lastFocusedInput && inputRefs[lastFocusedInput]?.current) {
      inputRefs[lastFocusedInput].current.focus()
    }
  }, [selectedDuration, lastFocusedInput])

  return (
    <div>
      <div className='v3-rewards-transaction-filter-bottom-sheet-header'>
        <div>
          {t("payLaterWebComponents.transactionFilters.filterTransactions")}
        </div>
        <div
          onClick={() => {
            setSelectedDuration(selectedCycle)
            handleCloseButtonClick()
          }}
        >
          <CloseButton
            color={tertiaryNeutralColor1}
            background={tertiaryNeutralColor3}
          />
        </div>
      </div>
      <div className='v3-rewards-transaction-filter-tabs'>
        <div
          style={getTabStyles(FilterBottomSheetTabs.CYCLE)}
          onClick={() => setSelectedTab(FilterBottomSheetTabs.CYCLE)}
        >
          <div className='v3-rewards-transaction-filter-tabs-icon'>
            <Rotate
              color={
                selectedTab === FilterBottomSheetTabs.CYCLE
                  ? secondaryColor1
                  : tertiaryNeutralColor2
              }
            />
          </div>
          <div className='v3-rewards-transaction-filter-tabs-text'>
            {t("payLaterWebComponents.transactionFilters.byCycle")}
          </div>
        </div>
        <div
          style={getTabStyles(FilterBottomSheetTabs.DURATION)}
          onClick={() => setSelectedTab(FilterBottomSheetTabs.DURATION)}
        >
          <div className='v3-rewards-transaction-filter-tabs-icon'>
            <Calender
              color={
                selectedTab === FilterBottomSheetTabs.DURATION
                  ? secondaryColor1
                  : tertiaryNeutralColor2
              }
              customClassname='v3-rewards-transaction-filter-tabs-icon-image'
            />
          </div>
          <div className='v3-rewards-transaction-filter-tabs-text'>
            {t("payLaterWebComponents.transactionFilters.byDuration")}
          </div>
        </div>
      </div>
      {selectedTab === FilterBottomSheetTabs.CYCLE && (
        <div className='v3-rewards-transaction-filter-billing-cycles'>
          {cycles.map((cycle, index) => {
            const isSelected =
              cycle.from === selectedDuration.from &&
              cycle.to === selectedDuration.to
            return (
              <div
                style={
                  isSelected
                    ? {
                        background: "var(--secondary-color-5)",
                        color: "var(--secondary-color-1)",
                      }
                    : { color: "var(--tertiary-neutral-color-1)" }
                }
                onClick={() =>
                  setSelectedDuration({ from: cycle.from, to: cycle.to })
                }
              >
                <div>
                  {moment(cycle.from).format("DD MMM 'YY")} -{" "}
                  {moment(cycle.to).format("DD MMM 'YY")}
                </div>
                {isSelected ? <Check color={secondaryColor2} /> : <></>}
              </div>
            )
          })}
        </div>
      )}
      {selectedTab === FilterBottomSheetTabs.DURATION && (
        <div className='v3-rewards-transaction-filter-billing-cycles'>
          {duration.map((element, index) => {
            const isSelected =
              element?.duration?.from === selectedDuration?.from &&
              element?.duration?.to === selectedDuration?.to
            return (
              <>
                <div
                  style={
                    isSelected
                      ? {
                          background: "var(--secondary-color-5)",
                          color: "var(--secondary-color-1)",
                        }
                      : { color: "var(--tertiary-neutral-color-1)" }
                  }
                  onClick={() =>
                    setSelectedDuration({
                      from: element.duration.from,
                      to: element.duration.to,
                    })
                  }
                >
                  <div>{element.text}</div>
                  {isSelected ? <Check color={secondaryColor1} /> : <></>}
                </div>
                {element?.text === "Custom Range" &&
                  selectedDuration?.from === element?.duration?.from &&
                  selectedDuration?.to === element?.duration?.to && (
                    <div className='v3-rewards-transaction-filter-custom-range'>
                      <div>
                        <div className='v3-rewards-transaction-filter-custom-range-label'>
                          <Calender color={primaryColor1} />
                          <div>
                            {t(
                              "payLaterWebComponents.transactionFilters.fromDate",
                            )}
                          </div>
                        </div>
                        <input
                          className='v3-rewards-transaction-filter-custom-range-input'
                          placeholder={t(
                            "payLaterWebComponents.transactionFilters.dateFormatText",
                          )}
                          value={customFromDate}
                          onChange={e =>
                            handleCustomDateChange(e.target.value, "from")
                          }
                          ref={inputRefs?.customFromDate}
                        />
                      </div>
                      <div>
                        <div className='v3-rewards-transaction-filter-custom-range-label'>
                          <Calender color={primaryColor1} />
                          <div>
                            {t(
                              "payLaterWebComponents.transactionFilters.toDate",
                            )}
                          </div>
                        </div>
                        <input
                          className='v3-rewards-transaction-filter-custom-range-input'
                          placeholder={t(
                            "payLaterWebComponents.transactionFilters.dateFormatText",
                          )}
                          value={customToDate}
                          onChange={e =>
                            handleCustomDateChange(e.target.value, "to")
                          }
                          ref={inputRefs?.customToDate}
                        />
                      </div>
                    </div>
                  )}
              </>
            )
          })}
        </div>
      )}
      <div className='v3-rewards-transaction-filter-cta-container'>
        <ColorButton
          text={t("payLaterWebComponents.transactionFilters.applyFilters")}
          width='100%'
          primaryColor={secondaryColor1}
          onPress={() => applyFilters()}
        />
      </div>
    </div>
  )
}

export default FiltersBottomSheet
