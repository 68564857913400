import React from "react"
import RewardsCashbackZeroState from "../../../assets/images/v3/Rewards&CashbackZeroState.svg"
import { useTranslation } from "react-i18next"

const RewardsZeroStateTransaction = ({ isRewards }) => {
  const { t } = useTranslation()
  return (
    <div className='v3-Rewards-zero-state-transaction'>
      <img
        src={RewardsCashbackZeroState}
        alt='empty state rewards & cahsback'
        className='v3-Rewards-zero-state-transaction-image'
      />
      <div className='v3-Rewards-zero-state-transaction-text'>
        <div>
          {isRewards
            ? t("cashBack.noRewardPointHistory")
            : t("cashBack.noCashbackHistory")}
        </div>
        <div>
          {isRewards
            ? t("cashBack.rewardShowingText")
            : t("cashBack.cashBackTrxnShowingText")}
        </div>
      </div>
    </div>
  )
}

export default RewardsZeroStateTransaction
