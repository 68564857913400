import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import "react-spring-bottom-sheet/dist/style.css"
import { captureEvents } from "../../../../../utils/functions"
import { Header } from "../../../../core"
import { ColorButton } from "../../../../core/buttons"
import {
  CustomerCareContactTypes,
  EventName,
  FaqTypes,
} from "../../../../../utils/enums"
import EmaiQueryBottomSheet from "./EmaiQueryBottomSheet"
import CustomerSupportBottomSheet from "./CustomerSupportBottomSheet"
import FaqsAccordian_v3 from "./FaqsAccordian_v3"
import CustomerSupportFaqBottomSheet from "./CustomerSupportFaqBottomSheet"
import InfoCard from "../../../../core/InfoCard/v3/InfoCard"
import Divider from "../../../../core/Divider/v3/Divider"
import CS_blockCard from "../../../../svg/CS_blockCard"
import CS_privacy from "../../../../svg/v3/CS_privacy"
import CS_bell from "../../../../svg/CS_bell"
import CS_creditCard from "../../../../svg/CS_creditCard"
import FAQ_Img1 from "../../../../svg/FAQ_Img1"
import FAQ_Img2 from "../../../../svg/FAQ_Img2"
import FAQ_Img3 from "../../../../svg/FAQ_Img3"
import { useTranslation } from "react-i18next"

const CustomerCare_v3 = () => {
  const [theme, screen] = useSelector(state => [
    state.theme,
    state.screen,
    state.config,
  ])
  const [cardFaqs, setCardFaqs] = useState([])
  const [listFaqs, setListFaqs] = useState([])
  const [selectedFaq, setSelectedFaq] = useState()
  const [
    isCustomerSupportBottomSheetOpen,
    setIsCustomerSupportBottomSheetOpen,
  ] = useState(false)
  const [isEmailQueryBottomSheetOpen, setIsEmailQueryBottomSheetOpen] =
    useState(false)
  const [
    isCustomerSupportFaqBottomSheetOpen,
    setIsCustomerSupportFaqBottomSheetOpen,
  ] = useState(false)

  const { t } = useTranslation()

  const {
    primary: { color1: primaryColor1 },
    primaryBase: { color5: primaryBaseColor5 },
    tertiaryNeutral: { color1: tertiaryNeutralColor1 },
    tertiaryPositive: { color1: tertiaryPositiveColor1 },
    tertiaryNegative: { color3: tertiaryNegativeColor3 },
    tertiaryCaution: { color3: tertiaryCautionColor3 },
    tertiaryAltFeedback: { color3: tertiaryAltFeedbackColor3 },
  } = theme.v3.rawColors

  const customerSupportContainerRef = useRef(null)

  const faqs = screen?.aboutProgram?.faq

  const cardDefaultImage = [
    <CS_blockCard color={primaryColor1} />,
    <CS_privacy color={tertiaryPositiveColor1} />,
    <CS_bell />,
    <CS_creditCard />,
  ]

  const cardBackground = [
    tertiaryNegativeColor3,
    "#F1FFF0",
    tertiaryCautionColor3,
    tertiaryAltFeedbackColor3,
  ]

  const FAQsDefaultImageColor = primaryColor1
  const FAQsDefaultImage = [
    <FAQ_Img1 color={FAQsDefaultImageColor} />,
    <FAQ_Img2 color={FAQsDefaultImageColor} />,
    <FAQ_Img3 color={FAQsDefaultImageColor} />,
  ]

  useEffect(() => {
    captureEvents({ eventName: EventName.CUSTOMER_CARE, metadata: {} })
    const element = customerSupportContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  useEffect(() => {
    setCardFaqs(faqs.filter(faq => faq.type === FaqTypes.CARD))
    setListFaqs(faqs.filter(faq => faq.type === FaqTypes.LIST))
  }, [faqs])

  const handleAssistanceButtonClick = () => {
    setIsCustomerSupportBottomSheetOpen(true)
  }

  const onInfoCardClick = element => {
    setIsCustomerSupportFaqBottomSheetOpen(true)
    setSelectedFaq(element)
  }

  const handleContactsClick = ({ contact }) => {
    if (contact === CustomerCareContactTypes.EMAIL) {
      if (screen?.customerCare?.isEnabled) {
        setIsCustomerSupportBottomSheetOpen(false)
        setIsEmailQueryBottomSheetOpen(true)
      } else {
        const recipient = screen?.customerCare?.email
        window.location.href = `mailto:${recipient}?subject=${encodeURIComponent("Assistance Needed with my Credit Card")}`
      }
    } else {
      window.location.href = `tel:${screen?.customerCare?.moblieNo}`
    }
  }

  return (
    <div
      style={{
        minHeight: window.innerHeight,
      }}
      ref={customerSupportContainerRef}
      className='customerSupport-container'
    >
      <Header
        text={t("customerSupport.supportHeader")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='v3-customerSupport'>
        {screen?.aboutProgram?.faqCard?.isEnabled && (
          <>
            <div
              className='v3-customerSupport-faqsTitle'
              style={{ color: tertiaryNeutralColor1 }}
            >
              {screen?.aboutProgram?.faqCard?.header ||
                t("customerSupport.faqTitle")}
            </div>
            {cardFaqs.length > 0 && (
              <div className='v3-customerSupport-faqs'>
                {cardFaqs?.map((element, index) => {
                  return (
                    <InfoCard
                      details={element}
                      faq={element}
                      onCardClick={onInfoCardClick}
                      key={index}
                      defaultImage={
                        cardDefaultImage[index % cardDefaultImage.length]
                      }
                      cardBackgroundColor={
                        cardBackground[index % cardBackground.length]
                      }
                    />
                  )
                })}
              </div>
            )}
          </>
        )}

        {screen?.aboutProgram?.faqCard?.isEnabled &&
          screen?.aboutProgram?.faqList?.isEnabled && (
            <Divider showCenterMarker />
          )}

        {screen?.aboutProgram?.faqList?.isEnabled && (
          <div
            className='v3-customerSupport-otherFaqs'
            style={{ color: tertiaryNeutralColor1 }}
          >
            <div className='v3-customerSupport-otherFaqsTitle'>
              {screen?.aboutProgram?.faqList?.header ||
                t("customerSupport.otherFAQs")}
            </div>
            <div className='v3-customerSupport-otherFaqs-accordian-container'>
              {listFaqs.length > 0 &&
                listFaqs?.map((item, index) => (
                  <div key={index}>
                    <FaqsAccordian_v3
                      item={item}
                      index={index}
                      FAQsDefaultImage={
                        FAQsDefaultImage[index % FAQsDefaultImage.length]
                      }
                    />

                    <Divider
                      showCenterSquare={
                        index == screen.aboutProgram.faq.length - 1
                      }
                      color={primaryBaseColor5}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      <div className='v3-customerSupport-CTA'>
        <ColorButton
          text={t("customerSupport.assistanceButton")}
          width={"100%"}
          onPress={handleAssistanceButtonClick}
        />
      </div>

      <CustomerSupportBottomSheet
        isOpen={isCustomerSupportBottomSheetOpen}
        onClose={() => setIsCustomerSupportBottomSheetOpen(false)}
        handleContactsClick={handleContactsClick}
      />

      <EmaiQueryBottomSheet
        isOpen={isEmailQueryBottomSheetOpen}
        onClose={() => setIsEmailQueryBottomSheetOpen(false)}
        setIsCustomerSupportBottomSheetOpen={
          setIsCustomerSupportBottomSheetOpen
        }
      />

      <CustomerSupportFaqBottomSheet
        isOpen={isCustomerSupportFaqBottomSheetOpen}
        onClose={() => setIsCustomerSupportFaqBottomSheetOpen(false)}
        selectedFaq={selectedFaq}
      />
    </div>
  )
}

export default CustomerCare_v3
