import React, { useState } from "react"
import ArrowUp from "../../../svg/arrowUp"
import ArrowDown from "../../../svg/arrowDown"
import { useSelector } from "react-redux"
import Dompurify from "dompurify"

const OfferDetailsAccordian = ({ header, points, open = false }) => {
  const theme = useSelector(state => state.theme)
  const [isOpen, setIsOpen] = useState(open)

  return (
    <div className='v3-offerDetails-accordian'>
      <div
        className='v3-offerDetails-accordian-header'
        style={{ color: theme?.v3.cssVars.tertiaryNeutral.color1 }}
      >
        {header}{" "}
        <div onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? (
            <ArrowDown
              size='12px'
              color={theme.v3.rawColors.tertiaryNeutral.color1}
            />
          ) : (
            <ArrowUp
              color={theme.v3.rawColors.tertiaryNeutral.color1}
              size='12px'
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className='v3-offerDetails-accordian-points'
          style={{ color: theme.v3.rawColors.tertiaryNeutral.color2 }}
        >
          {Array.isArray(points) &&
            points?.map((point, index) => {
              return (
                <div
                  key={index}
                  className='v3-offerDetails-accordion-points-container'
                >
                  <div
                    className='v3-offerDetails-accordian-bullet'
                    style={{ background: theme.v3.rawColors.primary.color1 }}
                  ></div>
                  <div className='v3-offerDetails-accordian-point'>{point}</div>
                </div>
              )
            })}
          {typeof points === "string" && (
            <div
              dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(points) }}
              className='v3-offerDetails-accordian-dangerously-rendered-points'
            />
          )}
        </div>
      )}
    </div>
  )
}

export default OfferDetailsAccordian
