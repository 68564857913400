import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { BottomSheet } from "react-spring-bottom-sheet"
import Bank from "../../../../svg/v3/bank"
import { ColorButton } from "../../../../core/buttons"

const EMIConversionInProgressBottomSheet = forwardRef(
  ({ isOpen, onClose }, ref) => {
    const { t } = useTranslation()

    const theme = useSelector(state => state.theme)
    return (
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <div className='v3-transactionsSection-conversionProgress-bottomSheet'>
          <div>
            <Bank />
          </div>
          <div>{t("TransactionSection.conversionProgressBottomSheetText")}</div>
          <ColorButton
            text={t("EmiElement.okBtnText")}
            width='100%'
            onPress={() => onClose()}
          />
        </div>
      </BottomSheet>
    )
  },
)

export default EMIConversionInProgressBottomSheet
