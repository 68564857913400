import React from "react"

const UserIdSvg = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.50781 8.375C9.16467 8.375 10.5078 7.03185 10.5078 5.375C10.5078 3.71815 9.16467 2.375 7.50781 2.375C5.85096 2.375 4.50781 3.71815 4.50781 5.375C4.50781 7.03185 5.85096 8.375 7.50781 8.375Z'
        fill='#25314C'
      />
      <path
        opacity='0.4'
        d='M12.03 11.0375C11.3775 10.34 10.395 9.875 9 9.875H6C2.955 9.875 1.875 12.1025 1.875 14.015C1.875 15.725 2.7825 16.625 4.5 16.625H10.1925C10.3125 16.625 10.389 16.535 10.425 16.34L10.575 15.4775C10.59 15.4025 10.5675 15.3275 10.5075 15.275L9.82498 14.615C9.41998 14.2325 9.26998 13.6625 9.44247 13.13C9.61497 12.59 10.0725 12.2075 10.6275 12.1325L11.565 11.9975C11.565 11.9975 11.715 11.9832 11.76 11.8925L12.06 11.2925C12.06 11.2925 12.0975 11.2325 12.09 11.15C12.0825 11.12 12.0675 11.075 12.03 11.0375Z'
        fill='#25314C'
      />
      <path
        d='M13.8213 11.5701L14.4686 12.8541C14.5173 12.9509 14.6111 13.0176 14.7198 13.0334L16.2139 13.2456C16.4876 13.2846 16.5971 13.6154 16.3983 13.8044L15.3184 14.8349C15.2396 14.9099 15.2036 15.0186 15.2223 15.1251L15.4691 16.5358C15.5193 16.8216 15.2134 17.0399 14.9516 16.9056L13.6556 16.2388C13.5581 16.1886 13.4426 16.1886 13.3451 16.2388L12.0499 16.9056C11.7874 17.0406 11.4806 16.8216 11.5308 16.5351L11.7776 15.1258C11.7963 15.0193 11.7604 14.9106 11.6816 14.8356L10.6016 13.8051C10.4028 13.6161 10.5123 13.2853 10.7861 13.2463L12.2801 13.0341C12.3889 13.0184 12.4826 12.9516 12.5313 12.8549L13.1786 11.5708C13.3114 11.3098 13.6901 11.3099 13.8213 11.5701Z'
        fill='#25314C'
      />
    </svg>
  )
}

export default UserIdSvg
