import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { CustomerCareBottomSheetTypes } from "../../../../../utils/enums"
import CaretRight from "../../../../svg/v3/CaretRight"
import CrossButton from "../../../../svg/v3/CrossButton"
import { ColorButton } from "../../../../core/buttons"
import { BottomSheet } from "react-spring-bottom-sheet"
import { useTranslation } from "react-i18next"

const EmaiQueryBottomSheet = ({
  isOpen,
  onClose,
  setIsCustomerSupportBottomSheetOpen,
}) => {
  const [theme, screen] = useSelector(state => [state.theme, state.screen])
  const [text, setText] = useState("")
  const textInputRef = useRef(null)

  const {
    tertiaryNeutral: {
      color1: tertiaryNeutralColor1,
      color3: tertiaryNeutralColor3,
    },
  } = theme.v3.rawColors

  const { t } = useTranslation()

  const handleOnTextInput = e => {
    setText(e.target.value)
  }

  const handleBackButtonClick = () => {
    onClose()
    setIsCustomerSupportBottomSheetOpen(true)
    setText("")
  }

  const handleEmailCloseClick = () => {
    onClose()
    setText("")
  }

  const handleSendEmail = () => {
    const recipient = screen?.customerCare?.email
    const body = encodeURIComponent(text)
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent("Assistance Needed with my Credit Card")}&body=${body}`
    window.location.href = mailtoLink
  }

  useEffect(() => {
    if (textInputRef?.current && text.length) {
      const textarea = textInputRef.current
      textarea.style.height = "auto"
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }, [text, textInputRef])

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div className='v3-customer-support-email-query'>
        <div className='v3-customer-support-email-query-header'>
          <div className='v3-customer-support-email-query-title'>
            <div
              className='v3-customer-support-back-button'
              onClick={() => handleBackButtonClick()}
            >
              <CaretRight color={tertiaryNeutralColor1} />
            </div>
            <div className='v3-customer-support-email-query-text'>
              <div>{t("customerSupport.writeUsAboutIt")}</div>
              <div>{t("customerSupport.mailQueryText")}</div>
            </div>
          </div>
          <div onClick={() => handleEmailCloseClick()}>
            <CrossButton
              backgroundColor={tertiaryNeutralColor3}
              color={tertiaryNeutralColor1}
            />
          </div>
        </div>
        <div className='v3-customerSupport-textInput-container'>
          <textarea
            className='v3-customerSupport-textInput'
            ref={textInputRef}
            placeholder={t("customerSupport.typeHere")}
            value={text}
            onChange={handleOnTextInput}
          />
        </div>
        <div className='v3-customerSupport-button-container'>
          <ColorButton
            text={t("customerSupport.sendMailButton")}
            isDisable={!text.length}
            width={"100%"}
            onPress={handleSendEmail}
          />
        </div>
      </div>
    </BottomSheet>
  )
}

export default EmaiQueryBottomSheet
