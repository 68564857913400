import { Header } from "../../core"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  capitalizeFirsLetter,
  captureEvents,
  formatAmount,
} from "../../../utils/functions"
import upi from "../../../assets/images/upi.svg"
import netBanking from "../../../assets/images/netBanking.svg"
import ArrowRight from "../../svg/arrowRight"
import { useNavigate } from "react-router-dom"
import { EventName, HomeTabs, PwaVersions } from "../../../utils/enums"
import paymentOptionsPatternLeft from "../../../assets/images/v3/paymentOptionsPatternLeft.svg"
import paymentOptionPatternRight from "../../../assets/images/v3/paymentOptionsPatternRight.svg"
import bankImage from "../../../assets/images/v3/bank.svg"
import upiImage from "../../../assets/images/v3/upi.svg"
import debitImage from "../../../assets/images/v3/debitPayment.svg"
import Add from "../../svg/v3/add"
import { paymentTypes } from "../../../utils/constants"
import moment from "moment"
import PaymentUpiVerifyV3 from "./v3/paymentUpiVerifyV3"
import leftPatternBg from "../../../assets/images/v3/leftPatternBg.svg"
import rightPatternBg from "../../../assets/images/v3/rightPatternBg.svg"
import { Divider } from "../../../nativeBaseReplacements"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const options = [
  {
    img: upi,
    text1: "UPI ID",
    text2: "A Payment request will be sent to your UPI app.",
    text3: "UPI",
  },
  // {img: qrScan,
  //     text1:"QR Code",
  //     text2:"Scan the QR using any UPI app on your mobile phone.",
  // },
  {
    img: netBanking,
    text1: "Net Banking",
    text2: "Login to your internet banking portal and make the payment.",
    text3: "NetBanking",
  },
]

const optionsFromDashboard = {
  UPI: { img: upiImage, text1: "UPI", text2: "UPI" },
  NET_BANKING: { img: netBanking, text1: "Net Banking", text2: "NetBanking" },
}

const orderedPaymentOptions = Object.keys(optionsFromDashboard)

const PaymentOptions = () => {
  const { t } = useTranslation()
  const windowDim = useWindowDimensions()
  const [isUpiVerifyBottomSheetOpen, setIsUpiVerifyBottomSheetOpen] =
    useState(false)
  const [theme, paymentGateway, session, config, user, screen] = useSelector(
    state => [
      state.theme,
      state.paymentGateway,
      state.session,
      state.config,
      state.user,
      state.screen,
    ],
  )
  const paymentOptions = screen.home?.repayments?.allowedPaymentMode
  const redirectionUrl =
    paymentGateway?.pgconfig[0]?.flows[1]?.metadata?.redirectUrl
  const navigate = useNavigate()
  useEffect(() => {
    captureEvents({
      eventName: EventName.PAY_BILL_MODE_SELECTION_PAGE,
      metadata: {},
    })
  }, [])

  const handleAddButtonClick = option => {
    captureEvents({
      eventName: EventName.PAY_BILL_MODE_SELECTION_CTA,
      metadata: { paymentMethod: option?.text2 },
    })

    if (option?.text1 === "UPI") {
      setIsUpiVerifyBottomSheetOpen(true)
    } else if (option?.text1 === "Net Banking") {
      window.open(redirectionUrl)
    }
  }

  const closeUpiVerifyBottomSheet = () => {
    setIsUpiVerifyBottomSheetOpen(false)
  }

  const versionedPaymentOptions = () => {
    if (config?.version === PwaVersions.V3) {
      return (
        <>
          <Header
            text={t("PaymentGateway.selectPaymentOptions")}
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          <div className='v3-paymentGateway-options'>
            <div>
              {t("PaymentGateway.paying")}{" "}
              {capitalizeFirsLetter(
                paymentTypes[paymentGateway?.paymentType] || "",
              )}
            </div>
            <div>{formatAmount(paymentGateway?.amountPayable)}</div>
            <div>
              {t("PaymentGateway.stmtFor")}{" "}
              <span>
                {moment(user?.summary?.lastStatement?.fromDate).format(
                  "MMM'YY",
                )}
              </span>
            </div>
            <img
              src={leftPatternBg}
              className='v3-paymentGateway-options-bgPatternLeft'
              alt='bgPatternLeft'
            />
            <img
              src={rightPatternBg}
              className='v3-paymentGateway-options-bgPatternRight'
              alt='bgPatternLeft'
            />
          </div>
          <div className='v3-paymentGateway-options-chooseOptions'>
            <div>{t("PaymentGateway.paymentTypeSelect")}</div>
            <div>
              {orderedPaymentOptions.map((option, index) => {
                if (paymentOptions?.includes(option)) {
                  const paymentMethod = optionsFromDashboard[option]
                  return (
                    paymentMethod && (
                      <>
                        <div className='v3-paymentGateway-options-chooseOptions-option'>
                          <div>
                            <img
                              src={paymentMethod?.img}
                              alt='upi'
                              className='v3-paymentGateway-options-chooseOptions-option-image'
                            />
                            <div className='v3-paymentGateway-options-chooseOptions-option-text'>
                              {paymentMethod?.text1}
                            </div>
                          </div>
                          <div
                            onClick={() => handleAddButtonClick(paymentMethod)}
                          >
                            <Add color={theme.v3.cssVars.primaryBase.color1} />
                          </div>
                        </div>
                        {index + 1 !==
                          screen.home.repayments.allowedPaymentMode.length && (
                          <Divider />
                        )}
                      </>
                    )
                  )
                }
              })}
            </div>
          </div>
          <PaymentUpiVerifyV3
            isOpen={isUpiVerifyBottomSheetOpen}
            onClose={closeUpiVerifyBottomSheet}
          />
        </>
      )
    } else {
      return (
        <>
          {" "}
          <Header
            text={t("PaymentGateway.billPayment")}
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          <div className='mainContainer' style={{ color: theme.appTextColor }}>
            <div
              className='optionsWidget'
              style={{ backgroundColor: theme.widgetBackgroundColor }}
            >
              <div className='optionsContainer'>
                <div className='optionsHeading'>
                  {t("PaymentGateway.amountPayable")}
                </div>
                <div className='optionsAmount'>
                  {formatAmount(paymentGateway.amountPayable)}
                </div>
              </div>
              <div className='borderLine'></div>
              <div className='optionsContainer'>
                <div className='optionsHeading2'>
                  {t("PaymentGateway.payUsing")}
                </div>
                {options.map((item, index) => (
                  <>
                    <div
                      onClick={() => {
                        captureEvents({
                          eventName: EventName.PAY_BILL_MODE_SELECTION_CTA,
                          metadata: { paymentMethod: item?.text3 },
                        })
                        item.text1 == "UPI ID"
                          ? navigate("/PaymentGateway/options/verifyUpi")
                          : item.text1 == "Net Banking"
                            ? window.open(redirectionUrl)
                            : console.log()
                      }}
                      className='optionsContent optionsContentpg'
                    >
                      <img src={item.img} width={"38px"} height={"38px"} />
                      <div style={{ width: "70%", marginLeft: "-5px" }}>
                        <div className='optionsContentHeading'>
                          {item.text1}
                        </div>
                        <div className='optionsContentText'>{item.text2}</div>
                      </div>
                      <ArrowRight size={"10px"} color={theme.appTextColor} />
                    </div>
                    {index != options?.length - 1 && (
                      <div className='borderLine2'></div>
                    )}
                  </>
                ))}
              </div>
              {/* <div className="subHeading">Confirm the amount you wish to pay</div> */}
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      {versionedPaymentOptions()}
    </div>
  )
}

export default PaymentOptions
