import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { useNavigate } from "react-router-dom"
import { getDifferenceInDays } from "../../../../utils/functions"
import Hourglass from "../../../svg/v3/hourGlassIcon"
import { useSelector } from "react-redux"
import defaultBanner from "../../../../assets/images/v3/BrandBanner.svg"
import defaultLogo from "../../../../assets/images/v3/Vector.svg"
import StyledCheckIcon from "../../../../assets/images/v3/checkIconStyled.svg"
import { offerType, offerVisibility } from "../../../../utils/constants"
import RenderOptInStatus from "../../../Home/Components/OffersSection/v3/Components/offerOptInStatus"
import { useTranslation } from "react-i18next"

const MerchantOffersCarousel = ({ maxNumberOfCards = 3 }) => {
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)
  const benefits = useSelector(state => state.benefits)
  const { t } = useTranslation()

  const allMerchantOffers = benefits?.merchantBenefits
    ?.filter(offer => {
      return !offer?.tags.includes("Hero Offer")
    })
    .sort((a, b) => a?.displayOrder - b?.displayOrder)

  const handleMerchantOfferClick = benefitId => {
    navigate(`/MerchantOffers/${benefitId}`)
  }
  return (
    <div style={{ paddingLeft: "5px" }}>
      <Swiper
        spaceBetween={12}
        style={{ padding: "0px 15px" }}
        breakpoints={{
          320: { slidesPerView: 1.9 },
          370: { slidesPerView: 2.0 },
          395: { slidesPerView: 2.1 },
          412: { slidesPerView: 2.2 },
          450: { slidesPerView: 2.3 },
        }}
      >
        {allMerchantOffers.slice(0, maxNumberOfCards).map((offer, index) => {
          const isOptInOffer = offer?.visibility === offerVisibility.OPT_IN
          const isOptedIn = offer?.isAccountOptedIn
          return (
            <SwiperSlide key={index} style={{ marginRight: "10px" }}>
              <div
                className='v3-AllCampaignOffers-merchantOffersContainer-offerCard'
                onClick={() => handleMerchantOfferClick(offer?.benefitId)}
              >
                <div className='v3-AllCampaignOffers-merchantOffersContainer-offerCard-illustration-container'>
                  {isOptInOffer && (
                    <RenderOptInStatus
                      isOptedIn={isOptedIn}
                      benefitType={offerType.MERCHANT_OFFERS}
                    />
                  )}
                  {offer?.illustrationUrl ? (
                    <img
                      src={offer?.illustrationUrl}
                      alt='illustration'
                      className='v3-AllCampaignOffers-merchantOffersContainer-offerCard-illustration'
                    />
                  ) : (
                    <img
                      src={defaultBanner}
                      className='v3-AllCampaignOffers-merchantOffersContainer-offerCard-illustration'
                    />
                  )}
                  <div className='v3-AllCampaignOffers-merchantOffersContainer-offerCard-logo'>
                    {offer?.logoUrl ? (
                      <img src={offer?.logoUrl} alt='logo' />
                    ) : (
                      <img src={defaultLogo} />
                    )}
                  </div>
                </div>
                <div className='v3-AllCampaignOffers-merchantOffersContainer-offerCard-description'>
                  {offer?.displayDescription || offer?.description}
                </div>
                {getDifferenceInDays(offer?.activeTo).toFixed(0) <= 7 && (
                  <div className='v3-AllCampaignOffers-merchantOffersContainer-offerCard-countdown'>
                    <Hourglass color={theme?.v3.cssVars.secondary.color1} />{" "}
                    {t("offers.expiresInText", {
                      time:
                        getDifferenceInDays(offer?.activeTo) < 1
                          ? t("offers.TodayText")
                          : t("offers.dayText", {
                              count: Number(
                                getDifferenceInDays(offer?.activeTo).toFixed(0),
                              ),
                            }),
                    })}
                  </div>
                )}
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default MerchantOffersCarousel
