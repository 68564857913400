import React from "react"
import ArrowRight from "../../../svg/arrowRight"
import customerTxnCatchAll from "../../../../assets/images/transactionLogos/customerTxnCatchAll.svg"
import { useSelector } from "react-redux"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import moment from "moment"
import { copyToClipboard, formatAmount } from "../../../../utils/functions"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  payLaterTransactionStatus,
  transactionStatus,
} from "../../../../utils/constants"
import {
  EventName,
  transactionNature,
  transactionTypes,
} from "../../../../utils/enums"
import purchaseIcon from "../../../../assets/images/v3/PaylaterPurchase.svg"
import repaymentIcon from "../../../../assets/images/v3/PaylaterRepayment.svg"
import refundIcon from "../../../../assets/images/v3/PaylaterRefund.svg"
import CopyToClipboard from "../../../svg/v3/copyToClipboard"

const transactionTypesMapping = {
  SETTLEMENT_CREDIT: "Repayment",
  AUTHORIZE: "Purchase",
  AUTHORIZATION_REVERSAL: "Cancelled",
}

const RecentTransactions = ({
  transactions,
  currentPage,
  dropDownSelected,
  setSelectedTransaction,
}) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const navigate = useNavigate()
  const hanldeSelectTransaction = txn => {
    setSelectedTransaction ? setSelectedTransaction(txn) : console.log()
  }
  const handleViewAllTransactions = () => {
    navigate("/transactions")
  }
  const getTransactionStatus = transaction => {
    if (
      transaction?.txnStatus === transactionStatus.APPROVED ||
      transaction?.txnStatus === transactionStatus.PARTIALLY_SETTLED
    )
      return payLaterTransactionStatus.DUE
    else if (transaction?.txnStatus === transactionStatus.SETTLED)
      return payLaterTransactionStatus.REPAID
  }

  const getTransactionIcon = transaction => {
    if (transaction?.txnType === transactionTypes.PURCHASE) return purchaseIcon
    else if (transaction?.txnType === transactionTypes.REFUND) return refundIcon
    else if (transaction?.txnType === transactionTypes.REPAYMENT)
      return repaymentIcon
  }

  const copyTransactionId = transaction => {
    copyToClipboard(transaction?.id || "")
  }

  return (
    <div className='v3-recent-transaction-table-container'>
      <div className='v3-recent-transaction-header-container'>
        {currentPage === EventName.HOME ? (
          <>
            <div className='v3-recent-transaction-header-text'>
              {t(
                "payLaterWebComponents.recentTransactions.recentTransactionsHeader",
              )}
            </div>
            <div
              className='v3-recent-transaction-header-viewall-container'
              onClick={handleViewAllTransactions}
            >
              <div className='v3-recent-transaction-viewall-text'>
                {t(
                  "payLaterWebComponents.recentTransactions.seeAllTransactions",
                )}
              </div>
              <div className='v3-recent-transaciton-viewall-cta-icon'>
                <ArrowRight
                  size={"13px"}
                  color={theme.v3.cssVars.primary.color1}
                />
              </div>
            </div>
          </>
        ) : (
          <div className='v3-recent-transaciton-all-transaction-header'>
            {t("payLaterWebComponents.recentTransactions.showingAllText")}{" "}
            <span className='v3-recent-transaction-total-count'>
              {transactions?.length}{" "}
              {t(
                "payLaterWebComponents.recentTransactions.transactionCountText",
              )}
            </span>{" "}
            {t("payLaterWebComponents.recentTransactions.fromText")}{" "}
            {moment(dropDownSelected?.from).format("DD MMM `YY")} -{" "}
            {moment(dropDownSelected?.to).format("DD MMM `YY")}
          </div>
        )}
      </div>
      <div className='v3-recent-transaction-table-section'>
        <table className='v3-recent-transaction-table-body'>
          <tr className='v3-recent-transaction-table-header'>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.recentTransactions.transactionType")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.recentTransactions.orderIdText")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.recentTransactions.dateText")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.recentTransactions.amountText")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.recentTransactions.statusText")}
            </th>
          </tr>

          {transactions?.map((transaction, index) => {
            let transactionAmount = formatAmount(transaction?.amount)
            if (transaction?.txnNature === transactionNature.CREDIT) {
              transactionAmount = "+ " + transactionAmount
            }
            return (
              <>
                <tr className='v3-recent-transactions-transaction-contianer'>
                  <td
                    className='v3-recent-transactions-transaction-logo-text'
                    onClick={() => hanldeSelectTransaction(transaction)}
                  >
                    <img
                      src={
                        getTransactionIcon(transaction) || customerTxnCatchAll
                      }
                      width='40px'
                      alt='transaction logo'
                    />
                    <span>
                      <div className='v3-recent-transactions-transaction-type'>
                        {transactionTypesMapping[transaction?.txnType]
                          ? transactionTypesMapping[transaction?.txnType]
                          : transaction?.txnType}
                      </div>
                    </span>
                  </td>
                  <td className='v3-recent-transactions-transaction-order-id'>
                    {transaction?.id}{" "}
                    <span onClick={() => copyTransactionId(transaction)}>
                      <CopyToClipboard width='16' height='16' />
                    </span>
                  </td>
                  <td className='v3-recent-transactions-transaction-date'>
                    {moment(transaction?.transactionDate).format("DD MMM")}
                  </td>
                  <td
                    className='v3-recent-transactions-transaction-amount'
                    style={{
                      color:
                        transaction?.txnNature === transactionNature.CREDIT &&
                        theme.v3.rawColors.tertiaryPositive.color1,
                    }}
                  >
                    {transactionAmount}
                  </td>
                  <td>
                    {getTransactionStatus(transaction) ===
                    payLaterTransactionStatus.DUE ? (
                      <div className='v3-recent-transactions-transaction-due-status'>
                        {t("payLaterWebComponents.recentTransactions.dueText")}
                      </div>
                    ) : getTransactionStatus(transaction) ===
                      payLaterTransactionStatus.REPAID ? (
                      <div className='v3-recent-transactions-transaction-repaid-status'>
                        {t(
                          "payLaterWebComponents.recentTransactions.repaidText",
                        )}
                      </div>
                    ) : null}
                  </td>
                </tr>
                {index !== transactions.length - 1 && (
                  <DividerV3
                    width={"363%"}
                    color={theme.v3.rawColors.primaryBase.color5}
                    marginBottom={"0"}
                  />
                )}
              </>
            )
          })}
        </table>
      </div>
    </div>
  )
}

export default RecentTransactions
