import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { ColorButton } from "../core/buttons"
import background from "../../assets/images/v3/welcomeCardPattern.svg"
import horizontalCard from "../../assets/images/v3/horizontalCard.svg"
import verticalCard from "../../assets/images/v3/VerticalCard.svg"
import { CardOrientation, ErrorType } from "../../utils/constants"
import settingBanner from "../../assets/images/v3/setting-banner.svg"
import CustomCarousel from "../../core/WebCarousel"
import LoginJourneyBottomSheet_v3 from "./LoginJourneyBottomSheet_v3"
import AccountService from "../../services/AccountService"
import giftsBanner from "../../assets/images/v3/gifts-banner.svg"
import securityBanner from "../../assets/images/v3/security-banner.svg"
import GlobalV3Theme from "../Theme"
import { setDesign } from "../../store/actions/Theme"
import { setCommonState } from "../../store/actions/Common"
import { setConfigState } from "../../store/actions/Config"
import { consoleError } from "../../utils/functions"
import { ErrorContext } from "./ErrorScreenContext"

const LoginJourney = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const session = useSelector(state => state.session)
  const isAppHeaderEnabled = screen?.pwaHeader?.isAppHeaderEnabled || false
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [configs, setConfigs] = useState(null)
  const [v3ThemeConfig, setV3ThemeConfig] = useState(null)
  const { setErrorState } = useContext(ErrorContext)

  const designId =
    session?.multicardDesignId || localStorage.getItem("design_id")
  const cardOrientation = theme?.cardOrientation || "HORIZONTAL"
  const dispatch = useDispatch()

  const offerBanners = [
    {
      image: settingBanner,
      text: "Manage your credit card like a pro with just a few clicks!",
    },
    {
      image: giftsBanner,
      text: "Discover curated offers that perfectly match your spending style",
    },
    {
      image: securityBanner,
      text: "Enjoy peace of mind with top-notch security and convenience",
    },
  ]

  const fetchDesignConfig = async id => {
    try {
      const response = await AccountService.fetchDesignConfig(id)
      if (response?.status !== 200) {
        consoleError(response?.errors)
        setErrorState(response?.status, () => fetchDesignConfig(id))
      }
      setConfigs(response.data)
      const themeConfig = response.data?.configs?.uiConfigs || null
      const authSteps = response.data?.configs?.authSteps
      dispatch(
        setConfigState({
          version: "v3",
          authSteps,
        }),
      )
      setV3ThemeConfig(themeConfig)
      dispatch(setDesign(themeConfig))
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => fetchDesignConfig(id))
      } else {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    localStorage.setItem("version", "v3")
    if (designId) {
      fetchDesignConfig(designId)
    }
    localStorage.setItem("standAloneJourney", false)
    dispatch(setCommonState({ standAloneLoginScreen: true }))
  }, [designId])

  const handleLoginJourney = () => {
    setIsBottomSheetOpen(true)
  }

  const getAppWindowHeight = isAppHeaderEnabled => {
    return isAppHeaderEnabled
      ? ` ${window?.innerHeight - 52}px `
      : `${window?.innerHeight}px`
  }

  return (
    <>
      <div
        style={{
          minHeight: getAppWindowHeight(isAppHeaderEnabled),
          backgroundColor: theme.v3.cssVars.leadingWhite,
          position: "relative",
          overflow: "hidden",
          maxWidth: "450px",
          margin: "auto",
        }}
      >
        {isBottomSheetOpen && (
          <LoginJourneyBottomSheet_v3
            isOpen={true}
            onClose={() => setIsBottomSheetOpen(false)}
            configs={configs}
          />
        )}
        <div
          className={`v3-mpinIntro-wrapper`}
          style={{ minHeight: getAppWindowHeight(isAppHeaderEnabled) }}
        >
          <div className='v3-mpinIntro-top'>
            <div className='v3-mpinIntro-header-desc'>
              {t("HomePage.demoBank")} <br />
              {t("HomePage.cardSpaceText")}
            </div>
          </div>
          <div className='v3-mpinIntro-hero-image'>
            <div className='v3-login-journey-hero-background'>
              <img src={background} />
              <div
                className={
                  cardOrientation === CardOrientation.HORIZONTAL
                    ? `${
                        screen?.preLoader?.heroImageUrl
                          ? "v3-login-journey-hero-card-horizontal"
                          : "v3-login-journey-hero-card-horizontal-stock"
                      }`
                    : `${
                        screen?.preLoader?.heroImageUrl
                          ? "v3-mpinIntro-hero-card-vertical"
                          : "v3-mpinIntro-hero-card-vertical-stock"
                      }`
                }
              >
                <img
                  src={
                    screen?.preLoader?.heroImageUrl ??
                    (theme?.cardDesignUrl
                      ? theme?.cardDesignUrl
                      : cardOrientation === CardOrientation.HORIZONTAL
                        ? horizontalCard
                        : verticalCard)
                  }
                  style={{ width: "100%" }}
                  alt='card'
                />
              </div>
            </div>
          </div>

          <CustomCarousel offers={offerBanners} />

          <div
            className='v3-mpinIntro-cta-container'
            style={{ padding: "2% 0" }}
          >
            <div style={{ width: "100%" }}>
              <ColorButton
                mx='auto'
                text='Login'
                onPress={handleLoginJourney}
              />
            </div>
          </div>
        </div>
      </div>
      <GlobalV3Theme themeConfig={v3ThemeConfig} />
    </>
  )
}

export default LoginJourney
