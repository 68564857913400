import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import cardBlocked from "../../../../../components/svg/v3/blockCardIcon.svg"
import effectImg1 from "../../../../../assets/images/effect2-blockCard.svg"
import effectImg2 from "../../../../../assets/images/effect-blockCard.svg"
import unionTL from "../../../../../assets/images/v3/UnionTL.svg"
import unionBR from "../../../../../assets/images/v3/UnionRB.svg"
import freezeBg from "../../../../../assets/images/v3/FreezeCardEllipse.svg"
import frozenCard from "../../../../../assets/images/v3/FrozenCardImage.svg"

import moment from "moment"
import { Header } from "../../../../core"
import { ColorButton, WhiteButton } from "../../../../core/buttons"

import { CardOrientation, DisplayConfig } from "../../../../../utils/constants"
import { EventName, PwaVersions } from "../../../../../utils/enums"
import { captureEvents } from "../../../../../utils/functions"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"
import DividerV3 from "../../../../core/Divider/v3/DividerV3"

const CardBlocked_v3 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const location = useLocation()

  const cardBlockedContainerRef = useRef(null)

  const date = moment().format("DD-MM-YYYY, hh:mm A")

  useEffect(() => {
    captureEvents({ eventName: EventName.CARD_BLOCKED, metadata: {} })
    const element = cardBlockedContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  useEffect(() => {
    // this screen comes after block card reason screen

    // if account is inactive or card was not issued, user wouldn't have reached the block card reason screen
    // if card is not hotlisted then user didn't successfully submit the block card reason

    console.log("CARD blocked", user.cards[0])
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      !(user.cards[0].isHotlisted || user.cards[0].isLocked)
    ) {
      navigate("/")
    }

    // reaching this point means card hotlisted, but this screen should only come after the block card reason screen.
    if (
      location.state?.from !== "blockCard" &&
      location.state?.from !== "frozenCard"
    ) {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards, location.state?.from])

  const requestNewCard = () => {
    captureEvents({
      eventName: EventName.BLOCK_CARD_SUCCESS_CTA,
      metadata: { cta: "request new card" },
    })
    navigate("/ManageCard/NewCardRequest")
  }
  const returnHome = () => {
    captureEvents({
      eventName: EventName.BLOCK_CARD_SUCCESS_CTA,
      metadata: { cta: "return home" },
    })
    navigate("/")
  }

  return (
    <div
      style={{
        minHeight: windowDim.height,
        backgroundColor: theme.v3.cssVars.leadingWhite,
      }}
      ref={cardBlockedContainerRef}
      className='cardBlocked-container'
    >
      <div>
        <div
          style={{
            minHeight: windowDim.height,
            backgroundColor: theme.v3.cssVars.leadingWhite,
          }}
        >
          {/* body content start */}
          <div className='v3-cardBlocked-header'>
            <Header
              onBack={async () => {
                window.history.go(-1)
              }}
            />
          </div>
          {/* body title end */}
          {location.state?.from === "blockCard" ? (
            <div className='v3-card-blocked-img-effect-container'>
              <img className='v3-card-blocked-img-effect1' src={effectImg1} />
              <img className='v3-card-blocked-img-effect2' src={effectImg2} />
            </div>
          ) : (
            <div className='v3-card-blocked-img-effect-container'>
              <img className='v3-card-blocked-img-effect3' src={unionTL} />
              <img className='v3-card-blocked-img-effect4' src={unionBR} />
            </div>
          )}

          <div
            className='v3-card-blocked-success-img-container'
            style={{
              backgroundColor:
                location.state?.from === "blockCard"
                  ? theme.v3.cssVars.tertiaryNegative.color3
                  : theme.v3.cssVars.primary.color5,
            }}
          >
            {theme.cardOrientation === CardOrientation.HORIZONTAL ? (
              <img
                className='v3-card-blocked-success-img'
                src={
                  location.state?.from === "blockCard"
                    ? cardBlocked
                    : frozenCard
                }
                alt='blocked-card'
              />
            ) : (
              <img
                className='v3-card-blocked-success-img'
                src={
                  location.state?.from === "blockCard"
                    ? cardBlocked
                    : frozenCard
                }
                alt='forzen-card'
              />
            )}
          </div>

          <div className='v3-card-blocked-success-title'>
            {location.state?.from === "blockCard"
              ? t("CardDetailsWidgetV3.YourCardWasBlockedSuccessfully")
              : t("CardDetailsWidgetV3.YourCardWasFrozenSuccessfully")}
          </div>

          <div className='v3-card-blocked-success-subtitle'>
            {t("CardDetailsWidgetV3.effectiveDate", { effectiveDate: date })}
          </div>

          {/* <hr className='v3-card-block-success-hr-line'></hr> */}

          <div className='v3-card-blocked-success-sub-text'>
            {location.state?.from === "blockCard"
              ? t("CardDetailsWidgetV3.blockCardMessage")
              : t("CardDetailsWidgetV3.recurringTransactionsMessage")}
          </div>
          {location.state?.from !== "blockCard" && (
            <div className='v3-card-blocked-success-sub-text'>
              {t("CardDetailsWidgetV3.cardUnavailableForNewTransactions")}
            </div>
          )}

          {/* content box start */}

          <div
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            className='cardBlocked-ctaSection'
          >
            <DividerV3 />
            <ColorButton
              text={
                location?.state?.from === "blockCard"
                  ? t("CardDetailsWidgetV3.requestNewCardButton")
                  : t("CardDetailsWidgetV3.returnToHomeButton")
              }
              onPress={
                location?.state?.from === "blockCard"
                  ? requestNewCard
                  : returnHome
              }
            />
            {location?.state?.from === "blockCard" && (
              <WhiteButton
                text={t("CardDetailsWidgetV3.requestNewCardButton")}
                onPress={returnHome}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardBlocked_v3
