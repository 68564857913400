import moment from "moment"
import store from "../store/Store"
import { setEventFlow } from "../store/actions/Session"
import {
  AU_BlockCodesMapping,
  Federal_BlockCodesMapping,
} from "./blockCodesMapping"
import {
  GenerateLrSsoUrlStatus,
  ERROR_SCREEN_TRY_AGAIN_RETRY_COUNT,
  ErrorType,
  ProgramTypes,
  TransactionDateTypes,
  currencyCodes,
} from "./constants"
import {
  AU_BlockCodes,
  Envs,
  EventName,
  Federal_BlockCodes,
  LogLevels,
  PAY_BILL_BUTTON_TEXT,
  PwaVersions,
  cardSettingsOptions,
  eventConfig,
} from "./enums"
import logger from "./logger"
import badRequest from "../assets/images/v3/badRequest.svg"
import apiError from "../assets/images/v3/apiError.svg"
import noInternet from "../assets/images/v3/noInternet.svg"
import WarningIcon from "../components/svg/v3/WarningIcon"
import CryptoJS from "crypto-js"
import CommonService from "../services/CommonService"
import NoInternet from "../components/svg/v3/NoInternet"
import * as Sentry from "@sentry/react"

const getLogAsString = (level, args) => {
  const dateObj = new Date()
  const date = dateObj.toLocaleDateString("en-CA")
  const time = dateObj.toLocaleTimeString()

  if (args.length > 0) {
    args[0] =
      typeof args[0] === "string" && args[0].startsWith("\n")
        ? args[0].substring(1)
        : args[0]
    args[0] = `${date} ${time} ${level} PWA ` + args[0]
  }

  return args
    .map(arg => {
      if (typeof arg === "string") return arg

      if (arg && arg.hasOwnProperty("toString")) return arg.toString()

      return JSON.stringify(arg)
    })
    .join(" ")
}

export const consoleLog = (...args) => {
  const log = getLogAsString(LogLevels.INFO, args)
  logger.info(log)
}

export const consoleError = (...args) => {
  const log = getLogAsString(LogLevels.ERROR, args)
  logger.error(log)
}

export const consoleExceptions = (...args) => {
  const log = getLogAsString(LogLevels.ERROR, args)
  const env = process.env.REACT_APP_ENV
  if (env !== Envs.DEV) {
    const accountId = localStorage.getItem("accountId")
    Sentry.withScope(scope => {
      scope.setTag("accountId", accountId)
      scope.setExtra("logs", log)
      Sentry.captureException(new Error(log))
    })
  }
}
export const openLink = url => {
  const link = document.createElement("a")
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const formatString = (string = "") => {
  string = string || ""
  string = string.split(" ").filter(Boolean).join(" ")
  return string
}
export const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string

export const getTransactionDate = (
  transactionDate,
  postingDate,
  version = PwaVersions.V2,
) => {
  const programType = store.getState().user.programType
  const transactionDateType = store.getState().theme.transactionDateType

  // if txn type to show is posting date and posting date is not null then show posting date else txn date
  let date = transactionDate
  if (
    postingDate &&
    transactionDateType === TransactionDateTypes.POSTING_DATE
  ) {
    date = postingDate
  }

  const givenDate = moment(date).format("YYYY-MM-DD")
  const todayDate = moment().format("YYYY-MM-DD")
  const dateFormat =
    version === PwaVersions.V2_1
      ? "DD MMM YYYY"
      : programType === ProgramTypes.CREDIT_CARD
        ? "DD MMM YYYY"
        : "MMM Do"

  // if not today
  if (givenDate !== todayDate) {
    return moment(date).format(dateFormat)
  }

  // if program is credit card
  if (programType === ProgramTypes.CREDIT_CARD) {
    return moment(date).format(dateFormat)
  } else {
    return "Today, " + moment(date).format("h:mm a")
  }
}

export const formatAmount = (amount, isInt = false) => {
  const preferences = store.getState().preferences
  const currencyCode =
    store.getState().config?.jsonConfig?.currency?.currencyCode
  const isCurrencyFormatUpdated =
    store.getState().config?.jsonConfig?.currency?.isCurrencyFormatUpdated
  const currencySuffix =
    store.getState().config?.jsonConfig?.currency?.currencySuffix

  if (currencyCode) {
    const formattedAmount = amount.toLocaleString(currencyCode)
    if (isCurrencyFormatUpdated === "true") {
      const indexOfDecimalPoint = formattedAmount.indexOf(",")
      let displayAmount = `${formattedAmount.replace(/\./g, ",")}`

      // in case of decimal value, replace , with .
      if (indexOfDecimalPoint !== -1)
        displayAmount =
          displayAmount.slice(0, indexOfDecimalPoint) +
          "." +
          displayAmount.slice(indexOfDecimalPoint + 1)

      return displayAmount + ` ${currencySuffix}`
    }
    return formattedAmount + ` ${currencySuffix}`
  }

  if (isInt) {
    return Number(amount).toLocaleString(
      preferences.locales,
      preferences.localeOptions,
    )
  }
  return Math.abs(Number(amount)).toLocaleString(
    preferences.locales,
    preferences.localeOptions,
  )
}

export const formatNegativeAmount = amount => {
  let s = amount
  if (s.includes("-")) {
    s = s.replace("-", "(")
    s = s.concat(")")
  }
  return s
}

export const formatCardNumber = num =>
  String(num)
    .match(/.{1,4}/g)
    ?.join(" ")

export const copyToClipboard = async text => {
  if (!navigator.clipboard) {
    const textArea = document.createElement("textarea")
    document.body.appendChild(textArea)
    textArea.value = text
    textArea.select()
    document.execCommand("copy")
    document.body.removeChild(textArea)
    return
  }

  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    console.log(error)
  }
}

export const getApiConfig = (apiToken, apikey) => {
  const headers = {}

  if (apiToken) headers.apiToken = apiToken

  if (apikey) headers.apikey = apikey

  return { headers }
}

export const getLastDigitsOfMobileNumber = (n = "") => n.slice(-4)
export const getMaskedMobileNumber = (
  mobileNumber = "",
  numberOfUnmaskedDigits = 0,
  maskCharacter = "X",
) => {
  const maskedString = Array(mobileNumber.length - numberOfUnmaskedDigits)
    .fill(maskCharacter)
    .join("")
  const unmaskedString =
    numberOfUnmaskedDigits > 0
      ? mobileNumber.slice(-numberOfUnmaskedDigits)
      : ""
  return `${maskedString}${unmaskedString}`
}
export const getMaskedEmail = (n = "") => {
  return `${n.slice(0, 4)}*******@${n.split("@")[1]}`
}

export const setInputValue = (e, finalValue) => {
  const target = e.target
  const initValue = target.value

  // if the new char was rejected, cursor would have still moved by one so bring it back
  const selectionRange =
    target.selectionStart + finalValue?.length - initValue?.length

  // set value
  target.value = finalValue

  // setting a value different than the actual value moves cursor to end hence reset the cursor
  target.setSelectionRange(selectionRange, selectionRange)
}

// ios integration
export const isIosDevice = () =>
  Boolean(window?.webkit?.messageHandlers?.hyperfaceIosBridge)

export const postMessageToIos = action =>
  window?.webkit?.messageHandlers?.hyperfaceIosBridge?.postMessage(action)
// ios integration

export const getAnalyticsProgramType = programType => {
  if (programType === ProgramTypes.CREDIT_CARD) return "CC"
  else if (programType === ProgramTypes.PAY_LATER) return "PL"
  else if (programType === ProgramTypes.PREPAID) return "PPD"
  else return "Undefined Program Type"
}

export const goToRedirectUrl = () => {
  const session = store.getState().session

  if (isIosDevice()) {
    postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
  } else {
    window.location.href = session.redirectUrl
  }
}

export const getEnumsFromAU_BlockCodes = blockcodes => {
  let blockCodesMapping = AU_BlockCodesMapping
  let blockCodesEnums = AU_BlockCodes
  let disabledFeaturesByBlockCodes = []

  for (let currBlockCode of blockcodes) {
    let currMappedArray = Object.keys(blockCodesMapping)
    currMappedArray.forEach(item => {
      if (blockCodesMapping[item].includes(currBlockCode)) {
        if (
          disabledFeaturesByBlockCodes.indexOf(blockCodesEnums[item]) === -1
        ) {
          disabledFeaturesByBlockCodes.push(blockCodesEnums[item])
        }
      }
    })
  }
  return disabledFeaturesByBlockCodes
}

export const getUnion = (array1, array2) => {
  let unionArray = array1
  for (let element of array2) {
    if (unionArray.indexOf(element) === -1) {
      unionArray.push(element)
    }
  }
  return unionArray
}
export async function retrieveHTMLFromTxt(url) {
  let headerFromFile = ""
  headerFromFile = fetch(url)
    .then(r => r.text())
    .then(text_content => {
      headerFromFile = text_content
      return headerFromFile
    })
    .catch(err => {
      console.log(err)
    })
  return headerFromFile
}

export const capitalizeFirsLetter = sentence => {
  sentence = sentence.trim()
  const wordsToSkip = ["DOM", "POS", "INTL", "XXXXXX", "GST", "EMI"]
  const words = sentence.split(" ")
  for (let i = 0; i < words.length; i++) {
    if (
      words[i] === "" ||
      wordsToSkip.includes(words[i]) ||
      words[i].includes("XXXXXX")
    ) {
      continue
    }
    words[i] = words[i].toLowerCase()
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }
  return words.join(" ")
}

export const isCardInactive = (cards, cardId) => {
  let cardInactive = false
  cards.map(card => {
    if (card.id === cardId) {
      if (card.cardStatus === "INACTIVE") {
        cardInactive = true
      }
    }
  })
  return cardInactive
}

export const getEnumsFromFederal_BlockCodes = blockcodes => {
  let blockCodesMapping = Federal_BlockCodesMapping
  let blockCodesEnums = Federal_BlockCodes
  let disabledFeaturesByBlockCodes = []

  for (let currBlockCode of blockcodes) {
    let currMappedArray = Object.keys(blockCodesMapping)
    currMappedArray.forEach(item => {
      if (blockCodesMapping[item].includes(currBlockCode)) {
        if (
          disabledFeaturesByBlockCodes.indexOf(blockCodesEnums[item]) === -1
        ) {
          disabledFeaturesByBlockCodes.push(blockCodesEnums[item])
        }
      }
    })
  }
  return disabledFeaturesByBlockCodes
}

export const addCssFromUrl = (url, callback) => {
  const link = document.createElement("link")
  link.setAttribute("rel", "stylesheet")
  link.setAttribute("href", url)
  link.onload = callback
  document.head.appendChild(link)
}
export const addJsFromUrl = url => {
  const script = document.createElement("script")
  script.setAttribute("src", url)
  document.head.appendChild(script)
}

export const captureEvents = data => {
  try {
    const session = store.getState().session
    data.name = eventConfig[data.eventName]?.eventName
    let metadata = data.metadata
    if (eventConfig[data.eventName]?.flowRequired) {
      metadata.flow = session?.eventFlow
    }
    for (const key in metadata) {
      if (metadata.hasOwnProperty(key)) {
        if (!metadata[key] && metadata[key] != 0) metadata[key] = "NA"
      }
    }
    data.metadata = metadata
    // data.flow =  "NA"
    data.clientParams = session?.clientParams || "NA"

    if (data.eventName === EventName.HOME) {
      let nudges = data?.metadata?.nudge
      const nudgeTitles =
        nudges && nudges != "NA"
          ? nudges.map(
              nudge => JSON.parse(nudge?.displayOptions?.nudgeDetails)?.title,
            )
          : []
      data.metadata.nudge = nudgeTitles
    }

    if (data.eventName == EventName.ALL_OFFERS) {
      let offers = data?.metadata?.offers
      const offerTitles =
        offers && offers != "NA" ? offers.map(offer => offer?.name) : []
      data.metadata.offers = offerTitles
    }

    if (window?.postEvent && eventConfig[data.eventName]?.allowed) {
      console.log(data.eventName, eventConfig[data.eventName]?.allowed, data)
      window?.postEvent(data?.name, data)
    }

    if (data.eventName != EventName.SESSION_EXPIRED && data?.name) {
      store.dispatch(
        setEventFlow({
          eventFlow: data?.name,
        }),
      )
    }
  } catch (error) {
    consoleError(error)
  }
}

export const getStatementsYears = (startYear, uptoYear, selectedYear) => {
  let years = []
  for (let i = startYear; i >= uptoYear; i--) {
    years.push(i + "")
  }
  if (years.length == 0) {
    years.push(selectedYear)
  }
  return years
}

export const getStatementsMonths = (
  selectedYear,
  uptoYear,
  startYear,
  startMonth,
  uptoMonth,
  selectedMonth,
) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  if (selectedYear < uptoYear) {
    return [selectedMonth]
  } else if (selectedYear == uptoYear && selectedYear == startYear) {
    if (startMonth >= uptoMonth) {
      return months.slice(uptoMonth, startMonth + 1)
    } else {
      return [selectedMonth]
    }
  } else if (selectedYear == uptoYear) {
    return months.slice(uptoMonth)
  } else if (selectedYear == startYear) {
    return months.slice(0, startMonth + 1)
  } else {
    return months
  }
}

export const hexToRGBA = (hex, alpha) => {
  if (!hex) return
  // Remove the hash (#) if present
  hex = hex.replace("#", "")

  // Parse the hex values to get R, G, B components
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Create the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const getDifferenceInDays = (endDate, startDate) => {
  return moment.duration(moment(endDate).diff(moment(startDate))).asDays()
}
export const getDate = dateString => {
  const dateObj = new Date(dateString)
  const day = dateObj.getDate()
  const month = dateObj.toLocaleString("en-US", { month: "long" })
  const year = dateObj.getFullYear()
  return `${day} ${month} ${year}`
}

export const getMonthForIndex = index => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  if (index < 0 || index > 11) return null
  return months[index]
}

export const getCurrentFinancialYear = () => {
  let financialYear = ""
  let today = new Date()
  let leftBound = 0
  let rightBound = 0
  let leftBoundFullYear = 0
  let rightBoundFullYear = 0
  if (today.getMonth() + 1 <= 3) {
    leftBoundFullYear = today.getFullYear() - 1
    rightBoundFullYear = today.getFullYear()
    leftBound = parseInt((today.getFullYear() - 1).toString().substring(2))
    rightBound = parseInt(today.getFullYear().toString().substring(2))
    financialYear =
      "'" +
      (today.getFullYear() - 1).toString().substring(2) +
      "-'" +
      today.getFullYear().toString().substring(2)
  } else {
    leftBoundFullYear = today.getFullYear()
    rightBoundFullYear = today.getFullYear() + 1
    leftBound = parseInt(today.getFullYear().toString().substring(2))
    rightBound = parseInt((today.getFullYear() + 1).toString().substring(2))
    financialYear =
      "'" +
      today.getFullYear().toString().substring(2) +
      "-" +
      (today.getFullYear() + 1).toString().substring(2)
  }
  return {
    financialYear,
    leftBound,
    rightBound,
    leftBoundFullYear,
    rightBoundFullYear,
  }
}

export const colorPicker = hexColor => {
  const red = parseInt(hexColor.slice(1, 3), 16)
  const green = parseInt(hexColor.slice(3, 5), 16)
  const blue = parseInt(hexColor.slice(5, 7), 16)
  const opacity1 = 1
  const opacity2 = 0.62
  const opacity3 = 0.38
  const opacity4 = 0.18
  const opacity5 = 0.06
  const primary1 = `rgba(${red}, ${green}, ${blue}, ${opacity1})`
  const primary2 = `rgba(${red}, ${green}, ${blue}, ${opacity2})`
  const primary3 = `rgba(${red}, ${green}, ${blue}, ${opacity3})`
  const primary4 = `rgba(${red}, ${green}, ${blue}, ${opacity4})`
  const primary5 = `rgba(${red}, ${green}, ${blue}, ${opacity5})`
  return { primary1, primary2, primary3, primary4, primary5 }
}
export const getFormatedBillingCycle = (fromDate, toDate) => {
  let finalCycle =
    moment(fromDate).format("DD MMM 'YY") +
    " - " +
    moment(toDate).format("DD MMM 'YY")
  return finalCycle
}

export const debounceFunction = (func, delay) => {
  let timer
  return function (...args) {
    const context = this
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      func.apply(context, args)
    }, delay)
  }
}

export const getSortedComponentsBasedOnOrder = response => {
  if (!response) return
  const sections = Object.keys(response).sort(
    (a, b) => response[a].order - response[b].order,
  )
  const enabledSections = []
  sections.forEach(section => {
    if (response[section].isEnabled) {
      enabledSections.push(section)
    }
  })

  return enabledSections
}

export const hex2hsl = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  let r = parseInt(result[1], 16)
  let g = parseInt(result[2], 16)
  let b = parseInt(result[3], 16)

  r /= 255
  g /= 255
  b /= 255
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h,
    s,
    l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  s = s * 100
  s = Math.round(s)
  l = l * 100
  l = Math.round(l)
  h = Math.round(360 * h)

  return {
    h,
    s,
    l,
  }
}

export const hsl2hex = (h, s, l) => {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0") // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export const addClassname = (element, classname) => {
  element.classList.add(classname)
}
export const removeClassname = (element, classname) => {
  element.classList.remove(classname)
}

export const formatToWholeCurrency = amount => {
  const preferences = store.getState().preferences
  const currencyCode =
    store.getState().config?.jsonConfig?.currency?.currencyCode
  const isCurrencyFormatUpdated =
    store.getState().config?.jsonConfig?.currency?.isCurrencyFormatUpdated
  const currencySuffix =
    store.getState().config?.jsonConfig?.currency?.currencySuffix

  if (currencyCode) {
    const formattedAmount = amount.toLocaleString(currencyCode, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    if (isCurrencyFormatUpdated === "true") {
      const indexOfDecimalPoint = formattedAmount.indexOf(",")
      let displayAmount = `${formattedAmount.replace(/\./g, ",")}`

      // in case of decimal value, replace , with .
      if (indexOfDecimalPoint !== -1)
        displayAmount =
          displayAmount.slice(0, indexOfDecimalPoint) +
          "." +
          displayAmount.slice(indexOfDecimalPoint + 1)

      return displayAmount + ` ${currencySuffix}`
    }
    return formattedAmount + ` ${currencySuffix}`
  }

  return amount.toLocaleString(preferences.locales, {
    ...preferences.localeOptions,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
export const nativeBaseColor2rgba = color => {
  const colorVals = color?.split(":")
  if (!color || colorVals.length < 2) {
    return colorVals?.[0]
  }
  const alphaPercent = +colorVals[1].split(".")[1]
  return hexToRGBA(colorVals[0], alphaPercent / 100)
}

export const getNativeBaseBoxShadow = shadowLevel => {
  if (!shadowLevel) {
    return
  }
  const alphaBase = 0.18
  const alphaStep = 0.02
  const vOffsetBase = 1
  const vOffsetStep = 0.66
  return `1px ${vOffsetBase + +shadowLevel * vOffsetStep}px rgba(0, 0, 0, ${alphaBase + +shadowLevel * alphaStep})`
}

/**
 * @param {string | number} space
 * @param {1 | 4} multiple
 * @param {number} maxInputToMultiply
 * @returns {string} The space value in string
 */
export const getNativeBaseSpaceToPx = (
  space,
  multiple = 4,
  maxInputToMultiply = 96,
) => {
  if (
    multiple !== 1 &&
    typeof space === "number" &&
    space > maxInputToMultiply
  ) {
    return `${space}${!isNaN(+space) ? "px" : ""}`
  }
  if (!isNaN(+space)) {
    return `${+space * multiple}px`
  }
  return space
}

export const truncateTransactionId = transactionId => {
  if (transactionId?.length > 20) {
    return `${transactionId.slice(0, 6)}....${transactionId.slice(-4)}`
  }
  return transactionId
}

export const getErrorText = statusCode => {
  switch (statusCode) {
    case 500:
      return "Something went wrong."
    case 404:
      return "Something went wrong."
    case 400:
      return "Bad Request!"
    case "NO_INTERNET_ERROR":
      return "Not connected to internet"
    default:
      return "Something went wrong."
  }
}

export const getErrorDescription = statusCode => {
  switch (statusCode) {
    case 500:
      return "Sorry, your request could not be processed. Please try again later."
    case 404:
      return "Sorry, your request could not be processed. Please try again later."
    case 400:
      return "There seems to be an API error. Please try again or report problem for persistent error."
    case "NO_INTERNET_ERROR":
      return "Please make sure you are connected to a stable internet connection and try again."
    default:
      return "Sorry, your request could not be processed. Please try again later."
  }
}

export const getErrorImage = statusCode => {
  switch (statusCode) {
    case 500:
      return <img src={apiError} alt='error' />
    case 404:
      return <img src={apiError} alt='error' />
    case 400:
      return <img src={badRequest} alt='error' />
    case ErrorType.NO_INTERNET_ERROR:
      return <NoInternet />
    default:
      return <WarningIcon />
  }
}

export const hideGenericError = statusCode => {
  return statusCode === 500 || statusCode === 404 || statusCode === 400
}

export const showSecondaryCta = statusCode => {
  let tryAgainCount =
    JSON.parse(localStorage.getItem("errorTryAgainCount")) || 0
  return (
    (statusCode >= 500 &&
      statusCode < 600 &&
      tryAgainCount < ERROR_SCREEN_TRY_AGAIN_RETRY_COUNT) ||
    statusCode === ErrorType.NO_INTERNET_ERROR
  )
}

export const getPrimaryCtaText = error => {
  let tryAgainCount =
    JSON.parse(localStorage.getItem("errorTryAgainCount")) || 0

  switch (error?.statusCode) {
    case 500:
      if (
        error?.callback &&
        tryAgainCount < ERROR_SCREEN_TRY_AGAIN_RETRY_COUNT
      ) {
        return "Try Again"
      } else {
        return "Back to Home"
      }

    case ErrorType.NO_INTERNET_ERROR:
      return "Reload Page"

    default:
      return "Back to Home"
  }
}

export const getPrimaryCtaOnclick = (error, navigate, clearErrorState) => {
  let tryAgainCount =
    JSON.parse(localStorage.getItem("errorTryAgainCount")) || 0

  if (
    error?.statusCode >= 500 &&
    error?.statusCode < 600 &&
    error.callback &&
    tryAgainCount < ERROR_SCREEN_TRY_AGAIN_RETRY_COUNT
  ) {
    return () => {
      error.callback()
      clearErrorState()
    }
  } else {
    return () => {
      clearErrorState()
      navigate("/")
    }
  }
}

export const resetErrorTryAgainCount = () => {
  localStorage.setItem("errorTryAgainCount", 0)
}

export const getFlowIdForSetMpin = (
  oldMpinMatchCount,
  oldMpinMatchedCounter,
) => {
  if (oldMpinMatchCount > 0) {
    if (oldMpinMatchedCounter > 0) return "2"
    else return "1"
  }
  return null
}
export const isResponseValid = (response, checksum) => {
  const responstBody = JSON.stringify(response?.data?.data)
  let key = process.env.REACT_APP_CHECKSUM_KEY || ""
  let hashedChecksum = CryptoJS.HmacSHA256(responstBody, key).toString(
    CryptoJS.enc.Hex,
  )
  if (!key || hashedChecksum == checksum) return true
  return false
}
export const tempFormatTransactionsData = (transactions, data, count) => {
  if (transactions && transactions?.length !== 0) {
    return {
      data: {
        success: true,
        data: {
          transactions: transactions,
          hasMore: count + data?.offset * count < data?.totalCount,
        },
      },
    }
  }
}
export const isWebKitBrowser = () => {
  let ua = navigator.userAgent.toLowerCase()
  let isWebKit = false
  if (
    ua.indexOf("chrome") === ua.indexOf("android") &&
    ua.indexOf("safari") !== -1
  ) {
    isWebKit = true
  } else {
    if (
      ua.indexOf("ipad") !== -1 ||
      ua.indexOf("iphone") !== -1 ||
      ua.indexOf("ipod") !== -1
    ) {
      isWebKit = true
    } else {
      isWebKit = false
    }
  }
  return isWebKit
}

export const handleWebKitTextInputFocus = e => {
  if (isWebKitBrowser() && ["TEXTAREA", "INPUT"].includes(e.target.tagName)) {
    e.stopPropagation()
    const target = e.target
    target.style.transform = "TranslateY(-10000px)"
    target.focus()

    setTimeout(() => {
      target.style.transform = "none"
    }, 100)
  }
}

export const showErrorScreenV3 = (statusCode, isLoggedIn, version) => {
  return (
    (statusCode &&
      !isLoggedIn &&
      statusCode !== 400 &&
      (version === PwaVersions.V3 || version === "v3")) ||
    (isLoggedIn && statusCode === ErrorType.NO_INTERNET_ERROR)
  )
}

export const lrSsoRedirection = async (
  requestData,
  redeemUrl,
  setRedirectionLoading,
) => {
  setRedirectionLoading(true)
  try {
    const response = await CommonService.generateLrSsoUrl(requestData)
    if (response.data?.data?.status === GenerateLrSsoUrlStatus.SUCCESS) {
      window.open(response.data?.data?.redirectUrl)
    } else {
      window.open(redeemUrl || "https://rewardz.aubank.in/")
    }
    setRedirectionLoading(false)
  } catch (error) {
    consoleError(error)
    window.open(redeemUrl || "https://rewardz.aubank.in/")
    setRedirectionLoading(false)
  }
}

export const getCardSettingsOptions = settings => {
  let optionsList = []
  settings.map((setting, index) => {
    if (setting?.isEnabled && setting?.id) {
      optionsList.push(setting?.id)
    }
  })
  return optionsList
}

export const getMonthlyYearlyDateRanges = (
  startDate,
  endDate,
  dateInterval,
) => {
  const dateRanges = []
  let currentStartDate = moment(startDate)
  const finalEndDate = moment(endDate)

  while (currentStartDate.isBefore(finalEndDate)) {
    let currentEndDate

    if (dateInterval === "YEAR") {
      currentEndDate = moment(currentStartDate).endOf("year")
    } else {
      currentEndDate = moment(currentStartDate).endOf("month")
    }

    if (currentEndDate.isAfter(finalEndDate)) {
      currentEndDate = finalEndDate
    }

    dateRanges.push({
      from: currentStartDate.format("YYYY-MM-DD"),
      to: currentEndDate.format("YYYY-MM-DD"),
    })

    currentStartDate =
      dateInterval === "YEAR"
        ? currentEndDate.add(1, "days").startOf("year")
        : currentEndDate.add(1, "days").startOf("month")
  }

  return dateRanges
}
export const isValidSuccessResponse = (path, urlsExemptFromValidation) => {
  for (const currentPath of urlsExemptFromValidation) {
    if (currentPath.includes("$")) {
      const currentPathEndPoint = currentPath.split("/")
      const pathEndPoint = path.split("/")
      if (
        currentPathEndPoint[currentPathEndPoint.length - 1] ===
        pathEndPoint[pathEndPoint.length - 1]
      )
        return false
    } else {
      if (currentPath === path) return false
    }
  }
  return true
}

export const formatDateTime = isoDateString => {
  const date = new Date(isoDateString)

  const options = { day: "numeric", month: "short", year: "numeric" }
  const formattedDate = date.toLocaleDateString("en-GB", options)

  let hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, "0")
  const ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12 || 12

  return `${formattedDate}, ${hours}:${minutes} ${ampm}`
}
