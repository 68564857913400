import { useSelector } from "react-redux"
import { ProgramTypes } from "../utils/constants"
import useWindowDimensions from "./useWindowDimensionsWeb"

const usePaylaterDesktopViewEnabled = () => {
  const user = useSelector(state => state.user)
  const windowDimension = useWindowDimensions()
  const programType = user.programType
  const isWebViewEnabled =
    programType === ProgramTypes.PAY_LATER && windowDimension.width >= 500

  return isWebViewEnabled
}

export default usePaylaterDesktopViewEnabled
