import React, { useState } from "react"
import hyperfaceLogo from "../../../assets/images/demoLogin/HyperfaceLogo.svg"
import horizontalBar from "../../../assets/images/demoLogin/horizontalBar.svg"
import rocketlaunch from "../../../assets/images/demoLogin/rocketLaunch.svg"
import mobile from "../../../assets/images/demoLogin/mobile.png"
import PublicService from "../../../services/PublicService"
import demoButtonLoader from "../../../components/svg/demoButtonLoader.json"
import Lottie from "lottie-react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

const DemoLogin = () => {
  const [password, setPassword] = useState("")
  const [username, setUsername] = useState("")
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)

  const { issuerName } = useParams()
  const { t } = useTranslation()

  const getSessionToken = async data => {
    setLoading(true)
    try {
      const response = await PublicService.cbccDemoLogin(data)
      if (response.status === 200) {
        const payload = response.data?.data?.payload
        window.location.href = `${process.env.REACT_APP_URL}?sessionToken=${payload?.token}&tenantId=${payload?.tenantId}`
      } else {
        setIsError(true)
      }
    } catch (error) {
      console.log(error)
      setIsError(true)
    }
    setLoading(false)
  }

  const handleSubmit = () => {
    getSessionToken({
      username: username?.toLowerCase(),
      password,
      issuerName: issuerName?.toLowerCase(),
    })
  }

  return (
    <div className={"demoLogin-wrapper"}>
      <div className={"demoLogin-container"}>
        <div className={"demoLogin-logo-container"}>
          <img src={hyperfaceLogo} className='demoLogin-logo' />
        </div>

        <div className={"demoLogin-horizontalBar-container"}>
          <img src={horizontalBar} className='demoLogin-logo' />
        </div>

        <p className={"demoLogin-heading-container"}>
          {t("messages.accessProductDemo")}
        </p>

        <p className={"demoLogin-content-container"}>
          {t("messages.enterCredentialsForHyperfaceDemo")}
        </p>

        <div className={"demoLogin-input-container"}>
          <input
            type='text'
            placeholder='Username'
            className={"demoLogin-input"}
            value={username}
            onChange={e => setUsername(e.target.value.replace(/\s/g, ""))}
            onFocus={() => setIsError(false)}
            onKeyDown={e => e.key === " " && e.preventDefault()}
          />
          <input
            type='password'
            placeholder='Password'
            className={"demoLogin-input"}
            value={password}
            onChange={e => setPassword(e.target.value)}
            style={isError ? { border: "1px solid #ff2e2e" } : {}}
            onFocus={() => setIsError(false)}
          />
          {isError && (
            <p className={"demoLogin-error"}>
              {t("messages.usernameAndPasswordDoNotMatch")}
            </p>
          )}
        </div>

        <div className={"demoLogin-button-container"}>
          <button
            className={
              username && password && !isError
                ? "demoLogin-button-active"
                : "demoLogin-button"
            }
            onClick={() => username && password && handleSubmit()}
          >
            {loading ? (
              <Lottie
                loop={true}
                animationData={demoButtonLoader}
                style={{ width: "200px" }}
              />
            ) : (
              <>
                <img src={rocketlaunch} className='demoLogin-rocket-logo' />
                {t("messages.launchApp")}
              </>
            )}
          </button>
        </div>

        <div className={"demoLogin-mobile-container"}>
          <img src={mobile} className={"demoLogin-mobile-img"} />
        </div>
      </div>
    </div>
  )
}

export default DemoLogin
