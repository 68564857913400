import rupayImg from "../../../../assets/images/v3/rupayImg.svg"
import masterCardImg from "../../../../assets/images/v3/masterCardSvg.svg"
import visaImg from "../../../../assets/images/v3/VISA.svg"
import dinersImg from "../../../../assets/images/v3/DinersLogo.svg"
import amexImg from "../../../../assets/images/v3/AmexLogo.svg"
import downArrow from "../../../../assets/images/v3/CaretDown.svg"
import { useSelector } from "react-redux"
import { cardStatus, networkType } from "../../../../utils/enums"

const CreditCardInfo = ({ setIsBottomSheetOpen }) => {
  const user = useSelector(state => state.user)
  const multicard = user?.multicardAccounts || []
  const activeAccountId = user?.account?.id
  const activeCard = multicard?.find(card => card.id === activeAccountId)

  const getNetworkImage = network => {
    switch (network) {
      case networkType.Visa:
        return visaImg
      case networkType.MasterCard:
        return masterCardImg
      case networkType.Rupay:
        return rupayImg
      case networkType.Diners:
        return dinersImg
      case networkType.Amex:
        return amexImg
      default:
        return rupayImg
    }
  }

  return (
    <div
      className='v3-home-activitySection-creditCardInfo'
      style={{ display: !activeCard && "none" }}
    >
      <div className='v3-home-activitySection-card-container'>
        <div className='v3-home-activitySection-card-text'>
          {activeCard?.programName} -{" "}
        </div>
        <div className='v3-home-activitySection-card-image'>
          <img
            src={getNetworkImage(activeCard?.primaryCard?.network)}
            alt='card network'
          />
        </div>
      </div>
      <div
        className='v3-home-activitySection-card-details'
        onClick={() => setIsBottomSheetOpen(true)}
      >
        <div className='v3-home-activitySection-card-details-number'>
          **** {activeCard?.primaryCard?.last4}
        </div>
        <div className='v3-home-activitySection-card-details-arrow-img'>
          <img src={downArrow} alt='expand' />
        </div>
      </div>
    </div>
  )
}

export default CreditCardInfo
