import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../auth/ApiIds"
import CardService from "../../services/CardService"
import { handleApiAuth } from "../../utils/auth"
import { DisplayConfig, ProgramTypes } from "../../utils/constants"
import { PwaVersions } from "../../utils/enums"
import { consoleError } from "../../utils/functions"
import CashIcon from "../svg/cash"
import ContactlessIcon from "../svg/contactless"
import ONLINEIcon from "../svg/online"
import PosIcon from "../svg/pos"
import { ColorButton, WhiteButton } from "./buttons"
import { Header } from "./headers"
import { showToast1 } from "./toast"
import View from "../../nativeBaseReplacements/View"
import Text from "../../nativeBaseReplacements/Text"
import { useToast } from "../../nativeBaseReplacements/useToast"
import ReactSwitch from "react-switch"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"
import { useTranslation } from "react-i18next"

const ActivationLimits = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const windowDimensions = useWindowDimensions()
  const nativeToast = useToast()
  const [saveLoading, setSaveLoading] = useState(false)
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const [controls, setControls] = useState([true, true, false, false])

  const ICONS = {
    ONLINE: (
      <ONLINEIcon
        className='cardSetting-online-transaction-icon'
        color={theme.appTextColor}
        size='18px'
        styles={{ "margin-right": "10px" }}
      />
    ),
    OFFLINE: (
      <PosIcon
        className='cardSetting-online-transaction-icon'
        color={theme.appTextColor}
        size='18px'
        styles={{ "margin-right": "10px" }}
      />
    ),
    CONTACTLESS: (
      <ContactlessIcon
        className='cardSetting-online-transaction-icon'
        color={theme.appTextColor}
        size='18px'
        styles={{ "margin-right": "10px" }}
      />
    ),
    CASH: (
      <CashIcon
        className='cardSetting-online-transaction-icon'
        color={theme.appTextColor}
        size='18px'
        styles={{ "margin-right": "10px" }}
      />
    ),
  }

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const navigateForCardActivation = () =>
    navigate("/", { replace: true, state: { from: "CardActivated" } })

  const handleSaveClick = async () => {
    setSaveLoading(true)

    await handleApiAuth({
      apiId: ApiIds.UPDATE_CARD_CONTROLS,
      flowId: "4",
      onAuthSuccess: onCardSettingsAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: t("activation.saveCardSettingsReason"),
      mpinReason: t("activation.saveCardSettingsReason"),
      toast,
      navigate,
    })

    setSaveLoading(false)
  }
  const isPaylaterOrPrepaidProgram =
    user.programType === ProgramTypes.PAY_LATER ||
    user.programType === ProgramTypes.PREPAID

  const onCardSettingsAuthSuccess = async apiToken => {
    try {
      // set api data
      const data = [
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE",
          },
          isEnabled: controls[0],
        },
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "CONTACT",
          },
          isEnabled: controls[1],
        },
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "MAGNETIC",
          },
          isEnabled: controls[1],
        },
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "CONTACTLESS",
          },
          isEnabled: controls[2],
        },
      ]
      if (!isPaylaterOrPrepaidProgram) {
        data.push({
          txnTags: {
            location: "DOMESTIC",
            txnType: "CASH",
          },
          isEnabled: controls[2],
        })
      }

      const response = await CardService.updateCardControls(
        apiToken,
        user.cards[0]?.id,
        {
          updateCardControls: data,
        },
      )
      const result = response?.data

      if (result?.success) {
        navigateForCardActivation()
        toast(t("activation.cardSettingsUpdatedSuccessMessage"), true)
      } else {
        consoleError(
          t("activation.updateCardSettingsErrorMessage", {
            errMessage: result?.errors,
          }),
        )
        navigateForCardActivation()
        toast(t("activation.updateCardSettingsErrorToast"), true)
      }
    } catch (error) {
      consoleError(
        t("activation.updateCardSettingsErrorMessage", { errMessage: error }),
      )
      toast(t("activation.updateCardSettingsErrorToast"))
      navigateForCardActivation()
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigateForCardActivation()
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigateForCardActivation()
  }

  const CARD_CONTROLS = [
    {
      title: t("activation.cardControlTitleOnlineTransactions"),
      icon: ICONS.ONLINE,
    },
    {
      title: t("activation.cardControlTitleOfflineTransactions"),
      icon: ICONS.OFFLINE,
    },
    {
      title: t("activation.cardControlTitleContactless"),
      icon: ICONS.CONTACTLESS,
    },
    { title: t("activation.cardControlTitleCashWithdrawal"), icon: ICONS.CASH },
  ]

  const renderControls = (title, controlIndex, icon) => {
    return (
      <View
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        borderBottomWidth={
          controlIndex == 3 || (controlIndex == 2 && isPaylaterOrPrepaidProgram)
            ? "none"
            : "1px"
        }
        borderBottomColor={
          controlIndex == 3 || (controlIndex == 2 && isPaylaterOrPrepaidProgram)
            ? "none"
            : "#ECECEC"
        }
        pb='16px'
        pt='16px'
      >
        <Text
          fontWeight='500'
          fontSize='16px'
          lineHeight='20px'
          color={theme.appTextColor}
          display={"flex"}
        >
          {icon}
          {title}
        </Text>
        <ReactSwitch
          width={35}
          height={20}
          handleDiameter={16}
          onHandleColor='whitesmoke'
          offHandleColor='whitesmoke'
          offColor='grey'
          disabled={false}
          checked={controls[controlIndex]}
          onColor={
            config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1
              ? theme.color1
              : theme.color2
          }
          onChange={value => {
            console.log(controlIndex, controls)
            let newControls = [...controls]
            newControls[controlIndex] = value
            setControls(newControls)
          }}
        />
      </View>
    )
  }

  return (
    <View backgroundColor={theme.backgroundColor}>
      <Header
        text={t("activation.activateCardHeader")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <View
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        minH={
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? windowDimensions.height
            : null
        }
        bgColor={
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? theme.backgroundColor
            : null
        }
      >
        <View height={windowDimensions.height} width='92%' m='auto'>
          <View
            px='20px'
            py='16px'
            bgColor='#F8F8F8'
            mb='12px'
            borderRadius='6px'
          >
            <View>
              <Text
                fontWeight='700'
                fontSize='20px'
                lineHeight='26px'
                color='#111111'
              >
                {t("activation.setupCardControlsText")}
              </Text>
              <Text
                mt='6px'
                fontWeight='400'
                fontSize='14px'
                lineHeight='24px'
                color='#111111'
                mb='20px'
              >
                {t("activation.domesticTransationsText")}
              </Text>
            </View>

            {CARD_CONTROLS &&
              CARD_CONTROLS.map((control, index) => {
                return (
                  <>
                    {index <= 2 || (!isPaylaterOrPrepaidProgram && index > 2)
                      ? renderControls(control.title, index, control.icon)
                      : null}
                  </>
                )
              })}
          </View>
          <View alignItems='center' justifyContent='center' w='100%'>
            <View
              _web={{ maxW: DisplayConfig.MAX_WIDTH }}
              pt='8px'
              alignItems='center'
              justifyContent='center'
              mt='24px'
              width='100%'
              bottom='24px'
              position='fixed'
            >
              <ColorButton
                text={t("activation.submitText")}
                isLoading={saveLoading}
                isDisable={false}
                onPress={handleSaveClick}
                width='92%'
              />
              {!config?.version == PwaVersions.V2 ? (
                <WhiteButton
                  text={t("activation.cancelText")}
                  onPress={() => navigateForCardActivation()}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default ActivationLimits
