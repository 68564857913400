import queryString from "query-string"
import { useTranslation } from "react-i18next"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import cardActivated from "../../../assets/images/cardActivated.svg"
import lineSeparator from "../../../assets/images/lineSeparator.svg"
import setupPin from "../../../assets/images/setupPin.svg"
import verifyOTP from "../../../assets/images/verifyOtp.svg"
import PublicService from "../../../services/PublicService"
import { ExternalPathRoots } from "../../../utils/constants"
import { consoleError } from "../../../utils/functions"
import { ColorButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import styles from "./styles"
import View from "../../../nativeBaseReplacements/View"
import Text from "../../../nativeBaseReplacements/Text"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import { Image, ScrollView } from "../../../nativeBaseReplacements"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const ContextSetting = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const location = useLocation()
  const nativeToast = useToast()
  const theme = useSelector(state => state.theme)

  const [submitLoading, setSubmitLoading] = useState(false)

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const getResetPinWidget = async () => {
    const queryParams = queryString.parse(location.search)

    if (!queryParams?.activationCode) {
      consoleError(t("activation.invalidActivationCodeText"))
      toast(t("activation.invalidActivationCodeText"))
      return
    }

    setSubmitLoading(true)
    try {
      const getInfoResponse = await PublicService.getInfoFromActivationCode({
        activationCode: queryParams?.activationCode,
      })
      const getInfoResult = getInfoResponse.data

      if (getInfoResult?.success) {
        const getResetPinResponse = await PublicService.getResetPinWidget({
          cardId: getInfoResult.data?.cardId,
          callbackUrl:
            process.env.REACT_APP_URL +
            `/${ExternalPathRoots.EXTERNAL_PATH}/CardActivation/CardActive`,
        })
        const getResetPinResult = getResetPinResponse.data

        if (getResetPinResult?.success) {
          localStorage.setItem("cardId", getInfoResult.data?.cardId)
          window.location.href = getResetPinResult.data
        } else {
          consoleError(getResetPinResult?.errors)
          toast(t("Otp.otpRequestErrorText"))
        }
      } else {
        consoleError(getInfoResult?.errors)
        toast(t("Otp.otpRequestErrorText"))
      }
    } catch (error) {
      consoleError(error)
      toast(t("Otp.otpRequestErrorText"))
    }
    setSubmitLoading(false)
  }

  return (
    <ScrollView>
      <View height={windowDimensions.height} minHeight='900px'>
        <View {...styles.mainView}>
          {/* <View alignItems="center" justifyContent="center">
            <View my="16px">
              {orientation === "Vertical" ? (
                <Image
                  source={{ uri: VerticalCard }}
                  height={"343px"}
                  width={"216px"}
                />
              ) : (
                <Image
                  source={{ uri: HorizontalCard }}
                  height={"216px"}
                  width={"343px"}
                />
              )}
            </View>
          </View> */}
          <View mt='16px'>
            <Text {...styles.clientText} fontFamily={theme.fontFamily}>
              {t("activation.cardActivationSteps", { noSteps: 4 })}
            </Text>
            <Text
              {...styles.physicalpayLatertext}
              fontFamily={theme.fontFamily}
            >
              {t("activation.offlineTransactionActivate")}
            </Text>
          </View>
          <View {...styles.processView}>
            <View {...styles.processMainView}>
              <Image source={{ uri: verifyOTP }} {...styles.processImage} />
              <View ml='3'>
                <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                  {t("activation.step", { stepNo: 1 })}
                </Text>
                <Text {...styles.processStepName} fontFamily={theme.fontFamily}>
                  {t("activation.verifyCardDetailsText")}
                </Text>
              </View>
            </View>
            <Image source={{ uri: lineSeparator }} {...styles.lineSeparator} />
            <View>
              <View {...styles.processIndividualStep}>
                <Image source={{ uri: setupPin }} {...styles.processImage} />
                <View ml='3'>
                  <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                    {t("activation.step", { stepNo: 2 })}
                  </Text>
                  <Text
                    {...styles.processStepName}
                    fontFamily={theme.fontFamily}
                  >
                    {t("activation.enterOtpAndDob")}
                  </Text>
                </View>
              </View>
            </View>
            <Image source={{ uri: lineSeparator }} {...styles.lineSeparator} />
            <View>
              <View {...styles.processIndividualStep}>
                <Image source={{ uri: setupPin }} {...styles.processImage} />
                <View ml='3'>
                  <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                    {t("activation.step", { stepNo: 3 })}
                  </Text>
                  <Text
                    {...styles.processStepName}
                    fontFamily={theme.fontFamily}
                  >
                    {t("activation.pinSetup")}
                  </Text>
                </View>
              </View>
            </View>
            <Image source={{ uri: lineSeparator }} {...styles.lineSeparator} />
            <View>
              <View {...styles.processIndividualStep}>
                <Image
                  source={{ uri: cardActivated }}
                  {...styles.processImage}
                />
                <View ml='3'>
                  <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                    {t("activation.step", { stepNo: 4 })}
                  </Text>
                  <Text
                    {...styles.processStepName}
                    fontFamily={theme.fontFamily}
                  >
                    {t("activation.cardActivatedText")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View {...styles.buttonContainer}>
          <ColorButton
            isLoading={submitLoading}
            text={t("Otp.requestOTP")}
            onPress={() => getResetPinWidget()}
          />
        </View>
      </View>
    </ScrollView>
  )
}

export default ContextSetting
