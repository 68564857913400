import { BottomSheet } from "react-spring-bottom-sheet"
import crossIcon from "../../assets/images/v3/closeBtn.svg"
import UserIdSvg from "../svg/UserIdSvg"
import IncomingCallSvg from "../svg/IncomingCallSvg"
import { ColorButton } from "../core/buttons"
import { useState, useEffect, useContext } from "react"
import { Input } from "../../nativeBaseReplacements"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { useToast } from "../../nativeBaseReplacements/useToast"
import ApiIds from "../../auth/ApiIds"
import { handleApiAuth } from "../../utils/auth"
import { useBottomSheet } from "./BottomSheetContext"
import { BottomSheetType } from "../../utils/enums"
import { useTranslation } from "react-i18next"
import AccountService from "../../services/AccountService"
import AuthService from "../../services/AuthService"
import CommonService from "../../services/CommonService"
import { setAfaDetails, setDeviceId } from "../../store/actions/Auth"
import { setSession } from "../../store/actions/Session"
import { multicardAccounts, setUser } from "../../store/actions/User"
import { setCommonState } from "../../store/actions/Common"
import CryptoJs from "crypto-js"
import { ErrorContext } from "./ErrorScreenContext"
import { consoleError } from "../../utils/functions"
import { ErrorType } from "../../utils/constants"

const LoginJourneyBottomSheet_v3 = ({ isOpen, onClose, configs }) => {
  const [step, setStep] = useState(1)
  const [customerRefId, setCustomerRefId] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [error, setError] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const bottomSheetContext = useBottomSheet()
  const toast = useToast()
  const authSteps = configs?.configs?.authSteps
  const { t } = useTranslation()
  const { setErrorState } = useContext(ErrorContext)

  const generateSteps = () => {
    const steps = authSteps?.map(stepKey => {
      switch (stepKey) {
        case "userId":
          return {
            image: <UserIdSvg />,
            text: `${t("userId.userID")}`,
            description: `${t("userId.userDescription")}`,
            inputPlaceholder: `${t("userId.userPlaceholder")}`,
            validate: () => customerRefId.trim() !== "",
            value: customerRefId,
            onChange: setCustomerRefId,
          }
        case "mobileNumber":
          return {
            image: <IncomingCallSvg />,
            text: `${t("mobileNumber.verifyMobileNumber")}`,
            description: `${t("mobileNumber.mobileDescription")}`,
            inputPlaceholder: `${t("mobileNumber.mobilePlaceholder")}`,
            validate: () => /^\d{10}$/.test(mobileNumber),
            value: mobileNumber,
            onChange: setMobileNumber,
          }
        default:
          return null
      }
    })
    return steps?.filter(Boolean)
  }

  const steps = generateSteps() || []

  useEffect(() => {
    if (steps.length > 0 && steps[step - 1]?.validate) {
      setIsButtonDisabled(!steps[step - 1]?.validate())
    }
  }, [customerRefId, mobileNumber, step])

  const handleLoginProcess = async () => {
    if (step === steps.length) {
      const isValid = steps.every(s => s.validate())

      if (isValid) {
        try {
          const accID = await handleCustomerLookup({
            mobileNumber,
            customerRefId,
          })

          await handleSessionGeneration(accID)
        } catch (error) {
          if (!navigator.onLine) {
            setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
              handleLoginProcess(),
            )
          } else {
            console.error(error)
            setErrorState(ErrorType.INTERNAL_ERROR, () => handleLoginProcess())
          }
          setError(true)
        }
      } else {
        setError(true)
      }
    } else {
      setStep(step + 1)
    }
  }

  const handleCustomerLookup = async ({ mobileNumber, customerRefId }) => {
    try {
      const response = await AccountService.lookupCustomer({
        mobileNumber,
        customerRefId,
      })

      if (response?.status !== 200) {
        setErrorState(
          response?.status,
          () => handleCustomerLookup({ mobileNumber, customerRefId }),
          consoleError(response?.errors),
        )
      }

      const accounts = response?.data?.data?.customerAccounts[0]?.accounts

      dispatch(multicardAccounts({ multicardAccounts: accounts }))

      const accID = response?.data?.data?.customerAccounts[0]?.accounts[0]?.id

      localStorage.setItem("accountId", accID)

      return accID
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          handleCustomerLookup({ mobileNumber, customerRefId }),
        )
      } else {
        console.error(error)
        setErrorState(ErrorType.INTERNAL_ERROR, () =>
          handleCustomerLookup({ mobileNumber, customerRefId }),
        )
      }
    }
  }

  const handleSessionGeneration = async accID => {
    try {
      const sessionResponse = await AccountService.generateSession({
        accountId: accID,
      })

      if (sessionResponse?.status !== 200) {
        consoleError(sessionResponse?.errors)
        setErrorState(sessionResponse?.status, () =>
          handleSessionGeneration(accID),
        )
      }

      const payload = sessionResponse?.data?.data?.payload
      const sessionToken = payload?.token
      const tenantId = payload?.tenantId

      const response = await AuthService.getDeviceToken({
        sessionToken: sessionToken,
        tenantId: tenantId,
      })

      if (response?.status !== 200) {
        setErrorState(response?.status, () => handleSessionGeneration(accID))
        consoleError(response?.errors)
      }

      const result = response.data

      const deviceToken = result?.data?.deviceToken
      const encryptionKey = result.data.encryptionKey
      const ivLength = 16
      const ivString = encryptionKey.substring(0, ivLength)
      const encryptedKeyString = encryptionKey.slice(ivLength)

      const key = CryptoJs.enc.Base64.parse(sessionToken)
      const iv = CryptoJs.enc.Base64.parse(ivString)
      const encoder = CryptoJs.enc.Utf8
      const decryptedEncryptionKey = CryptoJs.AES.decrypt(
        encryptedKeyString,
        key,
        { iv },
      ).toString(encoder)

      dispatch(
        setSession(
          {
            sessionToken: sessionToken,
            deviceToken: deviceToken,
            encryptionKey: decryptedEncryptionKey,
            encryptionIv: ivString,
          },
          true,
        ),
      )

      const sessionInfoPromise = await CommonService.getSessionInfo()

      const sessionInfoResult = sessionInfoPromise?.data

      dispatch(
        setUser({
          customer: sessionInfoResult.data?.customer,
          cards: sessionInfoResult.data?.cards,
          account: sessionInfoResult.data?.account,
          enablePciWidget: sessionInfoResult.data?.enablePciWidget,
        }),
      )

      await handleAfaDetails()

      await handleApiAuth({
        apiId: ApiIds.PWA_LOGIN,
        flowId: "1",
        onAuthSuccess: () =>
          onPWALoginAuthSuccess(
            sessionToken,
            tenantId,
            deviceToken,
            decryptedEncryptionKey,
            ivString,
          ),
        onAuthFailure: onAuthFailure,
        onAuthCancel: () => {
          console.log("cancelled")
        },
        otpReason: "",
        mpinReason: "",
        toast,
        navigate: "",
        version: "v3",
        pageNavigation: "",
        bottomSheetContext: bottomSheetContext,
      })
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          handleSessionGeneration(accID),
        )
      } else {
        console.error(error)
        setErrorState(ErrorType.INTERNAL_ERROR, () =>
          handleSessionGeneration(accID),
        )
      }
    }
  }

  const handleAfaDetails = async () => {
    try {
      const afaResponse = await CommonService.getAfaDetails({
        mpinMetaData: {
          customerId: null,
        },
        apiTags: Object.values(ApiIds),
      })

      const afaResult = afaResponse.data

      if (afaResult?.success) {
        dispatch(
          setAfaDetails({
            afaDetails: {
              config: afaResult.data,
            },
          }),
        )
      } else {
        console.error(afaResult?.errors)
        setError(true)
        setErrorState(afaResponse?.status, () => handleAfaDetails())
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => handleAfaDetails())
      } else {
        console.error(error)
        setError(true)
        setErrorState(ErrorType.INTERNAL_ERROR, () => handleAfaDetails())
      }
    }
  }

  const onPWALoginAuthSuccess = (
    sessionToken,
    tenantId,
    deviceToken,
    encryptionKey,
    encryptionIv,
  ) => {
    localStorage.setItem("sessionToken", sessionToken)
    localStorage.setItem("deviceToken", deviceToken)
    localStorage.setItem("encryptionKey", encryptionKey)
    localStorage.setItem("encryptionIv", encryptionIv)
    navigate("/")
    dispatch(setCommonState({ isStandaloneFlow: true }))
    setTimeout(() => {
      localStorage.setItem("standAloneJourney", true)
      dispatch(setCommonState({ standAloneLoginScreen: false }))
    }, 0)
  }

  const onAuthFailure = (error, message) => {
    console.error("Authentication failure:", error)
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("CardDetailsWidgetV3.BottomSheetFailureMsg"),
      t("CardDetailsWidgetV3.cardDetailsFetchErrorMsg"),
      null,
      null,
      () => handleLoginProcess(),
    )
  }

  const renderStepContent = () => {
    const currentStep = steps?.[step - 1]
    if (!currentStep) return null

    return (
      <>
        <div className='v3-login-journey-bottomSheet-body-title'>
          <div className='v3-login-journey-bottomSheet-body-title-logo'>
            {currentStep.image}
          </div>
          <div className='v3-login-journey-bottomSheet-body-title-text'>
            {currentStep.text}
          </div>
        </div>
        <div className='v3-login-journey-bottomSheet-body-description'>
          {currentStep.description}
        </div>
        <Input
          value={currentStep.value}
          onChange={e => currentStep.onChange(e.target.value)}
          width='100%'
          fontSize={14}
          textAlign={"center"}
          backgroundColor={theme.v3.rawColors.leadingWhite}
          placeholder={currentStep.inputPlaceholder}
          placeholderTextColor={theme.v3.rawColors.tertiaryNeutral.color2}
          borderWidth='0px'
          borderBottomWidth={"1px"}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          py={3}
          my={2}
          focusOutlineColor={theme.v3.rawColors.primary.color1}
          borderBottomColor={
            error
              ? theme.v3.rawColors.tertiaryNegative.color1
              : isInputFocused
                ? theme.v3.rawColors.primary.color1
                : theme.v3.rawColors.tertiaryNeutral.color2
          }
        />
      </>
    )
  }

  return (
    <div style={{ maxWidth: "500px" }}>
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <div
          className='v3-bottomDrawer-nudges-container'
          style={{ backgroundColor: "white", borderRadius: "16px" }}
        >
          <div className='v3-login-journey-bottomSheet-header'>
            <div style={{ width: "10%" }}></div>
            <div className='v3-login-journey-bottomSheet-title-container'>
              <div className='v3-login-journey-bottomSheet-title'>
                {t("Otp.loginSteps")} {step}/{steps?.length}
              </div>
            </div>
            <span
              className='v3-bottomDrawer-nudges-close-btn'
              onClick={onClose}
            >
              <img src={crossIcon} alt='close' />
            </span>
          </div>

          <div className='v3-login-journey-bottomSheet-body'>
            {renderStepContent()}
          </div>

          <div
            className='v3-mpinIntro-cta-container'
            style={{ padding: "2% 0" }}
          >
            <div style={{ width: "100%" }}>
              <ColorButton
                mx='auto'
                text={step === steps?.length ? "Submit" : "Next"}
                width='100%'
                onPress={handleLoginProcess}
                isDisable={isButtonDisabled}
              />
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  )
}

export default LoginJourneyBottomSheet_v3
