import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { programConfig } from "../../../../../utils/tempData"
import { captureEvents, hexToRGBA } from "../../../../../utils/functions"
import { EventName } from "../../../../../utils/enums"
import { Header } from "../../../../core"
import PatternLeft from "../../../../../assets/images/v3/aboutCardPattern1.svg"
import PatternRight from "../../../../../assets/images/v3/aboutCardPattern2.svg"
import ShadowLeft from "../../../../../assets/images/v3/aboutCardShadow1.svg"
import ShadowRight from "../../../../../assets/images/v3/aboutCardShadow2.svg"
import InfoCard from "../../../../core/InfoCard/v3/InfoCard"
import Divider from "../../../../core/Divider/v3/Divider"
import RewardPointsCard from "./RewardPointsCard"
import MoreToKnowCard from "./MoreToKnowCard"
import SpotlightLeft from "../../../../../assets/images/v3/spotlightLeft.svg"
import SpotlightRight from "../../../../../assets/images/v3/spotlightRight.svg"
import Ellipse from "../../../../../assets/images/v3/spotEllipse.svg"
import FavouriteMerchantsCarousel from "./FavouriteMerchantsCarousel"
import HorizontalCard from "../../../../../assets/images/v3/horizontalCard.svg"
import VerticalCard from "../../../../../assets/images/v3/VerticalCard.svg"
import hfLogo from "../../../../../assets/images/v3/active/hfLogo.svg"

const AboutCard_v3 = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)
  const [cvpSections, setCvpSections] = useState({
    card_v1: screen?.aboutProgram?.cardV1,
    list_v1: screen?.aboutProgram?.listV1,
    card_v2: screen?.aboutProgram?.cardV2,
    list_v2: screen?.aboutProgram?.listV2,
  })
  const abountCardContainerRef = useRef(null)

  const cvps = screen?.aboutProgram?.cvp

  const brandLogos = screen?.aboutProgram?.aboutCardOtherSection?.marqueeUrl

  const isMarqueeEnabled =
    screen?.aboutProgram?.aboutCardOtherSection?.isMarqueeEnabled

  useEffect(() => {
    setCvpSections({
      card_v1: mapCVPs(cvpSections.card_v1, cvps, "cardV1"),
      card_v2: mapCVPs(cvpSections.card_v2, cvps, "cardV2"),
      list_v1: mapCVPs(cvpSections.list_v1, cvps, "listV1"),
      list_v2: mapCVPs(cvpSections.list_v2, cvps, "listV2"),
    })
  }, [cvps])

  useEffect(() => {
    captureEvents({ eventName: EventName.ABOUT_PROGRAM, metadata: {} })
    const element = abountCardContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  const mapCVPs = (cvpSection, cvps, type) => {
    const items = cvps.filter(cvp => cvp.type === type)
    return { header: cvpSection?.header, isEnabled: items.length > 0, items }
  }

  const onCardV1Click = details => {
    if (details?.redirectionUrl !== null && details?.redirectionUrl)
      window.location.href = details?.redirectionUrl
  }

  const getFavouriteBrandsContainerHeight = () => {
    if (brandLogos?.length < 7) {
      return "279px"
    } else {
      return "377px"
    }
  }

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: theme?.v3.cssVars.leadingWhite,
      }}
      ref={abountCardContainerRef}
      className='aboutCard-container'
    >
      <Header
        text={t("statements.aboutCard")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='v3-aboutCard-card-section'>
        <img
          src={ShadowLeft}
          className='v3-aboutCard-card-shadowLeft'
          alt='shadow'
        />
        <img
          src={ShadowRight}
          className='v3-aboutCard-card-shadowRight'
          alt='shadow'
        />
        <img
          src={PatternLeft}
          className='v3-aboutCard-card-patternLeft'
          alt='pattern'
        />
        <img
          src={PatternRight}
          className='v3-aboutCard-card-patternRight'
          alt='pattern'
        />
        <div
          className='v3-aboutCard-card-text'
          style={{ color: theme.v3.cssVars.primaryBase.color2 }}
        >
          <div>{t("manageCard.explorePerksText")}</div>
          <div style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}>
            {screen?.aboutProgram?.description}
          </div>
        </div>
        <img
          src={
            theme?.cardDesignUrl ||
            (theme.cardOrientation === "VERTICAL"
              ? VerticalCard
              : HorizontalCard)
          }
          className={`v3-aboutCard-card-cardImage ${theme.cardOrientation === "VERTICAL" ? "v3-aboutCard-card-verticalCardImage" : ""}`}
          alt='card'
        />
      </div>
      {(screen?.aboutProgram?.cardV1?.isEnabled ||
        screen?.aboutProgram?.listV1?.isEnabled) && (
        <div className='v3-aboutCard-benefits'>
          {screen?.aboutProgram?.cardV1?.isEnabled && (
            <div className='v3-aboutCard-benefits-cvp'>
              <div style={{ color: theme.v3.cssVars.primaryBase.color1 }}>
                {screen?.aboutProgram?.cardV1?.header}
              </div>
              <div>
                {cvpSections?.card_v1?.items?.map((benefit, index) => {
                  return (
                    <InfoCard
                      details={benefit}
                      key={index}
                      onCardClick={onCardV1Click}
                    />
                  )
                })}
              </div>
            </div>
          )}

          {screen?.aboutProgram?.listV1?.isEnabled && (
            <>
              <Divider showCenterMarker />
              <div className='v3-aboutCard-welcomeBenefits'>
                <div style={{ color: theme.v3.cssVars.primaryBase.color1 }}>
                  {screen?.aboutProgram?.listV1?.header}
                </div>
                <div className='v3-aboutCard-welcomeBenefits-container'>
                  {cvpSections?.list_v1?.items?.map((benefit, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <img src={benefit?.imageUrl} alt='logo' />
                        </div>
                        <div className='v3-aboutCard-welcomeBenefit-benefitText'>
                          <div
                            style={{
                              color: theme.v3.cssVars.primaryBase.color1,
                            }}
                          >
                            {benefit?.title}
                          </div>
                          <div
                            style={{
                              color: theme.v3.cssVars.primaryBase.color2,
                            }}
                          >
                            {benefit?.desc}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {isMarqueeEnabled && brandLogos && (
        <div
          className='v3-aboutCard-favouriteBrands'
          style={{
            background: theme.v3.cssVars.primary.color3,
            minHeight: getFavouriteBrandsContainerHeight(),
          }}
        >
          <img
            src={SpotlightLeft}
            alt='Spotlight-Left'
            className='v3-aboutCard-favouriteBrands-spotlightLeft'
          />
          <img
            src={SpotlightRight}
            alt='Spotlight-Right'
            className='v3-aboutCard-favouriteBrands-spotlightRight'
          />
          <div className='v3-aboutCard-favouriteBrands-bottomCircle'></div>
          <div
            className='v3-aboutCard-favouriteBrands-title'
            style={{ color: theme?.v3.cssVars.primary.color1 }}
          >
            {screen?.aboutProgram?.aboutCardOtherSection?.sectionTitle}
          </div>
          <div
            className='v3-aboutCard-favouriteBrands-description'
            style={{
              backgroundColor:
                screen?.aboutProgram?.aboutCardOtherSection
                  ?.subtitleBackgroundColor,
            }}
          >
            <div
              style={{
                background:
                  screen?.aboutProgram?.aboutCardOtherSection
                    ?.subtitleTextColor,
                backgroundClip: "text",
                webkitBackgroundClip: "text",
                webkitTextFillColor: "transparent",
              }}
            >
              {screen?.aboutProgram?.aboutCardOtherSection?.sectionSubtitle}
            </div>
          </div>
          <div
            className='v3-aboutCard-favouriteBrands-carousel'
            style={{ overflow: "hidden" }}
          >
            <FavouriteMerchantsCarousel
              Merchants={
                brandLogos?.length < 7
                  ? brandLogos
                  : brandLogos?.slice(0, Math.ceil(brandLogos?.length / 2))
              }
              gutter={true}
            />
          </div>
          {brandLogos?.length >= 7 && (
            <div
              className='v3-aboutCard-favouriteBrands-carousel'
              style={{ marginTop: "-25px", overflow: "hidden" }}
            >
              <FavouriteMerchantsCarousel
                Merchants={brandLogos?.slice(
                  Math.ceil(brandLogos?.length / 2),
                  brandLogos?.length,
                )}
              />
            </div>
          )}
        </div>
      )}
      <div className='v3-aboutCard-bottomContainer'>
        {screen?.aboutProgram?.cardV2?.isEnabled && (
          <div
            className='v3-aboutCard-lifetimeRewardPoints'
            style={{ color: theme.v3.cssVars.primaryBase.color1 }}
          >
            <div>{screen?.aboutProgram?.cardV2?.header}</div>
            <div>
              {cvpSections?.card_v2?.items?.map((benefit, index) => {
                return <RewardPointsCard details={benefit} key={index} />
              })}
            </div>
          </div>
        )}

        {screen?.aboutProgram?.listV2?.isEnabled && (
          <>
            <Divider showCenterMarker />
            <div
              className='v3-aboutCard-moreToKnow'
              style={{ color: theme.v3.cssVars.primaryBase.color1 }}
            >
              <div>{screen?.aboutProgram?.listV2?.header}</div>
              <div>
                {cvpSections?.list_v2?.items?.map((element, index) => {
                  return (
                    <>
                      <MoreToKnowCard key={index} details={element} />
                      {index < cvpSections?.list_v2?.items.length - 1 && (
                        <Divider />
                      )}
                    </>
                  )
                })}
              </div>
            </div>
          </>
        )}
      </div>
      {screen?.preLoader?.isHfBrandingEnabled && (
        <div className='v3-aboutCard-hf-branding'>
          <div className='v3-home-hf-branding-text'>
            {t("loader.poweredBy")}
          </div>
          <div className='v3-home-hf-branding-logo'>
            <img src={hfLogo} />
          </div>
        </div>
      )}
    </div>
  )
}

export default AboutCard_v3
