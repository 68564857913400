import { useSelector } from "react-redux"
import { Header } from "../../../core/headers"
import { useNavigate } from "react-router-dom"
import ArrowDown from "../../../Rewards/v3/DownArrow"
import ArrowRight from "../../../svg/v3/arrowRight"
import { NudgeContentType } from "../../../../utils/constants"
import { useEffect, useState } from "react"
import CubeAppLoader from "../../InAppLoader"
import { nudgeVariants } from "../../../../utils/enums"
import { useTranslation } from "react-i18next"

const AllNudges = () => {
  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  const allNudges = session.nudges
  const navigate = useNavigate()
  const [allPillNudges, setAllPillNudges] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    allNudges.sort(
      (a, b) =>
        a?.displayOptions?.displayOrder - b?.displayOptions?.displayOrder,
    )

    let filteredPillNudges = allNudges?.filter(nudge => {
      const nudgeDetails = JSON.parse(
        nudge?.displayOptions?.nudgeDetails || "{}",
      )
      return nudgeDetails?.variant === nudgeVariants.PILL
    })

    setAllPillNudges(filteredPillNudges)

    setIsLoading(false)
  }, [])

  const getColorsV3 = (color, comp) => {
    if (color && color !== "") {
      return color
    } else {
      switch (comp) {
        case NudgeContentType.SUBTITLE:
          return theme.v3.rawColors.primaryBase.color2
        case NudgeContentType.BACKGROUND:
          return theme.v3.rawColors.primary.color5
        default:
          return theme.v3.rawColors.primary.color1
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <CubeAppLoader />
      ) : (
        <div>
          <Header onBack={() => navigate("/")} />
          <div className='v3-all-nudges-head'>
            <div onClick={() => window.history.go(-1)}>
              <ArrowDown />
            </div>
            <div className='v3-all-nudges-head-text'>
              {t("messages.allNotifications")}
            </div>
          </div>
          <div className='v3-all-nudges-container'>
            {allPillNudges.map((item, index) => {
              return (
                <div className='v3-all-nudges-card' key={index}>
                  <div id={item.id} className='v3-nudge-smartnudges-nudge-icon'>
                    <img
                      id={item.id}
                      src={
                        JSON.parse(item?.displayOptions?.nudgeDetails)
                          ?.illustrationUrl
                      }
                    />
                  </div>
                  <div
                    id={item.id}
                    className='nudge-smartnudges-nudge-nudgeTitleDescriptionContainer'
                  >
                    <div
                      id={item.id}
                      style={{
                        color: getColorsV3(
                          JSON.parse(item?.displayOptions?.nudgeDetails)
                            ?.titleColor,
                          NudgeContentType.TITLE,
                        ),
                      }}
                      className='nudge-smartnudges-nudge-nudgeTitle'
                    >
                      {JSON.parse(item?.displayOptions?.nudgeDetails)?.title}
                    </div>
                    <div
                      id={item.id}
                      style={{
                        color: getColorsV3(
                          JSON.parse(item?.displayOptions?.nudgeDetails)
                            ?.subtitleColor,
                          NudgeContentType.SUBTITLE,
                        ),
                      }}
                      className='nudge-smartnudges-nudge-nudgeDescription'
                    >
                      {
                        JSON.parse(item?.displayOptions?.nudgeDetails)
                          ?.description
                      }
                    </div>
                  </div>
                  <div
                    className='v3-nudge-smartnudges-nudge-arrow'
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                    }}
                  >
                    <ArrowRight
                      color={getColorsV3(
                        JSON.parse(item?.displayOptions?.nudgeDetails)
                          ?.titleColor,
                        NudgeContentType.ARROW,
                      )}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default AllNudges
