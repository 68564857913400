import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ColorButton } from "../../../core/buttons"
import { captureEvents } from "../../../../utils/functions"
import { EventName, PwaVersions } from "../../../../utils/enums"
import { useNavigate } from "react-router-dom"
import SuccessIcon from "../../../../assets/images/v3/Ellipse_new.svg"
import successCard from "../../../../assets/images/v3/successCard.svg"
import SuccessCard from "./SuccessCard"
import ticksvg from "../../../../assets/images/v3/badgeCheck.svg"
import { Header } from "../../../core"
import cardReady from "../../../svg/v3/cardReady.json"
import { useLottie } from "lottie-react"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import { useTranslation } from "react-i18next"

const SetMpinSuccess_v3 = ({ timeoutCallback, timeoutDuration = 6000 }) => {
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const { t } = useTranslation()

  const options = {
    loop: false,
    autoplay: true,
    animationData: cardReady,
    setSpeed: 0.4,
  }
  const { View, play, stop, pause, setSpeed } = useLottie(options, {
    height: 567,
    width: 567,
    maxWidth: useWindowDimensions().width,
  })

  useEffect(() => {
    setTimeout(() => {
      timeoutCallback()
      return
    }, timeoutDuration)
    setSpeed(1.5)
    play()
    setTimeout(() => {
      pause()
    }, 390)
  }, [])

  return (
    <>
      <Header />
      <div className='v3-mpinSuccess'>
        <div className='v3-mpin-success-card'>
          <SuccessCard
            color={theme.v3.cssVars.primary.color1}
            className='v3-mpinSuccess-card-img'
          />
        </div>
        <div className='v3-mpinSuccess-content-container'>
          <div className='v3-mpinSuccess-content-background'>{View}</div>
          <div className='v3-mpinSuccess-content-text-1'>
            {`${user.customer.firstName}, `}
            <br></br>
            {t("messages.yourSpaceIsReady")}
          </div>
        </div>
      </div>
    </>
  )
}

export default SetMpinSuccess_v3
