import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Header } from "../../../core"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import Cross from "../../../svg/v3/Cross"
import MerchantCard from "./merchantCard"
import ArrowRight from "../../../svg/v3/arrowRight"
import TokenisationArrowDown from "../../../svg/v3/tokenisation_arrow_right"
import Divider from "../../../core/Divider/v3/Divider"
import TokenisationLock from "../../../svg/v3/tokenisationLock"
import { useNavigate } from "react-router-dom"
import {
  BottomSheetType,
  MERCHANT_STATUS,
  MERCHANT_TYPE_LABELS,
  STANDALONE_FLOWS,
} from "../../../../utils/enums"
import MerchantBottomSheet from "./merchantBottomSheet"
import CardService from "../../../../services/CardService"
import { useBottomSheet } from "../../../auth/BottomSheetContext"
import { useTranslation } from "react-i18next"
import hfLogoColored from "../../../../assets/images/v3/active/hfLogoColored.svg"

const SavedCards = () => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const navigate = useNavigate()
  const [activeMerchants, setActiveMerchants] = useState([])
  const [pausedMerchants, setPausedMerchants] = useState([])
  const [requestedMerchant, setRequestedMerchant] = useState([])

  const [openedMerchant, setOpenMerchant] = useState(MERCHANT_STATUS.ACTIVE)
  const [showAllActive, setShowAllActive] = useState(false)
  const [showAllPaused, setShowAllPaused] = useState(false)
  const [showAllRequested, setShowAllRequested] = useState(false)
  const [selectedMerchant, setSelectedMerchant] = useState({})
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [cardProvisioned, setCardProvisioned] = useState(false)
  const bottomSheetContext = useBottomSheet()
  const { t } = useTranslation()

  useEffect(() => {
    ;(async function () {
      try {
        let data = {
          cardId: user.cards[0].id,
        }
        const response = await CardService.fetchTokenizationSummary(null, data)
        let activeMerchantTemp = []
        let pauseMercahntTemp = []
        let requestedMerchantTemp = []

        if (response.status == 200) {
          let allMerchants = response.data.data.data || []
          if (!allMerchants || allMerchants.length == 0) {
            navigate("/ManageCard/Cards/Saved/Empty", { replace: true })
            return
          }
          allMerchants.forEach(merchant => {
            if (merchant.status == MERCHANT_STATUS.ACTIVE) {
              activeMerchantTemp.push(merchant)
            }
            if (merchant.status == MERCHANT_STATUS.SUSPENDED) {
              pauseMercahntTemp.push(merchant)
            }
            if (
              merchant.status == MERCHANT_STATUS.FAILED ||
              merchant.status == MERCHANT_STATUS.PROCESSING
            ) {
              requestedMerchantTemp.push(merchant)
            }
          })
          setActiveMerchants(activeMerchantTemp)
          setPausedMerchants(pauseMercahntTemp)
          setRequestedMerchant(requestedMerchantTemp)
        } else {
          handleFailureState()
        }
      } catch (err) {
        handleFailureState()
      }
    })()
  }, [cardProvisioned])

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("merchant.CouldnotFetchSavedCards"),
      "",
      null,
      null,
      () => setCardProvisioned(!cardProvisioned),
    )
  }

  const handleMerchantAccordianClick = clickedMerchantType => {
    if (openedMerchant === clickedMerchantType)
      setOpenMerchant(MERCHANT_STATUS.NULL)
    else setOpenMerchant(clickedMerchantType)
  }

  const handleShowAllToggle = merchantType => {
    if (merchantType === MERCHANT_STATUS.ACTIVE) {
      setShowAllActive(!showAllActive)
    } else if (merchantType === MERCHANT_STATUS.SUSPENDED) {
      setShowAllPaused(!showAllPaused)
    } else if (merchantType == "REQUESTED") {
      setShowAllRequested(!showAllRequested)
    }
  }

  const handleMerchantCardClick = merchant => {
    setSelectedMerchant(merchant)
    setIsBottomSheetOpen(true)
  }

  const getMerchantCardData = merchant => {
    return (
      <MerchantCard
        key={merchant.merchantId}
        merchantType={merchant.status}
        merchant={merchant}
        onMerchantCardClick={() => handleMerchantCardClick(merchant)}
      />
    )
  }
  const getMerchantCard = (merchantList, merchantType, showDivider = true) => {
    const showAll =
      merchantType === MERCHANT_STATUS.ACTIVE
        ? showAllActive
        : merchantType === MERCHANT_STATUS.SUSPENDED
          ? showAllPaused
          : showAllRequested

    const merchantsToShow = showAll ? merchantList : merchantList.slice(0, 4)

    return (
      <>
        {merchantList && merchantList.length > 0 && (
          <>
            <div>
              <div
                className='v3-manage-cards-active-title'
                style={{
                  marginBottom: openedMerchant === merchantType ? "0px" : "8px",
                }}
              >
                <div className='v3-manage-cards-active-title-text'>
                  {MERCHANT_TYPE_LABELS[merchantType]} Merchants (
                  {merchantList.length})
                </div>

                <div
                  className='v3-manage-cards-active-title-arrow-container'
                  onClick={() => {
                    handleMerchantAccordianClick(merchantType)
                  }}
                >
                  <TokenisationArrowDown
                    color={theme.v3.cssVars.tertiaryNeutral.color1}
                  />
                </div>
              </div>

              {openedMerchant === merchantType && merchantList && (
                <div className='v3-manage-cards-merchant-list-container'>
                  {merchantsToShow.map(
                    merchant =>
                      ((merchantType == "REQUESTED" &&
                        merchant.status == MERCHANT_STATUS.FAILED) ||
                        merchantType != "REQUESTED") &&
                      getMerchantCardData(merchant),
                  )}
                </div>
              )}

              {openedMerchant === merchantType && merchantList && (
                <div className='v3-manage-cards-merchant-list-container'>
                  {merchantsToShow.map(
                    merchant =>
                      merchantType == "REQUESTED" &&
                      merchant.status == MERCHANT_STATUS.PROCESSING &&
                      getMerchantCardData(merchant),
                  )}
                </div>
              )}

              {merchantList.length > 4 && openedMerchant === merchantType && (
                <div
                  className='v3-manage-cards-show-all'
                  onClick={() => handleShowAllToggle(merchantType)}
                >
                  {showAll
                    ? `${t("merchant.hideAllMerchants", {
                        merchantType: MERCHANT_TYPE_LABELS[merchantType],
                      })}`
                    : `${t("merchant.showAllMerchants", {
                        merchantType: MERCHANT_TYPE_LABELS[merchantType],
                      })}`}
                </div>
              )}
            </div>

            {showDivider && (
              <div
                style={{
                  paddingTop: openedMerchant === merchantType ? "0px" : "16px",
                }}
                className='v3-manage-cards-divider'
              >
                <Divider />
              </div>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <div>
      <Header
        text={t("merchant.manageSavedCards")}
        onBack={async () => {
          if (localStorage.getItem("flow") == STANDALONE_FLOWS.TOKENIZATION) {
            window.location.href = localStorage.getItem("redirectUrl")
          } else window.history.go(-1)
        }}
      />

      <div className='tokenisation-saved-card-merchant-container'>
        {getMerchantCard(activeMerchants, MERCHANT_STATUS.ACTIVE)}
        {getMerchantCard(pausedMerchants, MERCHANT_STATUS.SUSPENDED)}
        {/* {getMerchantCard(requestedMerchant, MERCHANT_STATUS.FAILED)} */}
        {getMerchantCard(requestedMerchant, "REQUESTED", false)}
      </div>

      <div className='v3-tokenisation-hf-branding-container'>
        <div className='v3-tokenization-hf-branding'>
          <div className='v3-home-hf-branding-text v3-tokenization-hf-branding-text  '>
            {t("loader.poweredBy")}
          </div>
          <div className='v3-home-hf-branding-logo'>
            <img src={hfLogoColored} />
          </div>
        </div>
      </div>

      <div className='tokenisation-saved-card-cta-container'>
        <div className='tokenisation-saved-card-cta-container-div'>
          <div className='tokenisation-saved-card-cta-icon'>
            <TokenisationLock
              color1={theme.v3.cssVars.primary.color1}
              color2={theme.v3.cssVars.primary.color3}
            />
          </div>

          <div className='tokenisation-saved-card-cta-text'>
            {t("merchant.saveYourCardMerchant")}
          </div>
        </div>
        <ColorButton
          text={t("merchant.AddCardToMerchantApp")}
          margin='auto'
          onPress={() => navigate("/ManageCard/Cards/Add")}
          primaryColor={theme.v3.rawColors.secondary.color1}
          color={theme.v3.rawColors.leadingWhite}
          width='100%'
        />
      </div>

      <MerchantBottomSheet
        isOpen={isBottomSheetOpen}
        merchant={selectedMerchant}
        setCardProvisioned={setCardProvisioned}
        cardProvisioned={cardProvisioned}
        onClose={() => setIsBottomSheetOpen(false)}
      />
    </div>
  )
}

export default SavedCards
