import React, { useState, useRef } from "react"

const CustomCarousel = ({ offers }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [startX, setStartX] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const carouselRef = useRef(null)

  const handleTouchStart = e => {
    setStartX(e.touches[0].clientX)
    setIsDragging(true)
  }

  const handleTouchEnd = e => {
    if (!isDragging) return
    const endX = e.changedTouches[0].clientX
    handleSwipe(endX - startX)
    setIsDragging(false)
  }

  const handleMouseDown = e => {
    setStartX(e.clientX)
    setIsDragging(true)
  }

  const handleMouseUp = e => {
    if (!isDragging) return
    handleSwipe(e.clientX - startX)
    setIsDragging(false)
  }

  const handleSwipe = distance => {
    const threshold = 50

    if (distance > threshold) {
      prevSlide()
    } else if (distance < -threshold) {
      nextSlide()
    }
  }

  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % offers.length)
  }

  const prevSlide = () => {
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + offers.length) % offers.length,
    )
  }

  const goToSlide = index => {
    setCurrentIndex(index)
  }

  return (
    <div
      className='carousel-container'
      ref={carouselRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div
        className='carousel-track'
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {offers.map((offer, index) => (
          <div key={index} className='v3-login-journey-offer-carousel'>
            <div className='v3-login-journey-offer-image'>
              <img
                src={offer.image}
                alt={`offer-${index}`}
                style={{ width: "100%", overflow: "hidden" }}
              />
            </div>
            <div className='v3-login-journey-offer-text'>{offer.text}</div>
          </div>
        ))}
      </div>

      <div className='carousel-dots'>
        {offers.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default CustomCarousel
