import { BottomSheet } from "react-spring-bottom-sheet"
import crossIcon from "../../../../assets/images/v3/closeBtn.svg"
import CalendarSvg from "../../../svg/CalenderSvg"
import CreditCardSvg from "../../../svg/CreditCardSvg"
import LinkSvg from "../../../svg/LinkSvg"
import PauseSvg from "../../../svg/PauseSvg"
import UndoTrashSvg from "../../../svg/UndoTrashSvg"
import CaretRightSvg from "../../../svg/CaretRightSvg"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import TriangleExclamationSvg from "../../../svg/TriangleExclamationSvg"
import { ColorButton } from "../../../core/buttons"
import { useEffect, useState } from "react"
import AlertIconRounded from "../../../svg/AlertIconRounded"
import {
  BottomSheetType,
  MERCHANT_STATUS,
  TOKENISATION_LIFECYCLE_TYPE,
  TokenisationOnusOffusIndicator,
} from "../../../../utils/enums"
import { handleApiAuth } from "../../../../utils/auth"
import ApiIds from "../../../../auth/ApiIds"
import CardService from "../../../../services/CardService"
import { useBottomSheet } from "../../../auth/BottomSheetContext"
import { useSelector } from "react-redux"
import moment from "moment"
import TokenisationUnpauseMerchant from "../../../svg/v3/tokenizationUnpauseMerchant"
import TokenisationProcessing from "../../../svg/v3/tokenisationProcessing"
import { useTranslation } from "react-i18next"

const MerchantBottomSheet = ({
  cardProvisioned,
  setCardProvisioned,
  isOpen,
  onClose,
  merchant = {},
}) => {
  const [openPauseBottomSheet, setOpenPauseBottomSheet] = useState(true)
  const bottomSheetContext = useBottomSheet()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const [currentActionType, setCurrentActiontype] = useState("")
  const { t } = useTranslation()

  const handlePauseMerchant = (merchant, lifecycleMethodType) => {
    setCurrentActiontype(lifecycleMethodType)
    setOpenPauseBottomSheet(false)
  }

  const handleClose = () => {
    onClose()
    setOpenPauseBottomSheet(true)
  }
  const renderContent = () => {
    switch (merchant?.status) {
      case MERCHANT_STATUS.FAILED:
        return (
          <>
            <div className='v3-MerchantBottomSheet-body'>
              <div className='v3-MerchantBottomSheet-body-line-container'>
                <div className='v3-MerchantBottomSheet-body-line-icon'>
                  <TriangleExclamationSvg />
                </div>
                <div
                  className='v3-MerchantBottomSheet-body-line'
                  style={{
                    color: "var(--System-Palette-negative-feedback-1, #E72B2B)",
                  }}
                >
                  {t("merchant.dueToRequestFailure")}
                </div>
              </div>
              {/* <div className='v3-MerchantBottomSheet-body-line-container'>
                <div className='v3-MerchantBottomSheet-body-line-icon'>
                  <CreditCardSvg />
                </div>
                <div className='v3-MerchantBottomSheet-body-line'>
                  on 13 Jun, 2024
                </div>
              </div> */}
            </div>
            <DividerV3 />
            <div className='v3-MerchantBottomSheet-footer-btn'>
              <ColorButton
                onPress={() => handleRetry()}
                text={t("PaymentGateway.retryNow")}
                width='100%'
              />
            </div>
          </>
        )
      case MERCHANT_STATUS.PROCESSING:
        return (
          <>
            <div className='v3-MerchantBottomSheet-body'>
              <div className='v3-MerchantBottomSheet-body-line-container'>
                <div className='v3-MerchantBottomSheet-body-line-icon'>
                  <TokenisationProcessing
                    color1={theme.v3.cssVars.tertiaryCaution.color1}
                    color2={theme.v3.cssVars.tertiaryCaution.color1}
                  />
                </div>

                <div className='tokenisation-merchant-card-inside-second-icon'>
                  <span
                    style={{ color: theme.v3.cssVars.tertiaryCaution.color1 }}
                    className='tokenisation-merchant-card-inside-second-text'
                  >
                    {t("merchant.processingText")}
                  </span>
                </div>
              </div>
            </div>
            <DividerV3 />
          </>
        )
      case MERCHANT_STATUS.ACTIVE:
      default:
        return (
          merchant?.onusOffusIndicator ==
            TokenisationOnusOffusIndicator.ONUS && (
            <div className='v3-MerchantBottomSheet-body'>
              <div className='v3-MerchantBottomSheet-body-line-container'>
                <div className='v3-MerchantBottomSheet-body-line-icon'>
                  <CalendarSvg />
                </div>
                <div className='v3-MerchantBottomSheet-body-line'>
                  {t("merchant.throughThisApp")}
                </div>
              </div>
              {merchant.createdOn && (
                <div className='v3-MerchantBottomSheet-body-line-container'>
                  <div className='v3-MerchantBottomSheet-body-line-icon'>
                    <CreditCardSvg />
                  </div>
                  <div className='v3-MerchantBottomSheet-body-line'>
                    {t("merchant.creationDate", {
                      date: moment(merchant.createdOn).format("DD MMM, YYYY"),
                    })}
                  </div>
                </div>
              )}
              {/* <div className='v3-MerchantBottomSheet-body-line-container'>
              <div className='v3-MerchantBottomSheet-body-line-icon'>
                <LinkSvg />
              </div>
              <div className='v3-MerchantBottomSheet-body-line'>
                account linked with{" "}
                <span className='v3-MerchantBottomSheet-body-line-email'>
                  ****rahul@gmail.com
                </span>
              </div>
            </div> */}
            </div>
          )
        )
    }
  }

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      LifecycleFailureMessage[currentActionType].title,
      "",
      null,
      null,
      () => handleLifecycleCta(merchant),
    )

    setTimeout(() => {
      handleClose()
    }, 2000)
  }

  const handleProvisionFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("merchant.unableToAddErrorText"),
      t("merchant.unsuccessfulAttempts"),
      null,
      null,
      () => handleRetry(merchant),
    )

    setTimeout(() => {
      handleClose()
    }, 2000)
  }

  const handleLifecycleCta = async merchant => {
    try {
      await handleApiAuth({
        apiId: ApiIds.TOKENIZATION_LIFECYCLE,
        onAuthSuccess: onLifecycleAuthSuccess,
        onAuthFailure: handleFailureState,
        onAuthCancel: handleFailureState,
        otpReason: t("merchant.forTokenisationProvision"),
        mpinReason: t("merchant.forTokenisationProvision"),
        version: config?.version,
        bottomSheetContext: bottomSheetContext,
      })
    } catch (err) {}
  }

  const onLifecycleAuthSuccess = async apiToken => {
    try {
      let data = {
        cardId: user.cards[0].id,
        operations: [
          {
            tokenId: merchant.tokenId,
            reason: "Detected fraud",
            type: currentActionType,
          },
        ],
      }
      const response = await CardService.tokenizationLifecycle(apiToken, data)

      if (
        response.status === 200 &&
        response.data.data.operations[0].status == "SUCCESS"
      ) {
        setCardProvisioned(!cardProvisioned)
        onClose()
        setOpenPauseBottomSheet(true)

        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          LifecycleSuccessMessage[currentActionType].title,
          ".",
          "/ManageCard/Cards/Saved",
        )
      } else {
        handleFailureState()
      }
    } catch (err) {
      handleFailureState()
    }
  }
  const handleRetry = () => {
    ;(async function () {
      try {
        await handleApiAuth({
          apiId: ApiIds.TOKENIZATION_PROVISION,
          onAuthSuccess: onProvisionAuthSuccess,
          onAuthFailure: handleFailureState,
          onAuthCancel: handleFailureState,
          otpReason: t("merchant.forTokenisationProvision"),
          mpinReason: t("merchant.forTokenisationProvision"),
          version: config?.version,
          bottomSheetContext: bottomSheetContext,
        })
      } catch (err) {}
    })()
  }

  const onProvisionAuthSuccess = async apiToken => {
    try {
      let data = {
        cardId: user.cards[0].id,
        merchantIds: [merchant.merchantId],
      }
      const response = await CardService.tokenizationProvision(apiToken, data)

      if (response.status === 200) {
        onClose()
        setOpenPauseBottomSheet(true)
        setCardProvisioned(!cardProvisioned)

        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("merchant.requestSendSuccess"),
          ".",
          "/ManageCard/Cards/Saved",
        )
        // handleFailureState()
      } else {
        handleProvisionFailureState()
      }
    } catch (err) {
      handleProvisionFailureState()
    }
  }

  const ConfirmationBottomSheet = {
    [TOKENISATION_LIFECYCLE_TYPE.DELETE]: {
      title: t("merchant.confirmationBottomSheetDeleteTitle"),
      description: t("merchant.confirmationBottomSheetDeleteDescription"),
      icon: "",
    },
    [TOKENISATION_LIFECYCLE_TYPE.SUSPEND]: {
      title: t("merchant.confirmationBottomSheetSuspendTitle"),
      description: t("merchant.confirmationBottomSheetSuspendDescription"),
      icon: "",
    },
    [TOKENISATION_LIFECYCLE_TYPE.RESUME]: {
      title: t("merchant.confirmationBottomSheetResumeTitle"),
      description: t("merchant.confirmationBottomSheetResumeDescription"),
      icon: "",
    },
  }

  const LifecycleSuccessMessage = {
    [TOKENISATION_LIFECYCLE_TYPE.DELETE]: {
      title: t("merchant.lifecycleSuccessMessageDeleteTitle"),
    },
    [TOKENISATION_LIFECYCLE_TYPE.SUSPEND]: {
      title: t("merchant.lifecycleSuccessMessageSuspendTitle", {
        merchantName: merchant.name,
      }),
    },
    [TOKENISATION_LIFECYCLE_TYPE.RESUME]: {
      title: t("merchant.lifecycleSuccessMessageResumeTitle", {
        merchantName: merchant.name,
      }),
    },
  }

  const LifecycleFailureMessage = {
    [TOKENISATION_LIFECYCLE_TYPE.DELETE]: {
      title: t("merchant.lifecycleFailureMessageDeleteTitle"),
    },
    [TOKENISATION_LIFECYCLE_TYPE.SUSPEND]: {
      title: t("merchant.lifecycleFailureMessageSuspendTitle", {
        merchantName: merchant.name,
      }),
    },
    [TOKENISATION_LIFECYCLE_TYPE.RESUME]: {
      title: t("merchant.lifecycleFailureMessageResumeTitle", {
        merchantName: merchant.name,
      }),
    },
  }

  return (
    <div style={{ maxWidth: "500px" }}>
      <BottomSheet open={isOpen} onDismiss={onClose}>
        {openPauseBottomSheet ? (
          <div
            className='v3-bottomDrawer-nudges-container'
            style={{ alignItems: "normal", paddingBottom: "16px" }}
          >
            <div className='v3-MerchantBottomSheet-header'>
              <div className='v3-MerchantBottomSheet-header-left'>
                <div className='v3-MerchantBottomSheet-header-img'>
                  <img src={`data:image/jpg;base64,${merchant?.base64Image}`} />
                </div>
                <div className='v3-MerchantBottomSheet-header-title'>
                  <div className='v3-MerchantBottomSheet-header-text1'>
                    {t("merchant.yourCardDetailsIsSavedWith")}
                  </div>
                  <div className='v3-MerchantBottomSheet-header-text2'>
                    {merchant?.name}
                  </div>
                </div>
              </div>
              <div
                className='v3-MerchantBottomSheet-header-right'
                onClick={handleClose}
              >
                <img src={crossIcon} />
              </div>
            </div>

            {renderContent()}

            {merchant?.status !== MERCHANT_STATUS.FAILED &&
              merchant?.status !== MERCHANT_STATUS.PROCESSING && (
                <div className='v3-MerchantBottomSheet-footer'>
                  <div className='v3-MerchantBottomSheet-footer-section'>
                    <div
                      style={{
                        backgroundColor:
                          merchant.status != MERCHANT_STATUS.ACTIVE
                            ? theme.v3.cssVars.primary.color5
                            : "",
                      }}
                      className='v3-MerchantBottomSheet-footer-section-icon'
                    >
                      {merchant.status == MERCHANT_STATUS.ACTIVE ? (
                        <PauseSvg />
                      ) : (
                        <TokenisationUnpauseMerchant
                          color={theme.v3.cssVars.primary.color1}
                        />
                      )}
                    </div>
                    <div className='v3-MerchantBottomSheet-footer-section-text-container'>
                      <div
                        style={{
                          color:
                            merchant.status != MERCHANT_STATUS.ACTIVE
                              ? theme.v3.cssVars.primary.color1
                              : "",
                        }}
                        className='v3-MerchantBottomSheet-footer-section-text'
                      >
                        {merchant.status == MERCHANT_STATUS.ACTIVE
                          ? t("merchant.pauseMerchant")
                          : t("merchant.unpauseMerchant")}
                      </div>
                      <div
                        className='v3-MerchantBottomSheet-footer-section-arrow'
                        onClick={() =>
                          handlePauseMerchant(
                            merchant,
                            merchant.status == MERCHANT_STATUS.ACTIVE
                              ? TOKENISATION_LIFECYCLE_TYPE.SUSPEND
                              : TOKENISATION_LIFECYCLE_TYPE.RESUME,
                          )
                        }
                      >
                        <CaretRightSvg />
                      </div>
                    </div>
                  </div>
                  <DividerV3 />

                  <div className='v3-MerchantBottomSheet-footer-section'>
                    <div className='v3-MerchantBottomSheet-footer-section-icon'>
                      <UndoTrashSvg />
                    </div>
                    <div className='v3-MerchantBottomSheet-footer-section-text-container'>
                      <div className='v3-MerchantBottomSheet-footer-section-text2'>
                        {t("merchant.removeMerchant")}
                      </div>
                      <div
                        onClick={() =>
                          handlePauseMerchant(
                            merchant,
                            TOKENISATION_LIFECYCLE_TYPE.DELETE,
                          )
                        }
                        className='v3-MerchantBottomSheet-footer-section-arrow'
                      >
                        <CaretRightSvg
                          color={
                            "var(--negative-feedback-1, var(--System-Palette-negative-feedback-1, #E72B2B))"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        ) : (
          <div
            className='v3-bottomDrawer-nudges-container'
            style={{ alignItems: "normal", paddingBottom: "16px" }}
          >
            <div className='v3-MerchantBottomSheet-header'>
              <div className='v3-MerchantBottomSheet-heading'>
                {ConfirmationBottomSheet[currentActionType].title}
              </div>
              <div
                className='v3-MerchantBottomSheet-header-right'
                onClick={handleClose}
              >
                <img src={crossIcon} />
              </div>
            </div>
            <div className='v3-MerchantBottomSheet-body2'>
              <div className='v3-MerchantBottomSheet-body2-icon'>
                <AlertIconRounded />
              </div>
              <div className='v3-MerchantBottomSheet-body2-text'>
                {ConfirmationBottomSheet[currentActionType].description}
              </div>
            </div>
            <DividerV3 />
            <div className='v3-MerchantBottomSheet-footer-btn'>
              <ColorButton
                onPress={() => handleLifecycleCta(merchant)}
                text={t("merchant.OkayGoAheadText")}
                width='100%'
              />
            </div>
          </div>
        )}
      </BottomSheet>
    </div>
  )
}

export default MerchantBottomSheet
