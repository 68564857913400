import React, { useContext, useState } from "react"
import crossIcon from "../../../../assets/images/v3/closeBtn.svg"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import customerTxnCatchAll from "../../../../assets/images/transactionLogos/customerTxnCatchAll.svg"
import {
  consoleError,
  copyToClipboard,
  formatAmount,
  formatToWholeCurrency,
} from "../../../../utils/functions"
import moment from "moment"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  EventName,
  transactionNature,
  transactionTypes,
} from "../../../../utils/enums"
import purchaseIcon from "../../../../assets/images/v3/PaylaterPurchase.svg"
import repaymentIcon from "../../../../assets/images/v3/PaylaterRepayment.svg"
import refundIcon from "../../../../assets/images/v3/PaylaterRefund.svg"
import CopyToClipboard from "../../../svg/v3/copyToClipboard"
import {
  ErrorType,
  payLaterTransactionStatus,
  transactionStatus,
} from "../../../../utils/constants"
import CreditImage from "../../../svg/v3/creditImage"
import DebitImage from "../../../svg/v3/debitImage"
import AccountService from "../../../../services/AccountService"
import { ErrorContext } from "../../../auth/ErrorScreenContext"
import CustomLoader from "../../../core/loader"

const Sidebar = ({ data, onClose, currentPage }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const theme = useSelector(state => state.theme)
  const handleClose = () => onClose()
  const accountDetails = user?.summary?.account
  const { setErrorState } = useContext(ErrorContext)

  const [isLoading, setIsLoading] = useState(false)

  const getTransactionIcon = transaction => {
    if (transaction?.txnType === transactionTypes.PURCHASE) return purchaseIcon
    else if (transaction?.txnType === transactionTypes.REFUND) return refundIcon
    else if (transaction?.txnType === transactionTypes.REPAYMENT)
      return repaymentIcon
  }

  const copyTransactionId = transaction => {
    copyToClipboard(transaction?.id || "")
  }

  const getTransactionStatus = transaction => {
    if (
      transaction?.txnStatus === transactionStatus.APPROVED ||
      transaction?.txnStatus === transactionStatus.PARTIALLY_SETTLED
    )
      return (
        <div className='v3-web-detail-value-due'>
          {payLaterTransactionStatus.DUE}
        </div>
      )
    else if (transaction?.txnStatus === transactionStatus.SETTLED)
      return (
        <div className='v3-web-detail-value-repaid'>
          {payLaterTransactionStatus.REPAID}
        </div>
      )
  }

  const handlePayNowClick = async () => {
    let outStandingAmount =
      Number(accountDetails?.approvedCreditLimit) -
      Number(accountDetails?.availableCreditLimit)
    if (outStandingAmount < 0) outStandingAmount = 0
    try {
      setIsLoading(true)
      const response =
        await AccountService.generatePaylaterRepaymentRedirectionUrl({
          accountId: accountDetails?.id,
          category: "REPAYMENT",
          sso: "REQUIRED",
          amount: outStandingAmount,
          metadata: {
            redirectionUrl: process.env.REACT_APP_URL,
          },
        })
      if (response.status === 200) {
        window.location.href = response.data.data.redirectUrl
        handleClose()
      } else {
        setErrorState(response?.status, () => handlePayNowClick())
        consoleError(response?.errors)
      }
    } catch (err) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => handlePayNowClick())
      } else {
        consoleError(err)
        setErrorState(ErrorType.INTERNAL_ERROR, () => handlePayNowClick())
      }
    } finally {
      setIsLoading(false)
    }
  }

  return currentPage === EventName.HOME ? (
    <div className='v3-web-transaction-sidebar-container'>
      <div className='v3-web-transaction-sidebar-header'>
        <div className='v3-web-transaction-sidebar-header-text'>
          {t("payLaterWebComponents.sideBar.cardLimitInfo")}
        </div>
        <div className='v3-web-transaction-close-btn' onClick={handleClose}>
          <img src={crossIcon} />
        </div>
      </div>
      <DividerV3 />
      <div className='v3-web-transaction-credit-info'>
        <p className='v3-web-transaction-available-credit-label'>
          {t("payLaterWebComponents.sideBar.availableCredit")}
        </p>
        <p className='v3-web-transaction-available-credit-amount'>
          <span className='v3-web-transaction-highlighted-amount'>
            {formatAmount(accountDetails?.availableCreditLimit)}
          </span>{" "}
          / {formatAmount(accountDetails?.approvedCreditLimit)}
        </p>
        <div className='v3-web-transaction-progress-bar'>
          <div
            className='v3-web-transaction-filled-bar'
            style={{
              width:
                (accountDetails?.availableCreditLimit /
                  accountDetails?.approvedCreditLimit) *
                  100 +
                "%",
            }}
          ></div>
        </div>
      </div>

      <DividerV3 marginBottom={"32px"} />
      <div className='v3-web-transaction-outstanding-info'>
        <div className='v3-web-transaction-outstanding-info-text'>
          {t("payLaterWebComponents.sideBar.totalOutstanding")}
        </div>
        <p className='v3-web-transaction-outstanding-amount'>
          {formatToWholeCurrency(
            Number(accountDetails?.approvedCreditLimit) -
              Number(accountDetails?.availableCreditLimit),
          )}
        </p>
      </div>

      <button
        className='v3-web-transaction-pay-now-btn'
        onClick={handlePayNowClick}
      >
        {isLoading ? (
          <CustomLoader />
        ) : (
          t("payLaterWebComponents.sideBar.payNow")
        )}
      </button>
    </div>
  ) : (
    <div className='v3-web-transaction-sidebar-container'>
      <div className='v3-web-transaction-sidebar-header'>
        <div className='v3-web-transaction-sidebar-header-txn-details'>
          <div className='v3-web-transaction-sidebar-header-txn-image'>
            <img src={getTransactionIcon(data) || customerTxnCatchAll} />
          </div>
          <div className='v3-web-transaction-sidebar-header-txn-type-and-details'>
            <div className='v3-web-transaction-sidebar-header-txn-type-text'>
              {data?.txnType}
            </div>
            <div className='v3-web-transaction-sidebar-header-txn-amount'>
              {formatAmount(data?.amount)}
              <div className='v3-transaction-bottomsheet-txn-nature-image'>
                {data.txnNature === transactionNature.CREDIT ? (
                  <CreditImage />
                ) : (
                  <DebitImage />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='v3-web-transaction-close-btn' onClick={handleClose}>
          <img src={crossIcon} alt='Close' />
        </div>
      </div>
      <DividerV3 />
      <div className='v3-web-transaction-details'>
        <div className='v3-web-transaction-detail'>
          <div className='v3-web-detail-label'>
            {t("payLaterWebComponents.sideBar.orderId")}
          </div>{" "}
          <div className='v3-web-detail-value'>{data?.id}</div>{" "}
          <span onClick={() => copyTransactionId(data)}>
            <CopyToClipboard width='16' height='16' />
          </span>
        </div>
        <div className='v3-web-transaction-detail'>
          <div className='v3-web-detail-label'>
            {t("payLaterWebComponents.sideBar.dateAndTime")}
          </div>{" "}
          <div className='v3-web-detail-value'>
            {moment(data?.transactionDate).format("DD MMMM YYYY")}
          </div>
        </div>
        <div className='v3-web-transaction-detail'>
          <div className='v3-web-detail-label'>
            {t("payLaterWebComponents.sideBar.statusText")}
          </div>{" "}
          {getTransactionStatus(data)}
        </div>
        <div className='v3-web-transaction-detail'>
          <div className='v3-web-detail-label'>
            {t("payLaterWebComponents.sideBar.dueDateText")}
          </div>
        </div>
      </div>
      <div className='v3-web-transaction-note'>
        <span>{t("payLaterWebComponents.sideBar.interestFreeUpTo")}</span>
      </div>
    </div>
  )
}

export default Sidebar
