import React from "react"
import Calender from "../../../svg/v3/Calender"
import Categories from "../../../svg/v3/Categories"
import ArrowDown_1 from "../../../svg/v3/arrowDown_1"
import Cross from "../../../svg/v3/Cross"
import { useSelector } from "react-redux"
import { FilterType } from "../../../../utils/constants"
import { useTranslation } from "react-i18next"

const Filter = ({ data }) => {
  const { filterName, showBtnArrow, onFilterClick, isActive } = data
  const theme = useSelector(state => state.theme)
  const { t } = useTranslation()

  const filterText = {
    SORT_BY: t("cashBack.sortByText"),
    CREDITED: t("cashBack.creditedText"),
    REDEEMED: t("cashBack.RedeemedText"),
    REVERSED: t("cashBack.ReversedText"),
    PENDING: t("cashBack.PendingText"),
    EXPIRED: t("cashBack.ExpiredText"),
  }

  const showFilterText = () => {
    return (
      filterName !== FilterType.CATEGORIES && filterName !== FilterType.DATE
    )
  }

  return (
    <div
      className='v3-filter-wrapper'
      style={
        isActive
          ? {
              border: "1.6px solid var(--secondary-color-2)",
              background: "var(--secondary-color-4)",
            }
          : {
              border: "1.6px solid var(--tertiary-neutral-color-3)",
              background: "var(--leading-white)",
            }
      }
      onClick={() => onFilterClick()}
    >
      {filterName === FilterType.DATE && (
        <Calender
          color={
            isActive
              ? theme.v3.rawColors.secondary.color1
              : theme.v3.rawColors.tertiaryNeutral.color1
          }
        />
      )}

      {filterName === FilterType.CATEGORIES && (
        <Categories
          color={
            isActive
              ? theme.v3.rawColors.secondary.color1
              : theme.v3.rawColors.tertiaryNeutral.color1
          }
        />
      )}

      {showFilterText() && (
        <div
          className='v3-filter-text'
          style={
            isActive
              ? { color: "var(--secondary-color-1)", fontWeight: "700" }
              : { color: "var(--tertiary-neutral-color-1)", fontWeight: "500" }
          }
        >
          {filterText[filterName]}
        </div>
      )}

      {isActive && !showBtnArrow && (
        <Cross width={12} height={12} color='var(--secondary-color-1)' />
      )}

      {showBtnArrow && (
        <ArrowDown_1
          color={
            isActive
              ? theme.v3.rawColors.secondary.color1
              : theme.v3.rawColors.tertiaryNeutral.color1
          }
        />
      )}
    </div>
  )
}

export default Filter
