import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import successTick from "../../../assets/images/successTick.svg"
import AccountService from "../../../services/AccountService"
import {
  setPgTransaction,
  setPgVPA,
} from "../../../store/actions/PaymentGateway"
import { EventName } from "../../../utils/enums"
import { captureEvents, formatAmount } from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const options = [
  "@oksbi",
  "@ybl",
  "@paytm",
  "@upi",
  "@aubank",
  "@axisbank",
  "@jio",
]

const PaymentUpiVerify = () => {
  const windowDim = useWindowDimensions()
  const { t } = useTranslation()
  const [isOtherAmountSelected, setIsOtherAmountSelected] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [inputId, setInputId] = useState("")
  const [accHolderName, setAccHolderName] = useState("")
  const [vpaTxnId, setVpaTxnId] = useState("")
  const [isVerified, setIsVerified] = useState(false)
  const showOptions = !inputId?.includes("@") && inputId?.length > 0
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = useState("")
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const paymentGateway = useSelector(state => state.paymentGateway)

  useEffect(() => {
    captureEvents({
      eventName: EventName.PAY_BILL_UPI_ID_PAGE,
      metadata: { amount: paymentGateway?.amountPayable },
    })
  }, [])

  const handleVerify = async () => {
    let accountId = user?.account?.id
    let data = {
      accountId,
      vpaId: inputId,
      pgId: paymentGateway?.pgconfig[0]?.id,
      pgFlowId: paymentGateway?.pgconfig[0]?.flows[0]?.id,
    }
    setIsVerifyLoading(true)
    captureEvents({
      eventName: EventName.PAY_BILL_UPI_VERIFY_BTN,
      metadata: { amount: paymentGateway?.amountPayable, upiId: inputId },
    })

    try {
      const response = await AccountService.verifyVpa(data)
      setIsVerifyLoading(false)
      if (response.status === 200) {
        setIsVerified(true)
        setAccHolderName(response.data?.data.accountHolderName)
        setVpaTxnId(response.data?.data?.vpaTxnRefId)
        dispatch(
          setPgVPA({
            vpaTxnRefId: response?.data?.data?.vpaTxnRefId,
            accountHolderName: response?.data?.data?.accountHolderName,
            vpaId: inputId,
          }),
        )
      } else {
        toast(t("PaymentGateway.incorrectUpiIdText"))
      }
    } catch (err) {
      toast(t("PaymentGateway.incorrectUpiIdText"))
      setIsVerifyLoading(false)
    }
  }

  const handleProceed = async () => {
    let data = {
      accountId: user.account.id,
      vpaTxnRefId: vpaTxnId,
      amount: paymentGateway.amountPayable,
      pgId: paymentGateway?.pgconfig[0]?.id,
      pgFlowId: paymentGateway?.pgconfig[0]?.flows[0]?.id,
    }

    captureEvents({
      eventName: EventName.PAY_BILL_UPI_ID_CTA,
      metadata: { amount: paymentGateway?.amountPayable, upiId: inputId },
    })

    try {
      setSubmitLoading(true)
      const response = await AccountService.initiatePGTransaction(data)
      if (response.status == 200) {
        dispatch(
          setPgTransaction({
            txnRefId: response.data?.data?.txnRefId,
            redirectUrl: response.data?.data?.redirectUrl,
          }),
        )
        setSubmitLoading(false)
        navigate("/PaymentGateway/paymentRequest")
      } else {
        setSubmitLoading(false)
        navigate("/PaymentGateway/Failed")
      }
    } catch (err) {
      setSubmitLoading(false)
      navigate("/PaymentGateway/Failed")
    }
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text={t("PaymentGateway.billPayment")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='mainContainer' style={{ color: theme.appTextColor }}>
        <div
          className='optionsWidget optionsWidgetVerifyUpi'
          style={{ backgroundColor: theme.widgetBackgroundColor }}
        >
          <div className='optionsContainer'>
            <div className='optionsHeading'>
              {t("PaymentGateway.amountPayable")}
            </div>
            <div className='optionsAmount'>
              {formatAmount(paymentGateway.amountPayable)}
            </div>
          </div>
          <div className='borderLine'></div>
          <div className='optionsContainer'>
            <div className='optionsUpiVerifyHeading2'>
              {t("PaymentGateway.upiIdText")}
            </div>

            <div className='inputContainerVerifyUpi '>
              <input
                value={inputId}
                onChange={e => {
                  setIsVerified(false)
                  setInputId(e.target.value)
                }}
                className='otherAmountVerifyUpi'
              />

              {!isVerified && !isVerifyLoading ? (
                <span
                  onClick={() => handleVerify()}
                  className='currencyInputVerifyUpi'
                >
                  {t("PaymentGateway.verifyText")}
                </span>
              ) : isVerifyLoading ? (
                <span
                  style={{ borderTopColor: theme?.color1, float: "right" }}
                  className='generic-circular-loader'
                ></span>
              ) : (
                <span className='currencyInputVerifyUpi verifyUpiVerified'>
                  <img src={successTick}></img>
                  {t("PaymentGateway.verifiedText")}
                </span>
              )}
            </div>
          </div>

          <div className='verifyUpiTypesContainer'>
            {options &&
              showOptions &&
              options.map(type => {
                return (
                  <div
                    onClick={() => setInputId(inputId + type)}
                    className='verifyUpiTypes'
                  >
                    {type}
                  </div>
                )
              })}
          </div>

          {isVerified && (
            <>
              <div className='verifyOtpAccNameHeading'>
                {t("PaymentGateway.v3verifyOtpAccNameHeading")}
              </div>

              <div className='verifyOtpAccName'>{accHolderName}</div>
            </>
          )}
        </div>
      </div>
      <div className='paymentGatewayBtnContainer'>
        <ColorButton
          text={t("PaymentGateway.proceedButtonText")}
          isDisable={!isVerified}
          onPress={() => handleProceed()}
          isLoading={submitLoading}
        />
      </div>
    </div>
  )
}

export default PaymentUpiVerify
